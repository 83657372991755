import http from "../http-common";

class SeatService {
  createseat(data) {
    return http.post('/seats', data);
  }
  
  createseats(data) {
    return http.post('/seats/createseats', data);
  }
  createmanageseats(data) {
    return http.post('/seats/createmanageseats', data);
  }
  getseats() {
    return http.get(`/seats`);
  }  
  getseatbyzoneid(id) {
    return http.get(`/seats/getseatbyzoneid/`+id);
  }
  getseatByID(id) {
    return http.get(`/seats/getseatbyconcertid/`+id);
  }
  getseatbyconcertidmap(id) {
    return http.get(`/seats/getseatbyconcertidmap/`+id);
  }  
  getseatByIDandDate(id,date) {
    return http.get(`/seats/getseatbyconcertidanddate/`+id+'/'+date);
  }
  getseatByTicketID(id) {
    return http.get(`/seats/getseatbyticketid/`+id);
  }
  getseat(id) {
    return http.get(`/seats/`+id);
  }
  updateseat(id, data) {
    return http.put(`/seats/${id}`, data);
  }
  updatestatusseat(id, data) {
    return http.put(`/seats/updatestatus/${id}`, data);
  }
  updatesetzone(id, data) {
    return http.put(`/seats/updatesetzone/${id}`, data);
  }
  updatesetconfirm(id, data) {
    return http.put(`/seats/updatesetconfirm/${id}`, data);
  }
}

export default new SeatService();