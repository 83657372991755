import http from "../http-common";

class MainTimeBookingEndService {
  createmanage_booking(data) {
    return http.post('/manage_booking', data);
  }
  getmanage_bookings() {
    return http.get(`/manage_booking`);
  }
  getmanage_booking(id) {
    return http.get(`/manage_booking/${id}`);
  }
  updatemanage_booking(id, data) {
    return http.put(`/manage_booking/${id}`, data);
  }
  deletemanage_booking(id) {
    return http.delete(`/manage_booking/${id}`);
  }
}

export default new MainTimeBookingEndService();