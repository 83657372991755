<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddEvent"
        >
          <i class="fa fa-plus"></i> เพิ่มวันหยุด
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">วันที่ปิดร้าน</th>
          <th scope="col">หมายเหตุการปิดร้าน</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ convertdate(l.date) }}
          </td>
          <td>
            {{ l.remark_close }}
          </td>
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddEvent"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <a>
                <button
                @click="getid(l.id)"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#Deletevent"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a>
          </td>
        </tr>
      </tbody>
    </table>
 
            <!-- Modal -->
            
            <div
      class="modal fade"
      id="Deletevent"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบวันหยุด</h5>
            <button
              id="closeduser"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger" @click="deleteevent()">
              ลบ
            </button>
            <button
            id="closedevent"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="AddEvent"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              id="closeduser"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">

                <div class="form-group">
                  <label for="username">วันที่ปิดร้าน</label><br/>
                  <date-picker
              v-model="data.date"
              format="DD/MM/YYYY"
              valueType="format"
            ></date-picker>
                </div>
                <div class="form-group mt-3">
                  <label for="username">หมายเหตุการปิดร้าน</label>
                  <textarea
              rows="3"
              v-model="data.remark_close"
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="หมายเหตุ"
            />
                </div>
                
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closedremark"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import MainBannerService from "../services/MainBannerService";
import ConcertService from "../services/ConcertService";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Nav",
  components: {
    DatePicker,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      remarks:{},
      concert_id: 0,
      list: [],
      user: {},
      con_id: 0,
      hash: 0,
      title: "",
      roles:[],
      concert:[],
      data:{}
    };
  },
  mounted() {
    this.getconcert()
  },
  methods: {
    convertdatesave(date){
      // console.log(date);
var dd = date.split('/')[1]
var mm = date.split('/')[0]
var yy = date.split('/')[2]
return yy+'/'+dd+'/'+mm
    },
    convertdate(date){
var dates = new Date(date)
var dd = dates.getDate()
var mm = dates.getMonth()+1
var yy = dates.getFullYear()
if (String(dd).length == 1) {
  dd = '0'+dd
}
if (String(mm).length == 1) {
  mm = '0'+mm
}
return dd+'/'+mm+'/'+yy
    },
    getconcert(){
      ConcertService.getcloseconcert().then((res) => {
  this.list =res.data 
  console.log(res.data);
 });
    },
    deleteid(id) {
      MainBannerService.deletemain_banner(id).then(()=>{
this.getRemark();
    })
    },
    getid(id) {
      this.con_id = id;
      if (this.con_id != 0) {
        this.title = "แก้ไขข้อมูลวันหยุด";
        ConcertService.getconcert(this.con_id).then((res) => {
          this.data = res.data;
          this.data.date = this.convertdate(this.data.date)
          console.log(this.data);
        });
      } else {
        this.title = "เพิ่มข้อมูลวันหยุด";
        this.data = {};
      }
    },
    deleteevent(){
ConcertService.deleteconcert(this.con_id).then((res)=>{
  console.log(res.data);
  
  document.getElementById("closedevent").click();
            this.getconcert();
})
    },
    save() {
      if (this.data.date == null) {
        alert("กรุณากรอกวันที่ปิดร้าน");
      } else if(this.data.remark_close == null){
        alert("กรุณากรอกหมายเหตุ");
      }
        else {
        var con = {
          status:2,
          remark_close: this.data.remark_close,
          date:this.convertdatesave(this.data.date),
        };
        if (this.con_id == 0) {
          ConcertService.createconcert(con).then(() => {
            document.getElementById("closedremark").click();
            this.getconcert();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
        console.log(con);
          ConcertService.updateclosestatus(this.con_id, con).then(() => {
            // console.log(res.data);
            document.getElementById("closedremark").click();
            this.getconcert();
            alert('บันทึกสำเร็จ')
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      MainBannerService.getmain_banners().then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
