import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueApexCharts from 'vue-apexcharts'
import VueExcelXlsx from "vue-excel-xlsx";
import JwPagination from 'jw-vue-pagination';
import VueHtml2pdf from 'vue-html2pdf'
import VueQRCodeComponent from 'vue-qrcode-component'
import store from './store';
import VueHtml2Canvas from 'vue-html2canvas';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('jw-pagination', JwPagination);
Vue.use(VueHtml2pdf)
Vue.use(VueHtml2Canvas);
Vue.use(VueToast);
Vue.config.productionTip = false

Vue.use(VueExcelXlsx);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
