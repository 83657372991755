import http from "../http-common";

class PromotionService {
  createpromotions(data) {
    return http.post('/promotions', data);
  }
  getpromotions(name) {
    return http.get(`/promotions?name=`+name);
  }
  getpromotion(id) {
    return http.get(`/promotions/`+id);
  }
  getimage(name) {
    return http.get(`/img?name=`+name);
  }
  updatepromotions(id, data) {
    return http.put(`/promotions/${id}`, data);
  }
  updatepromotionstatus(id, data) {
    return http.put(`/promotions/updatestatus/${id}`, data);
  }
  deletepromotions(id) {
    return http.delete(`/promotions/${id}`);
  }
}

export default new PromotionService();