import axios from "axios";
import LinkService from './services/LinkService'

export default axios.create({
  // baseURL: "http://localhost:8080/api",
// 
  // baseURL : "https://api.hollywoodpubphayao.com/api",
   baseURL : LinkService.getLink() + "/api",
  
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type',
  },
  mode: 'no-cors'
});