<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddUser"
        >
          <i class="fa fa-plus"></i> เพิ่มหมายเหตุ
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">หมายเหตุ</th>
           <th scope="col">จัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in remarks" :key="i">
          <td>
            {{ i + 1 }} 
          </td>
          <td>{{ l.name }} </td>         
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddUser"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
              <!-- <a>
                <button
                @click="deleteid(l.id)"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a> -->
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              id="closedremark"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">หมายเหตุ</label>
                  <textarea
        v-model="data.name"
        type="text"
        class="form-control"
        id="exampleFormControlInput1"
      />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainBannerService from "../services/MainBannerService";
import RemarkService from "../services/RemarkService";

export default {
  name: "Nav",
  components: {
    // DatePicker,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      remarks:[],
      concert_id: 0,
      list: [],
      user: {},
      con_id: 0,
      hash: 0,
      title: "",
      roles:[],
      concert:[],
      data:{}
    };
  },
  mounted() {
    this.getRemark()
  },
  methods: {
    convertdate(date){
var dates = new Date(date)
var dd = dates.getDate()
var mm = dates.getMonth()+1
var yy = dates.getFullYear()
if (String(dd).length == 1) {
  dd = '0'+dd
}
return dd+'/'+mm+'/'+yy
    },
    convertdatesave(date){
      // console.log(date);
var dd = date.split('/')[1]
var mm = date.split('/')[0]
var yy = date.split('/')[2]
return yy+'/'+dd+'/'+mm
    },
    getRemark(){
 RemarkService.getremarks().then((res) => {
  this.remarks =res.data  
 });
    },
    deleteid(id) {
      MainBannerService.deletemain_banner(id).then(()=>{
this.getRemark();
    })
    },
    getid(id) {
      this.con_id = id;
      if (this.con_id != 0) {
        this.title = "แก้ไขข้อมูลหมายเหตุ";
        RemarkService.getremark(this.con_id).then((res) => {
          this.data = res.data;
        });
      } else {
        this.title = "เพิ่มข้อมูลหมายเหตุ";
        this.data = {};
      }
    },
    save() {
      console.log(this.data);
      if (this.data.name == null) {
        alert("กรุณากรอกหมายเหตุ");
      }  else {
        var con = {
          name: this.data.name,
        };
        // console.log(con);
        if (this.con_id == 0) {
          RemarkService.createremark(con).then(() => {
            document.getElementById("closedremark").click();
            this.getRemark();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          RemarkService.updateremark(this.con_id, con).then(() => {
            // console.log(res.data);
            document.getElementById("closedremark").click();
            this.getRemark();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      MainBannerService.getmain_banners().then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
