<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!-- <div class="col-md-12" style="text-align: center;">
  <qr-code style="display:inline !important" v-if="ticket.qrcode" :text="ticket.qrcode"></qr-code>

  </div> -->
          <div class="card-body" style="width: 100%" v-if="concert">
            <h6 class="card-title" style="text-align: center" v-if="concert.id != 17">
              คอนเสิร์ต: {{ concert.concert_name }}
            </h6>
            <h6 class="card-title" style="text-align: center" v-else>
              {{ concert.concert_name }}
            </h6>
            <br />
            <p class="card-title" style="text-align: center">
              ชื่อผู้จอง: {{ ticket.name }}
            </p>
            <br />
            <p class="card-title" style="text-align: center">
              เบอร์โทรศัพท์: {{ ticket.phone }}
            </p>
            <br />
            <p class="card-title" style="text-align: center">โซน: {{ zone }}</p>
            <br />

            <p class="card-title" style="text-align: center">หมายเลขโต๊ะ: {{ seat }}</p>
            <p class="card-title mt-3" style="text-align: center">
              หมายเหตุ: {{ ticket.remark || '-' }}
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from "../services/TicketService.js";
import SeatService from "../services/SeatService";
import ZoneService from "../services/ZoneService";
import ConcertService from "../services/ConcertService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      ticket_id: 0,
      ticket: {},
      seat: "",
      zone: "",
      concert: {},
    };
  }, 
  mounted() {
    this.ticket_id = this.$route.params.id;
    TicketService.getticket(this.ticket_id).then((res) => {
      this.ticket = res.data;
      console.log(res.data);
      if (res.data.length == 0) {
        this.$router.push('/qrcodebook/'+this.$route.params.id)    
      }else{
      ConcertService.getconcert(res.data.concert_id).then((res) => {
        this.concert = res.data;
        console.log(this.concert);
      });
      // console.log(this.list[r].id);
      ZoneService.getzonebyticketid(this.ticket_id).then((res) => {
        // console.log(res.data);
        this.zone = "";
        for (let z = 0; z < res.data.length; z++) {
          this.zone += res.data[z].zone + ", ";
        }
        this.zone = this.zone.slice(0, -2);
        // console.log(this.zone);
      });
      }
    });
    SeatService.getseatByTicketID(this.ticket_id).then((res) => {
      this.seat = "";
            for (let s = 0; s < res.data.length; s++) {
              this.seat += res.data[s].seat + ", ";
            }
            this.seat = this.seat.slice(0, -2);
      // console.log(this.seat);
    });
    // console.log(this.$route.params.ticket)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
img {
  display: inline-block !important;
}
</style>
