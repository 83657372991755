<template>
  <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout mb-5">
    <!-- <div class="col mb-3" style="text-align: right">
      <a @click="create()">
        <button type="button" class="btn btn-success">
          <i class="fa fa-plus"></i> สร้างผังคอนเสิร์ตใหม่
        </button></a
      >
    </div> -->
    <div class="col-md-6 mt-5">
      <label for="exampleFormControlInput1" class="form-label">ชื่อคอนเสิร์ต</label>
      <input v-model="title.concert_name" type="text" class="form-control" id="exampleFormControlInput1"
        placeholder="ชื่อคอนเสิร์ต" />
    </div>
    <div class="col-md-6 mt-3" v-if="concert_id != 17">
      <label for="username">วันที่แสดงคอนเสิร์ต</label>
      <br /><date-picker v-model="title.date" format="DD/MM/YYYY" valueType="format"></date-picker>
    </div>
    <div class="col-md-12 mt-3" v-if="concert_id != 17">
      <label for="exampleFormControlInput1" class="form-label">หมายเหตุ</label>
      <select v-model="title.remark_id" class="form-select" aria-label="Default select example">
        <option v-for="(z, i) in remarks" :key="i" :value="z.id">
          {{ z.name }}
        </option>
      </select>
    </div>
    <div class="row mb-3 mt-3" v-if="zones.length == 0">
      <div class="col-md-6 mt-3">
        <label for="exampleFormControlInput1" class="form-label">จำนวนโซน</label>
        <input v-model="zone" type="number" class="form-control" id="exampleFormControlInput1" placeholder="จำนวนโซน" />
        <div class="col-auto mt-3">
          <button @click="createzone()" type="submit" class="btn btn-success mb-3">
            ยืนยัน
          </button>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-success mt-3" @click="saveconcert()" v-if="zones.length > 0 && !statusedit">
      บันทึกรายละเอียดคอนเสิร์ต
    </button>
    <div>
      <div class="row mt-5" v-if="seats.length > 0" style="padding: 0px; margin: 0px">
        <table class="table table-borderless" style="width:100%">
            <thead style="width:100%;">
              <tr style="width:100%;">
               <th scope="col" class="before-mdroom"></th>
                <th scope="col" style="width: 65%;padding: 0px;background-color: black;text-align: center;vertical-align: middle;">
                  <img src="../assets/banner.png" style="width: 100%; text-align: center" />
                </th>
                <th scope="col" style="width: 30%;background-color:black;text-align:center">
                  <div style="background-color:black;text-align:center">
                <h1 class="neonText" :style="style">{{ title.banner_name }}
                  <br />
                  <span v-if="title.id  != 17">{{ title.date }}</span>
                </h1>
              </div></th>
              </tr>
            </thead>
           
          </table>
          <table class="table table-borderless" style="width:100%">
            <thead style="width:100%;">
              <tr style="width:100%;">
               <th scope="col" class="before-mdroom"></th>
                <th scope="col" style="width: 10%;border-left:5px #4976C0;border-left-style: solid;border-top:5px #4976C0;border-top-style: solid;padding: 0px;background-color: #FFF0CD;text-align: center;vertical-align: middle;">
                  <div class="sound">Sound Control<br> Room
                  </div>
                </th>
                <th scope="col" style="width: 55%;border-top:5px #4976C0;border-top-style: solid; padding: 0px;vertical-align: top;"> <img style="width: 100%;" src="../assets/Stage.jpg">
                  <img class="stagetable"
                    src="../assets/Stagetable.jpg">
                </th>
                <th scope="col" style="width: 11.5%;border-top:5px #4976C0;border-top-style: solid;border-right:5px #4976C0;border-right-style: solid;padding: 0px;background-color: #FFF0CD;text-align: center;vertical-align: middle;">
                  <div class="sound">Artist Room
                  </div>
                </th>
                <th scope="col" style="width: 20%;"></th>
              </tr>
            </thead>
           
          </table>

          <table class="table table-borderless">
            <thead>
              <tr>
               <th scope="col" class="before-dj">
                   <img class="fireexitleft" src="../assets/fireexitleft.png" style="
    position: absolute;">
                  <img class="fireexitleft2"  src="../assets/fireexitleft.png" style="
    position: absolute;"></th>
                <th scope="col" style="width: 65%;border-left:5px #4976C0;border-left-style: solid;padding: 0px;text-align: center;vertical-align: top;">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="onesection" style="vertical-align: top;">
                          <!-- <tr style="text-align: center" class="flex" >

<td v-if="r1.status" :class="r1.class" :style="r1.color" :id="r1.seat">
  <img src="../assets/cross.png" v-if="r1.seatstatus == true" class="seatconfirm">
  {{ r1.seat }}
</td>
<td v-else :class="r1.class" style="background-color: red" :id="r1.seat">
  <img src="../assets/cross.png" v-if="r1.seatstatus == true" class="seatconfirm">
  {{ r1.seat }}
</td>
</tr> -->
                          <div v-for="r1 in row1" :key="r1.id">
                            <div v-if="r1.status" :class="r1.class" :style="r1.color" :id="r1.seat" @click="selectseat($event, r1)">{{ r1.seat }}</div>
                            <div v-else  :class="r1.class" style="background-color: red" :id="r1.seat">{{ r1.seat }}</div>
                          </div>
                          <!-- <strong>2</strong> <strong>3</strong> <strong>4</strong> <strong>5</strong> -->
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;"> <div v-for="r6 in row6" :key="r6.id">
                            <div v-if="r6.status" :class="r6.class" :style="r6.color" :id="r6.seat" @click="selectseat($event, r6)">{{ r6.seat }}</div>
                            <div v-else  :class="r6.class" style="background-color: red" :id="r6.seat">{{ r6.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;">
                          <div v-for="r16 in row16" :key="r16.id">
                            <div v-if="r16.status" :class="r16.class" :style="r16.color" :id="r16.seat" @click="selectseat($event, r16)">{{ r16.seat }}</div>
                            <div v-else  :class="r16.class" style="background-color: red" :id="r16.seat">{{ r16.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;">
                          <div v-for="r26 in row26" :key="r26.id">
                            <div v-if="r26.status" :class="r26.class" :style="r26.color" :id="r26.seat" @click="selectseat($event, r26)">{{ r26.seat }}</div>
                            <div v-else  :class="r26.class" style="background-color: red" :id="r26.seat">{{ r26.seat }}</div>
                          </div>
                          <div class="polenew">เสา</div></th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;">
                         <div v-for="r35 in row35" :key="r35.id">
                            <div v-if="r35.status" :class="r35.class" :style="r35.color" :id="r35.seat" @click="selectseat($event, r35)">{{ r35.seat }}</div>
                            <div v-else  :class="r35.class" style="background-color: red" :id="r35.seat">{{ r35.seat }}</div>
                          </div></th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;"> <div v-for="r45 in row45" :key="r45.id">
                            <div v-if="r45.status" :class="r45.class" :style="r45.color" :id="r45.seat" @click="selectseat($event, r45)">{{ r45.seat }}</div>
                            <div v-else  :class="r45.class" style="background-color: red" :id="r45.seat">{{ r45.seat }}</div>
                          </div></th>
                        <th class="onesection" style="vertical-align: bottom;">
                          <div v-for="r55 in row55" :key="r55.id">
                            <div v-if="r55.status" :class="r55.class" :style="r55.color" :id="r55.seat" @click="selectseat($event, r55)">{{ r55.seat }}</div>
                            <div v-else  :class="r55.class" style="background-color: red" :id="r55.seat">{{ r55.seat }}</div>
                          </div></th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;">
                          <div v-for="r64 in row64" :key="r64.id">
                            <div v-if="r64.status" :class="r64.class" :style="r64.color" :id="r64.seat" @click="selectseat($event, r64)">{{ r64.seat }}</div>
                            <div v-else  :class="r64.class" style="background-color: red" :id="r64.seat">{{ r64.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;"> <div v-for="r74 in row74" :key="r74.id">
                            <div v-if="r74.status" :class="r74.class" :style="r74.color" :id="r74.seat" @click="selectseat($event, r74)">{{ r74.seat }}</div>
                            <div v-else  :class="r74.class" style="background-color: red" :id="r74.seat">{{ r74.seat }}</div>
                          </div></th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;"> <div v-for="r84 in row84" :key="r84.id">
                            <div v-if="r84.status" :class="r84.class" :style="r84.color" :id="r84.seat" @click="selectseat($event, r84)">{{ r84.seat }}</div>
                            <div v-else  :class="r84.class" style="background-color: red" :id="r84.seat">{{ r84.seat }}</div>
                          </div>
                          <div class="polenew">เสา</div></th>
                        <th class="onesection" style="vertical-align: top;padding-top: 0.2%!important;"> <div v-for="r93 in row93" :key="r93.id">
                            <div v-if="r93.status" :class="r93.class" :style="r93.color" :id="r93.seat" @click="selectseat($event, r93)">{{ r93.seat }}</div>
                            <div v-else  :class="r93.class" style="background-color: red" :id="r93.seat">{{ r93.seat }}</div>
                          </div></th>
                      </tr>
                    </thead>
                  </table>
                </th>
                <th scope="col"  style="width: 10%;padding: 0px;text-align: center;vertical-align: top;">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="onesection" style="vertical-align: top;padding-top: 2%!important;"> <div v-for="r103 in row103" :key="r103.id">
                            <div v-if="r103.status" :class="r103.class" :style="r103.color" :id="r103.seat" @click="selectseat($event, r103)">{{ r103.seat }}</div>
                            <div v-else  :class="r103.class" style="background-color: red" :id="r103.seat">{{ r103.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 2%!important;"> <div v-for="r109 in row109" :key="r109.id">
                            <div v-if="r109.status" :class="r109.class" :style="r109.color" :id="r109.seat" @click="selectseat($event, r109)">{{ r109.seat }}</div>
                            <div v-else  :class="r109.class" style="background-color: red" :id="r109.seat">{{ r109.seat }}</div>
                          </div>
                          <div style="border-top:5px #4976C0;border-top-style: solid;width: 60%;margin-left: 42%;margin-top:0%"></div>
                         
    <span class="strong139" style="color:white;">112</span>
                         
   
                        </th>
                      </tr>
                    </thead>
                  </table>
                </th>
                <th scope="col" style="width: 0.5%;padding: 0px;text-align: center;vertical-align: top;border-top:5px #4976C0;border-top-style: solid;">
<span class="row109" style="border-right:5px #4976C0;border-right-style: solid;
    display: block;"></span>
   
 
</th>
                <th scope="col" style="width:10%;border-top:5px #4976C0;border-top-style: solid;padding: 0px;text-align: center;vertical-align: top;">
               
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="onesection" style="vertical-align: top;width: 50%;padding-top: 2%!important;"> 
                          <div v-for="r119 in row119" :key="r119.id">
                            <div v-if="r119.status" :class="r119.class" :style="r119.color" :id="r119.seat" @click="selectseat($event, r119)">{{ r119.seat }}</div>
                            <div v-else  :class="r119.class" style="background-color: red" :id="r119.seat">{{ r119.seat }}</div>
                          </div>
                        
                        </th>
                        <th class="onesection" style="vertical-align: top;width: 50%;padding-top: 2%!important;">
                          <div v-for="r129 in row129" :key="r129.id">
                            <div v-if="r129.status" :class="r129.class" :style="r129.color" :id="r129.seat" @click="selectseat($event, r129)">{{ r129.seat }}</div>
                            <div v-else  :class="r129.class" style="background-color: red" :id="r129.seat">{{ r129.seat }}</div>
                          </div>
                          <div style="border-top:5px #4976C0;border-top-style: solid;width: 60%;margin-left: 42%;margin-top:-7%"></div>
                          <span class="strong139" style="color:white;">
                          </span>
                        <div class="row143" style="border-top: 5px solid rgb(73, 118, 192);
   
    margin-right: -20%;
    margin-left: 45%;"></div>
    <span class="rowheight-132" style="border-right:5px #4976C0;border-right-style: solid;;
    position: absolute;"></span>
    <div>
      <div v-for="r132 in row132" :key="r132.id">
                            <div v-if="r132.status" :class="r132.class" :style="r132.color + ';margin-top: 50%!important;margin-left: -10%;'" :id="r132.seat" @click="selectseat($event, r132)">{{ r132.seat }}</div>
                            <div v-else :class="r132.class" style="background-color: red;margin-top: 50%!important;margin-left: -10%;" :id="r132.seat">{{ r132.seat }}</div>
                          </div>
      </div>
    
    <!-- <strong>133</strong> -->

<!-- <span style="border-right:5px #4976C0;border-right-style: solid;height: 117px;
    display: block;"></span> -->
    
                        </th>
                        </tr>
                      </thead>
                    </table>
                   </th>

                <th scope="col" style="width: 0.5%;padding: 0px;text-align: center;vertical-align: top;border-top:5px #4976C0;border-top-style: solid;">
 <span class="row129" style="border-right:5px #4976C0;border-right-style: solid;
    display: block;"></span>

</th>
                <th scope="col" style="width: 8%;border-top:5px #4976C0;border-top-style: solid;padding: 0px;text-align: center;vertical-align: top;">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="onesection" style="vertical-align: top;width: 50%;padding-top: 2%!important;">
                          <div v-for="r137 in row137" :key="r137.id">
                            <div v-if="r137.status" :class="r137.class" :style="r137.color" :id="r137.seat" @click="selectseat($event, r137)">{{ r137.seat }}</div>
                            <div v-else  :class="r137.class" style="background-color: red" :id="r137.seat">{{ r137.seat }}</div>
                          </div>
                            <span class="strong139" style="color:white">112</span>
                          
                        </th>
                        <th class="onesection" style="vertical-align: top;width: 50%;padding-top: 2%!important;border-right:5px #4976C0;border-right-style: solid;">
                          <div v-for="r140 in row140" :key="r140.id">
                            <div v-if="r140.status" :class="r140.class" :style="r140.color" :id="r140.seat" @click="selectseat($event, r140)">{{ r140.seat }}</div>
                            <div v-else  :class="r140.class" style="background-color: red" :id="r140.seat">{{ r140.seat }}</div>
                          </div>
                          <!--  -->
                        </th>
                        </tr>
                      </thead>
                    </table>
                  </th>
              </tr>
            </thead>
          </table>
          <table class="table table-borderless">
            <thead>
              <tr>
                <th scope="col" class="before-mdroom" > <img
                    src="../assets/toilet.jpg" class="toilet" style="
    position: absolute;"> </th>
                <th scope="col"
                  class="row12-99" style="border-left:5px #4976C0;border-left-style: solid;padding: 0px;text-align: center;vertical-align: middle;">
                  <table class="table table-borderless">
                    <thead>
                      <tr style="background-color: #DBDBDB;">
                        <th style="width:100%;background-color: #DBDBDB;"><span>Walk way</span></th>
                      </tr>
                    </thead>
                  </table>
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th style="vertical-align: middle;background-color: #DBDBDB;width: 0.1% !important;padding: 0px;">
                          <div class="word">Walk way</div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r12 in row12" :key="r12.id">
                            <div v-if="r12.status" :class="r12.class" :style="r12.color" :id="r12.seat" @click="selectseat($event, r12)">{{ r12.seat }}</div>
                            <div v-else  :class="r12.class" style="background-color: red" :id="r12.seat">{{ r12.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r22 in row22" :key="r22.id">
                            <div v-if="r22.status" :class="r22.class" :style="r22.color" :id="r22.seat" @click="selectseat($event, r22)">{{ r22.seat }}</div>
                            <div v-else  :class="r22.class" style="background-color: red" :id="r22.seat">{{ r22.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r31 in row31" :key="r31.id">
                            <div v-if="r31.status" :class="r31.class" :style="r31.color" :id="r31.seat" @click="selectseat($event, r31)">{{ r31.seat }}</div>
                            <div v-else  :class="r31.class" style="background-color: red" :id="r31.seat">{{ r31.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r41 in row41" :key="r41.id">
                            <div v-if="r41.status" :class="r41.class" :style="r41.color" :id="r41.seat" @click="selectseat($event, r41)">{{ r41.seat }}</div>
                            <div v-else  :class="r41.class" style="background-color: red" :id="r41.seat">{{ r41.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r51 in row51" :key="r51.id">
                            <div v-if="r51.status" :class="r51.class" :style="r51.color" :id="r51.seat" @click="selectseat($event, r51)">{{ r51.seat }}</div>
                            <div v-else  :class="r51.class" style="background-color: red" :id="r51.seat">{{ r51.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r60 in row60" :key="r60.id">
                            <div v-if="r60.status" :class="r60.class" :style="r60.color" :id="r60.seat" @click="selectseat($event, r60)">{{ r60.seat }}</div>
                            <div v-else  :class="r60.class" style="background-color: red" :id="r60.seat">{{ r60.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r70 in row70" :key="r70.id">
                            <div v-if="r70.status" :class="r70.class" :style="r70.color" :id="r70.seat" @click="selectseat($event, r70)">{{ r70.seat }}</div>
                            <div v-else  :class="r70.class" style="background-color: red" :id="r70.seat">{{ r70.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r80 in row80" :key="r80.id">
                            <div v-if="r80.status" :class="r80.class" :style="r80.color" :id="r80.seat" @click="selectseat($event, r80)">{{ r80.seat }}</div>
                            <div v-else  :class="r80.class" style="background-color: red" :id="r80.seat">{{ r80.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r89 in row89" :key="r89.id">
                            <div v-if="r89.status" :class="r89.class" :style="r89.color" :id="r89.seat" @click="selectseat($event, r89)">{{ r89.seat }}</div>
                            <div v-else  :class="r89.class" style="background-color: red" :id="r89.seat">{{ r89.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;padding-top: 1%!important;width: 3%;">
                          <div v-for="r99 in row99" :key="r99.id">
                            <div v-if="r99.status" :class="r99.class" :style="r99.color" :id="r99.seat" @click="selectseat($event, r99)">{{ r99.seat }}</div>
                            <div v-else  :class="r99.class" style="background-color: red" :id="r99.seat">{{ r99.seat }}</div>
                          </div>
                        </th>
                        <th style="vertical-align: middle;background-color: #DBDBDB;width: 0.1% !important;padding: 0px;">
                          <div class="word">Walk way</div>
                        </th>
                        <th class="onesection" style="vertical-align: top;vertical-align: top;
    text-align: right;
    padding-right: 1.5%;">
<div v-for="r114 in row114" :key="r114.id">
                            <div v-if="r114.status" :class="r114.class+' strong114'" :style="r114.color" :id="r114.seat" @click="selectseat($event, r114)">{{ r114.seat }}</div>
                            <div v-else  :class="r114.class" style="background-color: red" :id="r114.seat">{{ r114.seat }}</div>
                          </div>
                           <!-- <span class="strong114" style="margin-top: 10%;">114</span><br><span class="strong114">115</span><br><span class="strong114">115</span><br><span class="strong114">115</span> -->
                          </th> 
                      </tr>
                    </thead>
                  </table>
                  <table class="table table-borderless" style="vertical-align: top">
                    <thead>
                      <tr>
                        <th style="width:94%;background-color: #DBDBDB;padding: 0px;vertical-align: middle"><span>Walk way</span><span
                            style="margin-left:40%;">Walk way</span></th>
                            <th style="width:6%;padding: 0px;">
                          <div v-for="r118 in row118" :key="r118.id">
                             <div v-if="r118.status" :class="r118.class" :style="r118.color + ';margin-bottom: 0px!important;'" :id="r118.seat" @click="selectseat($event, r118)">{{ r118.seat }}</div>
                            <div v-else :class="r118.class" style="background-color: red;margin-bottom: 0px!important;" :id="r118.seat">{{ r118.seat }}</div>
                          </div>
                          </th>
                      </tr>
                    </thead>
                  </table>
                </th>
                <th scope="col" style="width: 10%;border-left:5px #4976C0;border-left-style: solid;padding: 0px;text-align: center;vertical-align: top;">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="onesection" style="vertical-align: top;width: 50%;padding:0%!important;margin-top: 5%;">
                          <!-- <strong style="margin-top: 20%;">119</strong> -->
                          <div v-for="r124 in row124" :key="r124.id">
                            <div v-if="r124.status" :class="r124.class" :style="r124.color" :id="r124.seat" @click="selectseat($event, r124)">{{ r124.seat }}</div>
                            <div v-else  :class="r124.class" style="background-color: red" :id="r124.seat">{{ r124.seat }}</div>
                          </div>
                        </th>
                        <th class="onesection" style="vertical-align: top;width: 50%;padding:0%!important;">
                          <div v-for="r133 in row133" :key="r133.id">
                            <div v-if="r133.status" :class="r133.class+' strong114'" :style="r133.color" :id="r133.seat" @click="selectseat($event, r133)">{{ r133.seat }}</div>
                            <div v-else  :class="r133.class" style="background-color: red" :id="r133.seat">{{ r133.seat }}</div>
                          </div>
    <!-- <strong>133</strong> -->

<!-- <span style="border-right:5px #4976C0;border-right-style: solid;height: 117px;
    display: block;"></span> -->
    
                        </th>
                        </tr>
                      </thead>
                    </table>
                </th>
                <th scope="col" style="width: 15%;"></th>
              </tr>
            </thead>
          </table>
          <table class="table table-borderless">
          <thead>
            <tr>
              <th scope="col" class="row1-144"></th>
              <th scope="col"
                class="row144" style=";border-left:5px #4976C0;border-left-style: solid;border-top:5px #4976C0;border-top-style: solid;;padding: 0px;text-align: center;vertical-align: middle;">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th style="width:10%;padding: 0px;"></th>
                      <th style="width:15%;padding: 0px;padding-top: 5%!important;;padding-left: 20%!important;">
                        <!-- <span class="strongbottom">144</span><br><span class="strongbottom">145</span> -->
                        <div v-for="r144 in row144" :key="r144.id">
                          <div v-if="r144.status" :class="r144.class + ' strongbottom'" :style="r144.color"
                            @click="selectseat($event, r144)" :id="r144.seat">{{ r144.seat }}</div>
                          <div v-else :class="r144.class" style="background-color: red" :id="r144.seat">{{
                            r144.seat }}</div>
                        </div>
                      </th>
                      <th style="width:15%;padding: 0px;padding-left: 25%!important;">
                        <!-- <span class="strongbottom">146</span><br><span class="strongbottom">147</span> -->
                        <div v-for="r146 in row146" :key="r146.id">
                          <div v-if="r146.status" :class="r146.class + ' strongbottom'" :style="r146.color"
                            @click="selectseat($event, r146)" :id="r146.seat">{{ r146.seat }}</div>
                          <div v-else :class="r146.class" style="background-color: red" :id="r146.seat">{{
                            r146.seat }}</div>
                        </div>
                      </th>
                      <th style="width:15%;padding: 0px">
                        <div v-for="r148 in row148" :key="r148.id">
                          <div v-if="r148.status" :class="r148.class + ' textmd148'" :style="r148.color" :id="r148.seat"
                            @click="selectseat($event, r148)">{{ r148.seat }}</div>
                          <div v-else :class="r148.class + ' textmd148'" style="background-color: red" :id="r148.seat">
                            {{ r148.seat }}</div>
                        </div>
                      </th>
                      <th style="width:15%;padding: 0px">
                        <div v-for="r149 in row149" :key="r149.id">
                          <div v-if="r149.status" :class="r149.class + ' textmd149'" :style="r149.color" :id="r149.seat"
                            @click="selectseat($event, r149)">{{ r149.seat }}</div>
                          <div v-else :class="r149.class + ' textmd149'" style="background-color: red" :id="r149.seat">
                            {{ r149.seat }}</div>
                        </div>
                      </th>

                    </tr>
                  </thead>
                </table>
              </th>
              <th scope="col" class="before-dj"></th>
              <th scope="col"
                style="width: 5%;border-top:5px #4976C0;border-top-style: solid;padding: 0px;text-align: center;vertical-align: middle;">
              </th>
              <th scope="col" style="width: 8%;
                border-top:5px #4976C0;border-top-style: solid;
    padding: 0px;
    text-align: center;
    vertical-align: top;">
                <img src="../assets/DJ.jpg" style="width: 8%;
    position: absolute;
    margin-left: -4%;
    margin-top: 0%">
              </th>
              <th scope="col"
                style="width: 5%;border-top:5px #4976C0;border-top-style: solid;padding: 0px;text-align: center;vertical-align: middle;">
              </th>
             <th scope="col" class="before-dj">

              </th>
              <th scope="col"
                class="row170"
                  style="vertical-align: top;border-right:5px #4976C0;border-right-style: solid;border-top:5px #4976C0;border-top-style: solid;padding: 0px;">
                  <table class="table table-borderless headtop">
                  <thead>
                    <tr>
                      <th class="onesection" style="vertical-align: top;text-align: left;padding-left: 20%;">
                        <span v-for="r170 in row170" :key="r170.id">
                          <strong v-if="r170.status" :class="r170.class + ' strong170'" :style="r170.color"
                            @click="selectseat($event, r170)" :id="r170.seat">{{ r170.seat }}</strong>
                          <strong v-else :class="r170.class + ' strong170'" style="background-color: red"
                            :id="r170.seat">{{ r170.seat }}</strong>
                        </span>
                      </th>

                    </tr>
                  </thead>
                </table>
              </th>
              <th scope="col"
                class="rowshape"
                  style="vertical-align: top;border-right:5px #4976C0;border-right-style: solid;border-top:5px #4976C0;border-top-style: solid;padding: 0px;background-color: #DBDBDB;">
                <table class="table table-borderless headtop">

                  <thead>
                    <tr>
                      <th class="onesection" style="text-align: right;vertical-align:top;">
                        <img src="../assets/shape.png" style="width:100%;margin-top: -9%;">
                      </th>
                    </tr>
                  </thead>
                </table>
              </th>
              <th scope="col" style="width: 9%;padding: 0px;text-align: center;vertical-align: middle;">
              <div class="row128" style="border-top: 5px solid rgb(73, 118, 192);"></div>
              </th>
              <th scope="col" style="width: 8%;"></th>
            </tr>
          </thead>
        </table>
        <table class="table table-borderless">
          <thead>
            <tr>
             <th scope="col" class="before-mdroom"></th>
              <th scope="col" class="row148"
                  style="border-left:5px #4976C0;border-left-style: solid;border-bottom:5px #4976C0;border-bottom-style: solid;padding: 0px;text-align: center;vertical-align: top;">

                <table class="table table-borderless" style="margin-top:10%!important">
                  <tbody>
                    <tr>

                      <th style="width:85%;padding: 0px;">

                        <div>
                          <!-- <div class="trapezoid">MD Room</div> -->
                          <img src="../assets/mdroom.jpg" class="mdroom" style="width: 100%;
    margin-left: 0%;
    vertical-align: bottom;">
                        </div>
                      </th>

                      <th style="width:15%">
                        <!-- <table class="table table-borderless">
            <thead>
              <tr>
                <th scope="col" style="width: 50%;"><span class="strongbottom">148</span></th>
                <th scope="col" style="width: 50%;"><span class="strongbottom">149</span></th>
                </tr>
                </thead>
                </table> -->
                      </th>
                    </tr>
                  </tbody>
                  <!-- <thead>
                      <tr>
                       
                        <th style="vertical-align: top;width: 100%;padding: 0px;padding-top: 20%;"> <span class="strongbottom">144</span> 
                          <span class="strongbottom">145</span> 
                          <div class="trapezoid "><div style="text-align:center;vertical-align: middle;color: #fefefe;font-size: 12px;">MD Room</div></div>
                        </th>
                        <th style="vertical-align: top;width: 25%;padding: 0px;padding-top: 20%;"> <span class="strongbottom">146</span> 
                          <span class="strongbottom">147</span> 
                        </th>
                        <th style="vertical-align: top;width: 25%;padding: 0px;padding-top: 40%">
                          <span class="strongbottom">148</span> 
                        </th> <th style="vertical-align: top;width: 25%;padding: 0px;padding-top: 50%;"> 
                          <span class="strongbottom">149</span> 
                        </th>
                      </tr>
                    </thead> -->
                </table>
              </th>
              <th scope="col"
                class="row150"
                  style="padding: 0px;border-right:5px #4976C0;border-right-style: solid;text-align: left;vertical-align: top;border-bottom:5px #4976C0;border-bottom-style: solid;">
                <!-- <table class="table table-borderless headtop">
                    <thead>
                      <tr>
                        <th class="onesection" style="vertical-align: top;text-align: right;padding-right: 10%;">
                          <span v-for="r170 in row170" :key="r170.id">
                            <strong v-if="r170.status" :class="r170.class + ' strong170'" :style="r170.color"
                              :id="r170.seat">{{ r170.seat }}</strong>
                            <strong v-else :class="r170.class + ' strong170'" style="background-color: red"
                              :id="r170.seat">{{ r170.seat }}</strong>
                          </span>
                        </th>
                      </tr>
                    </thead>
                  </table> -->
                <table class="table table-borderless">
                  <thead>
                    <tr>
                     <th class="onesection row150-left">
                          <div v-for="r150 in row150" :key="r150.id">
                          <div v-if="r150.status" :class="r150.class" :style="r150.color" :id="r150.seat"
                            @click="selectseat($event, r150)">{{ r150.seat
                            }}</div>
                          <div v-else :class="r150.class" style="background-color: red" :id="r150.seat">{{ r150.seat }}
                          </div>
                        </div>
                      </th>
                      <th class="onesection row155-left">
                          <div v-for="r155 in row155" :key="r155.id">
                          <div v-if="r155.status" :class="r155.class" :style="r155.color" :id="r155.seat"
                            @click="selectseat($event, r155)">{{ r155.seat
                            }}</div>
                          <div v-else :class="r155.class" style="background-color: red" :id="r155.seat">{{ r155.seat }}
                          </div>
                        </div>
                      </th>
                       <th class="onesection row160-left">
                          <div v-for="r160 in row160" :key="r160.id">
                          <div v-if="r160.status" :class="r160.class" :style="r160.color" :id="r160.seat"
                            @click="selectseat($event, r160)">{{ r160.seat
                            }}</div>
                          <div v-else :class="r160.class" style="background-color: red" :id="r160.seat">{{ r160.seat }}
                          </div>
                        </div>
                      </th>
                      <th class="onesection">
                        <div v-for="r165 in row165" :key="r165.id">
                          <div v-if="r165.status" :class="r165.class" :style="r165.color" :id="r165.seat"
                            @click="selectseat($event, r165)">{{ r165.seat
                            }}</div>
                          <div v-else :class="r165.class" style="background-color: red" :id="r165.seat">{{ r165.seat }}
                          </div>
                        </div>
                      </th>
                      <th class="onesection">
                        <div v-for="r172 in row172" :key="r172.id">
                          <div v-if="r172.status" :class="r172.class" :style="r172.color" :id="r172.seat"
                            @click="selectseat($event, r172)">{{ r172.seat
                            }}</div>
                          <div v-else :class="r172.class" style="background-color: red" :id="r172.seat">{{ r172.seat }}
                          </div>
                        </div>
                      </th>
                      <th class="onesection">
                        <div v-for="r176 in row176" :key="r176.id">
                          <div v-if="r176.status" :class="r176.class" :style="r176.color" :id="r176.seat"
                            @click="selectseat($event, r176)">{{ r176.seat
                            }}</div>
                          <div v-else :class="r176.class" style="background-color: red" :id="r176.seat">{{ r176.seat }}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </th>
             <th class="row-bathroom" scope="col" style="padding: 0px;text-align: right;vertical-align: top">
                <img
                  style="border-bottom:5px #4976C0;border-bottom-style: solid;border-right:5px #4976C0;border-right-style: solid;width:100%"
                  src="../assets/bathroom.jpg">
              </th>
              <th scope="col" style="width: 10%;">
              </th>
              <th scope="col" style="width: 8%;"></th>
            </tr>
          </thead>
        </table>
        
      </div>
    </div>
    <div class="row mb-3 mt-3" v-if="zones.length > 0 && statusedit">
      <div class="col-md-6 mt-3">
        <label for="exampleFormControlInput1" class="form-label">จำนวนโซน</label>
        <input v-model="zonecount" type="number" class="form-control" id="exampleFormControlInput1"
          placeholder="จำนวนโซน" />
        <div class="col-auto mt-5" style="margin:auto;">
          <button @click="editzone()()" type="submit" class="btn btn-success mb-3">
            ยืนยัน
          </button> &nbsp;
          <button @click="cancleeditzone()()" type="submit" class="btn btn-danger mb-3">
            ยกเลิก
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3" v-if="zones.length > 0 && !statusedit">
      <div class="col-md-6">
        <label for="exampleFormControlInput1" class="form-label">เลือกโซน</label>
        <select v-model="data.zone" class="form-select" aria-label="Default select example" @change="selectzone($event)">
          <option v-for="(z, i) in zones" :key="i" :value="z.id">
            {{ z.name }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="exampleFormControlInput1" class="form-label">เลือกสี</label>
        <input type="color" class="form-control form-control-color" id="exampleColorInput" :value="color"
          @change="selectcolor($event)" title="Choose your color" />
      </div>
      <div class="col-md-6 mt-3">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">ราคา</label>
          <input v-model="data.price" type="number" class="form-control" id="exampleFormControlInput1"
            placeholder="ราคา" />
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <label for="exampleFormControlInput1" class="form-label">ที่นั่ง</label><br />
        <label v-for="(l, i) in list" :key="i">{{ l.seat }}&nbsp;&nbsp;</label>
      </div>
    </div>
    <div class="col-md-12 mt-3" style="text-align: center" v-if="zones.length > 0">
      <button type="button" class="btn btn-warning" @click="edit()" v-if="zones.length > 0 && !statusedit">
        แก้ไขโซน
      </button>&nbsp;
      <button type="button" class="btn btn-success" @click="save()" v-if="zones.length > 0 && !statusedit">
        บันทึก
      </button>
    </div>
    <div class="row mt-5" v-if="zones.length > 0 && !statusedit">
      <div class="col-sm-3" v-for="(z, i) in zones" :key="i">
        <div class="card mt-3">
          <div class="card-body">
            <div style="text-align: center">
              <p class="dot" :style="z.color"></p>
              <div>{{ z.name }}</div>
              <div>ราคา {{ z.price || "0" }} บาท</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZoneService from "../services/ZoneService.js";
import SeatService from "../services/SeatService";
import ConcertService from "../services/ConcertService";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import LinkService from "../services/LinkService";
import RemarkService from "../services/RemarkService";

export default {
  name: "Dashboard",
  components: { DatePicker },
  data() {
    return {
      list: [],
      color: "#0000FF",
      row1: [],
      row6: [],
      row16: [],
      row26: [],
      row35: [],
      row45: [],
      row55: [],
      row64: [],
      row74: [],
      row84: [],
      row93: [],
      row103: [],
      row109: [],
      row119: [],
      row129: [],
      row137: [],
      row140: [],
      row12: [],
      row22: [],
      row31: [],
      row41: [],
      row51: [],
      row60: [],
      row70: [],
      row80: [],
      row89: [],
      row99: [],
      row114: [],
      row118: [],
      row124: [],
      row132: [],
      row133: [],
      row144: [],
      row146: [],
      row148: [],
      row149: [],
      row150: [],
      row155: [],
      row160: [],
      row165: [],
      row172: [],
      row176: [],
      row170: [],
      concert_id: 0,
      zones: [],
      data: [],
      seats: [],
      selectedFile: "",
      banner: "",
      filename: "",
      docs: {},
      title: {},
      zone: "",
      statusedit: false,
      zonecount: 0,
      style: '',
      remarks: []
    };
  },
  mounted() {
    this.concert_id = this.$route.params.id;
    if (this.concert_id != 0) {
      this.getzones();
      this.getSeats();
      this.getConcert();
      if (window.localStorage) {
        if (!localStorage.getItem('firstLoad')) {
          localStorage['firstLoad'] = true;
          window.location.reload();
        }
        else
          localStorage.removeItem('firstLoad');
      }
    } else {
      this.title.remark_id = 1
    }
    this.getremark()
  },
  methods: {
    addLeadingZeros(num) {
      var val = ''
      if (String(num).length == 1) {
        val = '0' + num
      } else {
        val = num
      }
      return val
    },
    getremark() {
      RemarkService.getremarks().then((res) => {
        this.remarks = res.data
      })
    },
    newdate(date) {
      var d = new Date(date)
      var dd = this.addLeadingZeros(d.getDate())
      var mm = this.addLeadingZeros(parseInt(d.getMonth() + 1))
      var yy = parseInt(d.getFullYear()) + 543
      return dd + '/' + mm + '/' + yy
    },
    editzone() {
      ZoneService.deletezone(this.concert_id).then(() => {
        // console.log(res.data);
        for (let z = 0; z < this.zonecount; z++) {
          var nozone = z + 1;
          var zonedata = {
            concert_id: this.concert_id,
            name: "โซน " + nozone,
            color: "background-color:white",
          };
          ZoneService.createzone(zonedata).then(() => {
            // console.log(res.data);
          });
        }
        var seat = {}
        SeatService.updatesetzone(this.concert_id, seat).then(() => {
          // console.log(res.data);
          alert("บันทึกสำเร็จ");
          this.$router.push("/zone/" + this.concert_id);
          setTimeout(function () {
            location.reload();
          }, 500);
          window.scrollTo(0, 0);
        })
      })
    },
    edit() {
      this.statusedit = true
    },
    cancleeditzone() {
      this.statusedit = false
    },
    create() {
      var concert = {
        status: 1, concert_name: this.title.concert_name, status_show: false, remark_id: this.title.remark_id,
        date: this.convertdatesave(this.title.date), banner_name: this.title.concert_name
      };
      ConcertService.createconcert(concert).then((res) => {
        this.concert_id = res.data.id;
        var no = 0;
        for (let c = 0; c < 179; c++) {
          no = c + 1;
          var color = "background-color:white";
          var data = {
            concert_id: this.concert_id,
            seat: no,
            class: "strong",
            color: color,
            status: true,
          };
          SeatService.createseat(data).then(() => {
            // console.log(res.data);
          });
        }
        for (let z = 0; z < this.zone; z++) {
          var nozone = z + 1;
          var zonedata = {
            concert_id: this.concert_id,
            name: "โซน " + nozone,
            color: "background-color:black",
          };
          ZoneService.createzone(zonedata).then(() => {
            // console.log(res.data);
          });
        }
        this.$router.push("/zone/" + this.concert_id);
        setTimeout(function () {
          location.reload();
        }, 1500);
        window.scrollTo(0, 0);
      });
    },
    createzone() {
      if (this.title.concert_name == "") {
        alert("กรุณากรอกชื่อคอนเสิร์ต");
      } else if (this.zone == "") {
        alert("กรุณากรอกจำนวนโซน");
      } else {
        if (this.concert_id == 0) {
          this.create();
        } else {
          for (let z = 0; z < this.zone; z++) {
            var no = z + 1;
            var data = {
              concert_id: this.concert_id,
              name: "โซน " + no,
              color: "background-color:white",
            };
            ZoneService.createzone(data).then(() => {
              // console.log(res);
            });
          }
          var con = {
            concert_name: this.title.concert_name,
          };
          ConcertService.updateconcert(this.concert_id, con).then(() => {
            // console.log(res.data);
          });

          alert("บันทึกสำเร็จ");
          setTimeout(function () {
            location.reload();
          }, 500);
          window.scrollTo(0, 0);
        }

      }
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.filename = selectedFile.name;
      // console.log(selectedFile);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      var http = LinkService.getLink() + "/upload";
      axios
        .post(http, formData)
        .then((res) => {
          // console.log(res.data.name);
          this.title.name = res.data.name;
          this.title.path = "uploads/" + res.data.name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSeats() {
      SeatService.getseatByID(this.concert_id).then((res) => {
        this.seats = res.data;
        // console.log(this.seats);
        this.generaterow();
      });
    },
    convertdate(date) {
      var dates = new Date(date)
      var dd = dates.getDate()
      var mm = dates.getMonth() + 1
      var yy = dates.getFullYear()
      if (String(dd).length == 1) {
        dd = '0' + dd
      }
      if (String(mm).length == 1) {
        mm = '0' + mm
      }
      return dd + '/' + mm + '/' + yy
    },
    getConcert() {
      ConcertService.getconcert(this.concert_id).then((res) => {
        // console.log(res.data);
        this.title = res.data;
        this.title.date = this.convertdate(this.title.date)
        // console.log(this.title.date);
        this.style = 'color:' + this.title.color + ';font-family:' + this.title.font + ';'
        // console.log(this.style);

      });
    },
    getzones() {
      ZoneService.getzonebyconcertid(this.concert_id).then((res) => {
        this.zones = res.data;
        this.zonecount = this.zones.length
      });
    },
    generaterow() {
      this.row1= []
      this.row6= []
      this.row16= []
      this.row26= []
      this.row35= []
      this.row45= []
      this.row55= []
      this.row64= []
      this.row74= []
      this.row84= []
      this.row93= []
      this.row103= []
      this.row109= []
      this.row119= []
      this.row129= []
      this.row137= []
      this.row140= []
      this.row12= []
      this.row22= []
      this.row31= []
      this.row41= []
      this.row51= []
      this.row60= []
      this.row70= []
      this.row80= []
      this.row89= []
      this.row99= []
      this.row114= []
      this.row118= []
      this.row124= []
      this.row132= []
      this.row133= []
      this.row144= []
      this.row146= []
      this.row148= []
      this.row149= []
      this.row150= []
      this.row155= []
      this.row160= []
      this.row165= []
      this.row172= []
      this.row176= []
      this.row170= []
      for (let i = 0; i < this.seats.length; i++) {
        var data = {
          id: this.seats[i].id,
          seat: this.seats[i].seat,
          class: this.seats[i].class,
          color: this.seats[i].color,
          price: this.seats[i].price,
          status: this.seats[i].status,
          seatstatus: this.seats[i].seatstatus,
        };
        // console.log(data);
        if (
          this.seats[i].seat >= 1 &&
          this.seats[i].seat <=5
        ) {
          this.row1.push(data);
        } else if (
          this.seats[i].seat > 5 &&
          this.seats[i].seat <=11
        ) {
          this.row6.push(data);
        } else if (
          this.seats[i].seat > 11 &&
          this.seats[i].seat <=15
        ) {
          this.row12.push(data);
        } else if (
          this.seats[i].seat > 15 &&
          this.seats[i].seat <=21
        ) {
          this.row16.push(data);
        } else if (
          this.seats[i].seat > 21&&
          this.seats[i].seat <=25
        ) {
          this.row22.push(data);
        } else if (
          this.seats[i].seat > 25 &&
          this.seats[i].seat <=30
        ) {
          this.row26.push(data);
        } else if (
          this.seats[i].seat > 30 &&
          this.seats[i].seat <=34
        ) {
          this.row31.push(data);
        } else if (
          this.seats[i].seat > 34 &&
          this.seats[i].seat <=40
        ) {
          this.row35.push(data);
        } else if (this.seats[i].seat > 40 &&
          this.seats[i].seat <=44
          ) {
          this.row41.push(data);
        } else if (this.seats[i].seat > 44 &&
          this.seats[i].seat <=50
          ) {
          this.row45.push(data);
        } else if (this.seats[i].seat > 50 &&
          this.seats[i].seat <=54
        ) {
          this.row51.push(data);
        } else if (
          this.seats[i].seat > 54 &&
          this.seats[i].seat <=59
        ) {
          this.row55.push(data);
        } else if (
          this.seats[i].seat > 59 &&
          this.seats[i].seat <=63
        ) {
          this.row60.push(data);
        } else if (
          this.seats[i].seat > 63 &&
          this.seats[i].seat <=69
        ) {
          this.row64.push(data);
        } else if (
          this.seats[i].seat > 69 &&
          this.seats[i].seat <=73
        ) {
          this.row70.push(data);
        } else if (
          this.seats[i].seat > 73 &&
          this.seats[i].seat <=79
        ) {
          this.row74.push(data);
        } else if (
          this.seats[i].seat > 79 &&
          this.seats[i].seat <=83
        ) {
          this.row80.push(data);
        } else if (
          this.seats[i].seat > 83 &&
          this.seats[i].seat <=88
        ) {
          this.row84.push(data);
        } else if (
          this.seats[i].seat > 88 &&
          this.seats[i].seat <=92
        ) {
          this.row89.push(data);
        } else if (
          this.seats[i].seat > 92 &&
          this.seats[i].seat <=98
        ) {
          this.row93.push(data);
        } else if (
          this.seats[i].seat > 98 &&
          this.seats[i].seat <=102
        ) {
          this.row99.push(data);
        } else if (
          this.seats[i].seat > 102 &&
          this.seats[i].seat <=108
        ) {
          this.row103.push(data);
        } else if (
          this.seats[i].seat > 108 &&
          this.seats[i].seat <=113
        ) {
          this.row109.push(data);
        } else if (
          this.seats[i].seat > 113 &&
          this.seats[i].seat <=117
        ) {
          this.row114.push(data);
        } else if (this.seats[i].seat == 118) {
          this.row118.push(data);
        } else if (
          this.seats[i].seat > 118 &&
          this.seats[i].seat <=123
        ) {
          this.row119.push(data);
        } else if (
          this.seats[i].seat > 123 &&
          this.seats[i].seat <=128
        ) {
          this.row124.push(data);
        } else if (
          this.seats[i].seat > 128 &&
          this.seats[i].seat <=131
        ) {
          this.row129.push(data);
        } else if (this.seats[i].seat == 132) {
          this.row132.push(data);
        }else if (
          this.seats[i].seat > 132 &&
          this.seats[i].seat <=136
        ) {
          this.row133.push(data);
        } else if (
          this.seats[i].seat > 136 &&
          this.seats[i].seat <=139
        ) {
          this.row137.push(data);
        } else if (
          this.seats[i].seat > 139 &&
          this.seats[i].seat <=143
        ) {
          this.row140.push(data);
        } else if (
          this.seats[i].seat > 143 &&
          this.seats[i].seat <=145
        ) {
          this.row144.push(data);
        } else if (
          this.seats[i].seat > 145 &&
          this.seats[i].seat <=147
        ) {
          this.row146.push(data);
        } else if (
          this.seats[i].seat == 148 
        ) {
          this.row148.push(data);
        } else if (
          this.seats[i].seat == 149
        ) {
          this.row149.push(data);
        } else if (
          this.seats[i].seat > 149 &&
          this.seats[i].seat <=154
        ) {
          this.row150.push(data);
        }else if (
          this.seats[i].seat > 154 &&
          this.seats[i].seat <=159
        ) {
          this.row155.push(data);
        }else if (
          this.seats[i].seat > 159 &&
          this.seats[i].seat <=164
        ) {
          this.row160.push(data);
        }else if (
          this.seats[i].seat > 164 &&
          this.seats[i].seat <=169
        ) {
          this.row165.push(data);
        }else if (
          this.seats[i].seat > 169 &&
          this.seats[i].seat <=171
        ) {
          this.row170.push(data);
        }else if (
          this.seats[i].seat > 171 &&
          this.seats[i].seat <=175
        ) {
          this.row172.push(data);
        }else if (
          this.seats[i].seat > 175 &&
          this.seats[i].seat <=179
        ) {
          this.row176.push(data);
        }
      }
      // console.log(this.row11);
    },
    generaterow1() {
      for (let i = 0; i < 12; i++) {
        var no = i + 1;
        var color = "background-color:black";
        // if (i == 5 || i == 6) {
        //   this.row1.push({
        //   id:'',
        //   class:'notbadge',
        // })
        // }
        // else{
        // if (i > 6) {
        //   no = no-2
        // }
        this.row1.push({
          id: no,
          class: "badge",
          color: color,
        });
        // }
      }
    },
    generaterow11() {
      for (let i = 12; i < 24; i++) {
        var no = i + 1;
        var color = "background-color:black";
        this.row11.push({
          id: no,
          class: "badge",
          color: color,
        });
      }
    },
    generaterow111() {
      for (let i = 26; i < 38; i++) {
        var no = i + 1;
        var color = "background-color:black";
        this.row111.push({
          id: no,
          class: "badge",
          color: color,
        });
      }
    },
    selectcolor(event) {
      this.color = event.target.value;
      var color = "background-color:" + this.color;
      for (let l = 0; l < this.list.length; l++) {
        for (let r = 0; r < this.seats.length; r++) {
          if (this.seats[r].seat == this.list[l].seat) {
            this.seats[r].color = color;
          }
        }
      }
    },
    selectzone(event) {
      // console.log(event.target.value);
      ZoneService.getzone(event.target.value).then((res) => {
        this.data.price = res.data.price;
        var c = res.data.color.split(":");
        this.color = c[1];
        SeatService.getseatbyzoneid(event.target.value).then((res) => {
          this.list = res.data;
          // console.log(res.data);
        });
      });
    },
    convertdatesave(date) {
      // console.log(date);
      var dd = date.split('/')[1]
      var mm = date.split('/')[0]
      var yy = date.split('/')[2]
      return yy + '/' + dd + '/' + mm
    },
    saveconcert() {
      if (this.title.concert_name == null) {
        alert("กรุณากรอกชื่อคอนเสิร์ต");
      }
      else if (this.concert_id != 17 && this.title.date == null) {
        alert("กรุณาเลือกวันที่แสดงคอนเสิร์ต");
      } else {
        var con = {
          concert_name: this.title.concert_name,
          date: this.convertdatesave(this.title.date),
          remark_id: this.title.remark_id,
        };
        ConcertService.updateconcert(this.concert_id, con).then(() => {
          alert("บันทึกสำเร็จ");
          this.getzones();
          this.getSeats();
          this.getConcert();
        });
      }
    },
    save() {
      if (this.title.concert_name == null) {
        alert("กรุณากรอกชื่อคอนเสิร์ต");
      } else if (this.data.zone == null) {
        alert("กรุณาเลือกโซน");
      } else if (this.data.price == null) {
        alert("กรุณากรอกราคา");
      } else if (this.list.length == 0) {
        alert("กรุณาเลือกที่นั่ง");
      } else {
        var color = "background-color:" + this.color;
        for (let l = 0; l < this.list.length; l++) {
          for (let r = 0; r < this.seats.length; r++) {
            if (this.seats[r].seat == this.list[l].seat) {
              var data = {
                zone: this.data.zone,
                price: this.data.price,
                class: this.seats[r].class,
                color: color,
                status: this.seats[r].status,
              };
              // console.log(data);
              SeatService.updateseat(this.seats[r].id, data).then(() => {
                // console.log(res.data);
              });
            }
          }
        }
        var zone = {
          color: color,
          price: this.data.price,
        };
        ZoneService.updatezone(this.data.zone, zone).then(() => {
          // console.log(res);
        });
        alert("บันทึกสำเร็จ");
        this.getzones();
        this.getSeats();
        this.getConcert();
        // setTimeout(function () {
        //   location.reload();
        // }, 500);
        // window.scrollTo(0, 0);
      }
    },
    selectseat(event, s) {
      // console.log(s);
      var id = event.srcElement.id;
      var classs = event.srcElement.className;
      var element = document.getElementById(id);
      var c = s.color.split(":");
      // console.log(c);
      // console.log(classs);
      var selectbadge = classs.includes("noseat");
      // console.log(selectbadge);
      if (selectbadge) {
        element.classList.remove("noseat");
        element.classList.add("strong");
        document.getElementById(id).style.background = c[1];
        for (let l = 0; l < this.list.length; l++) {
          if (this.list[l].seat == id) {
            this.list.splice(l, 1);
          }
        }
      } else {
        document.getElementById(id).style.background = this.color;
        // element.classList.remove("badge");
        element.classList.add("noseat");
        this.list.push({
          seat: id,
        });
      }
      // console.log(this.list);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


@media only screen and (min-width: 600px) {
  .row150-left{
    padding-left: 6%!important;
  }
  .row155-left{
    padding-left: 3%!important;
  }
  .row160-left{
    padding-left: 1%!important;
  }
  
  .toilet{
    margin-left: -3.5%!important;
    width: 5.2%!important;
    margin-top: -24%!important;
  }
  .sound{
    font-size:12px!important;
  }
  .fireexitleft{
    margin-left: -1%!important;
    width: 3%!important;
    margin-top: -28%!important;
  }
  .fireexitleft2{
    margin-left: -1%!important;
    width: 3%!important;
    margin-top: -5.5%!important;
  }
  .row129{
    height: 95px!important;
  }
  .strong114 {
  width: 2em !important;
  height: 2em !important;
  margin-bottom: 5px;
  /* padding: 2%; */
  padding-top: 0em !important;
}

  .selectstrong {
  width: 2em!important;
  height: 2em!important;
  margin-bottom: 5px!important;
  padding-top: 0em!important;
}

  .row109{
    height: 156px!important;
  }

.strongbottom {
  text-align: center;
  width: 2em!important;
  height:2em!important;
}

.strong170 {
  text-align: center;
  width: 2em!important;
  height: 2em!important;

}

strong {
  font-size: 12px!important;
  width: 2em!important;
  height: 2em!important;
  margin-bottom: 5px!important;
  padding-top: 0em!important;
}

.strong {
  font-size: 12px!important;
  width: 2em!important;
  height: 2em!important;
  margin-bottom: 5px!important;
  padding-top: 0em!important;
}
  .textmd148 {
  margin-top: 6rem!important;
}

.textmd149 {
  margin-top: 8rem!important;
}
  .row144{
  width: 20%!important;
}
.row1-144{
  width: 1.65%!important;
}
  .row12-99{
  width: 77%!important;
}
.before-dj{
  width: 2%!important;
}
.row170{
  width: 12%!important;
}
.rowshape{
  width: 7%!important;
}
.before-mdroom{
  width: 2.3%!important;
}
.row148{
  width: 26%!important;
}
.row150{
  width: 33.5%!important;
}
.row-bathroom{
  width: 8%!important;
}
.mdroom{
  padding-top: 5rem!important;
}
.rowheight-132{
  height: 14.8rem!important;
  margin-left: 2.5%!important
}
.row128{
  width: 110%!important;
  margin-top: -65%!important;
  margin-right: -20%!important;
    margin-left: 0%!important;
}
.row143{
  width: 240%!important;
  margin-top: 28%!important
}
.stagetable{
  width: 6%!important;
    margin-left: -27.7%!important;
    margin-top: 7%!important;
    height: 33px;
  position: absolute;
}
}

@media only screen and (min-width: 768px) {
  .row150-left{
    padding-left: 2%!important;
  }
  .row155-left{
    padding-left: 1%!important;
  }
  .row160-left{
    padding-left: 1%!important;
  }
  .toilet{
    margin-left: 0%!important;
    margin-top: -15%!important;
    width: 3.2%!important;
    
  }
  .sound{
    font-size:12px!important;
  }
  .fireexitleft{
    margin-left: 1%!important;
    width: 2%!important;
    margin-top: -16%!important;
  }
  .fireexitleft2{
    margin-left: 1%!important;
    width: 2%!important;
    margin-top: -4%!important;
  }
  .row129{
    height: 96px!important;
  }
  .strong114 {
  width: 2em !important;
  height: 2em !important;
  margin-bottom: 5px;
  /* padding: 2%; */
  padding-top: 0em !important;
}
  .selectstrong {
  width: 2em!important;
  height: 2em!important;
  margin-bottom: 5px!important;
  padding-top: 0em!important;
}

.strong114 {
  width: 2em !important;
  height: 2em !important;
  display: inline-block;
  /* width: 50%; */
  border: 1px solid #000;
  text-align: center;
  margin-bottom: 5px;
  /* padding: 2%; */
  padding-top: 0em !important;
}

  .row109{
    height: 157px!important;
  }
  .strong170 {
  text-align: center;
  width: 2em!important;
  height: 2em!important;

}

strong {
  font-size: 12px!important;
  width: 2em!important;
  height: 2em!important;
  margin-bottom: 5px!important;
  padding-top: 0em!important;
}

.strong {
  font-size: 12px!important;
  width: 2em!important;
  height: 2em!important;
  margin-bottom: 5px!important;
  padding-top: 0em!important;
}
  .textmd148 {
  margin-top: 2.9rem!important;
}

.textmd149 {
  margin-top: 6rem!important;
}

  .row144{
  width: 20%!important;
}
  .row12-99{
  width: 76%!important;
}
.row1-144{
  width: 4.65%!important;
}
.before-dj{
  width: 5%!important;
}
.row170{
  width: 16%!important;
}
.rowshape{
  width: 9%!important;
}
.before-mdroom{
  width: 5%!important;
}
.row148{
  width: 32%!important;
}
.row150{
  width: 35.5%!important;
}
.row-bathroom{
  width: 10%!important;
}
.mdroom{
  padding-top: 2rem!important;
}
.rowheight-132{
  height: 15.4rem!important;
  margin-left: 1.9%!important
}
.row128{
  width: 109%!important;
  margin-top: -41%!important;
  margin-right: -20%!important;
    margin-left: -3%!important;
}
.row143{
  width: 225%!important;
  margin-top: 19%!important
}
.stagetable{
  width: 6%!important;
  margin-left:-24%!important;
  margin-top: 6%!important;
  position: absolute;
}
}
@media only screen and (min-width: 850px) {
  .row150-left{
    padding-left: 3%!important;
  }
  .row155-left{
    padding-left: 2%!important;
  }
  .row160-left{
    padding-left: 1%!important;
  }
  
  .toilet{
    margin-left: -4%!important;
    width: 5%!important;
    height: 300px!important;
    margin-top: -21%!important;
  }
  .sound{
    font-size:14px!important;
  }
  .fireexitleft{
    margin-left: -1.5%!important;
    width: 2.5%!important;
    margin-top: -22%!important;
  }
  .fireexitleft2{
    margin-left: -1.5%!important;
    width: 2.5%!important;
    margin-top: -6%!important;
  }
  .row129{
    height: 159px!important;
  }
  .strong114 {
  width: 3em !important;
  height: 3em !important;
  margin-bottom: 10px;
  /* padding: 2%; */
  padding-top: 0.5em !important;
}

  .selectstrong {
    font-size: 14px!important;
  width: 3em!important;
  height: 3em!important;
  margin-bottom: 10px!important;
  padding-top: 0.5em!important;
}

  .row109{
    height: 265px!important;
  }

.strongbottom {
  text-align: center;
  width: 3em!important;
  height:3em!important;
}

.strong170 {
  text-align: center;
  width: 3em!important;
  height: 3em!important;

}

strong {
  font-size: 14px!important;
  width: 3em!important;
  height: 3em!important;
  margin-bottom: 10px!important;
  padding-top: 0.5em!important;
}

.strong {
  font-size: 14px!important;
  width: 3em!important;
  height: 3em!important;
  margin-bottom: 10px!important;
  padding-top: 0.5em!important;
}
  .textmd148 {
  margin-top: 9rem!important;
}

.textmd149 {
  margin-top: 12rem!important;
}
  .row144{
  width: 20%!important;
}
.row1-144{
  width: 1.65%!important;
}
  .row12-99{
  width: 77%!important;
}
.before-dj{
  width: 2%!important;
}
.row170{
  width: 12%!important;
}
.rowshape{
  width: 7%!important;
}
.before-mdroom{
  width: 1.8%!important;
}
.row148{
  width: 26%!important;
}
.row150{
  width: 32%!important;
}
.row-bathroom{
  width: 7.8%!important;
}
.mdroom{
  padding-top: 8.5rem!important;
}
.rowheight-132{
  height: 403px!important;
  margin-left: 2.5%!important
}
.row128{
  width: 112%!important;
  margin-top: -38%!important;
  margin-right: -20%!important;
    margin-left: 0%!important;
}
.row143{
  width: 226%!important;
    margin-top: 50%!important;
}
.stagetable{
  width: 5.2%!important;
    margin-left: -23.8%!important;
    margin-top: 6.3%!important;
    height: 46px!important;
  position: absolute;
}
}

.row150-left{
    padding-left: 2%;
  }
  .row155-left{
    padding-left: 0%;
  }
  .row160-left{
    padding-left: 0%;
  }
  .toilet{
  margin-left: -5%;
    margin-top: -27%;
    width: 7.2%;
  }
  .sound{
    font-size:14px;
  }
.fireexitleft{
    margin-left: 0.5%;
    width: 3%;
    margin-top: -23%;
  }
  .fireexitleft2{
    margin-left: 0.6%;
    width: 3%;
    margin-top: -28%;
  }
.row129{
    height: 142px;
  }
.row109{
    height: 234px;
  }
.row144{
  width: 20%;
}
  .row12-99{
  width: 76%;
}
.row1-144{
  width: 4.65%;
}
.before-dj{
  width: 5%;
}
.row170{
  width: 12%;
}
.rowshape{
  width: 9%;
}
.before-mdroom{
  width: 5%;
}
.row148{
  width: 29%;
}
.row150{
  width: 35.5%;
}
.row-bathroom{
  width: 10%;
}
.mdroom{
  padding-top: 7rem;
}
.rowheight-132{
  height: 27.56rem;
  margin-left: 1.6%;
}
.row128{
  width: 190%;
  margin-top: 150%;
  margin-right: -20%;
    margin-left: 0%;
}
.row143{
  width: 230%;
  margin-top: 44%
}

.textmd148 {
  margin-top: 6rem;
  margin-left: 0%;
  position: absolute;
  border: 1px solid #000;
  width: 3em;
  height: 3em;
}

.textmd149 {
  margin-top: 8.5rem;
  position: absolute;
  border: 1px solid #000;
  width: 3em;
  height: 3em;
}

.stagetable{
  width: 6%;
  margin-left:-24%;
  margin-top: 6%;
  position: absolute;
}

body {
  margin: 60px;
  background: white;
}

.flex-container {
  display: flex;
}

.flex {
  display: inline-flex;
}

.dot {
  height: 25px;
  width: 25px;
  border: 50%;
  display: inline-block;
}

.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9;
  /*important*/
  border: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9;
  /*no need*/
  font-size: 20px;
  font-weight: 100;
}

.neonText {
  color: #f269d3;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #bc13fe,
    0 0 82px #bc13fe,
    0 0 92px #bc13fe,
    0 0 102px #bc13fe,
    0 0 151px #bc13fe;
  height: 70%;
}

.seatconfirm {
  width: 1.4%;
  position: absolute;
  margin-top: -2px;
  margin-left: -5px;
}

h1 {
  text-align: center;
  font-size: 1.9rem;
  animation: pulsate 1.5s infinite alternate;
  border: 0.1rem solid #fc0eb6;
  border: 1rem;
  padding: 0.3em;
  box-shadow: 0 0 .2rem #fff,
    0 0 .2rem #fff,
    0 0 2rem #bc13fe,
    0 0 0.8rem #bc13fe,
    0 0 2.8rem #f478cf,
    inset 0 0 1.3rem #f478cf;
}

@keyframes pulsate {
  100% {
    text-shadow:
      0 0 4px #ed0f3e,
      0 0 11px #ed0f3e,
      0 0 19px #ed0f3e,
      0 0 40px #f367f1,
      0 0 80px #f367f1,
      0 0 90px #f367f1,
      0 0 100px #f367f1,
      0 0 150px #f367f1;
  }

  0% {
    text-shadow:
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 6px #fff,
      0 0 10px #bc13fe,
      0 0 45px #bc13fe,
      0 0 55px #bc13fe,
      0 0 70px #bc13fe,
      0 0 80px #bc13fe;
  }

}


.table {
  padding: 0px !important;
  margin: 0px !important;
}

.strongbottom {
  display: inline-block !important;
  /* width: 60%; */
  border: 1px solid #000;
  text-align: center;
  margin-bottom: 0px !important;
}

.strong170 {
  display: inline-grid !important;
  border: 1px solid #000;
  text-align: center;
  width: 3em;
  height: 3em;

}

.divseat {
  border: 1px solid #000;
  width: 20px;
  display: inline-block;
  margin: 10px 30px;
}

strong {
  font-size: 12px;
  display: inline-block;
  width: 3em;
  height: 3em;
  border: 1px solid #000;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 0.5em
}

.strong {
  font-size: 12px;
  display: inline-block;
  width: 3em;
  height: 3em;
  border: 1px solid #000;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 0.5em
}

.polenew {
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  padding: 6%;
  margin-left: 25%;

}

.polerec {
  display: inline-block;
  width: 60%;
  height: 60%;
  border: 1px solid #000;
  text-align: center;
  margin-bottom: 10px;
  color: white;
  background-color: black;
  font-size: 14px;

}

.strong139 {
  display: inline-block;
  width: 60%;
  border: 1px solid white;
  text-align: center;
  margin-bottom: 10px;
}

.selectstrong {
  background-color: red;
  font-size: 12px;
  display: inline-block;
  width: 3em;
  height: 3em;
  border: 1px solid #000;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 0.5em
}

.strong114 {
  width: 3em;
  height: 3em;
  display: inline-block;
  /* width: 50%; */
  border: 1px solid #000;
  text-align: center;
  margin-bottom: 10px;
  /* padding: 2%; */
  padding-top: 0.5em;
}

.onesection {
  width: 2%;
  padding: 0px;
  vertical-align: top;
}

.word {
  margin: 50px -30px;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 100px;
  text-align: center;
}

.trapezoid {
  border-bottom: 50px solid black;
  border-right: 50px solid transparent;
  height: 0;
  /* width: 100px;
    margin-left: 0%;*/
  padding-top: 20%;
  width: 142px;
  margin-left: 0%;
  color: white;
  vertical-align: bottom;
  text-align: center;
}



.headtop {
  margin-top: 8% !important;
}


</style>
