<template>
<div class="container row">
    <div class="card mt-5 col-md-12" style="width: 500px">
    <div class="card-body login-card-body mt-3">
      <h3 class="login-box-msg" style="text-align:center">เข้าสู่ระบบ</h3>
      <form>
        <div class="card-body mt-5">
          <div class="form-group">
            <label for="username">ชื่อผู้ใช้งาน</label>
            <input
              v-model="user.username"
              v-on:keyup.enter="signIn()"
              type="text"
              min="1"
              class="form-control form-control-sm"
              id="username"
              placeholder="กรุณากรอกชื่อผู้ใช้งาน"
            />
          </div>
          <div class="form-group mt-3">
            <label for="password">รหัสผ่าน</label>
            <input
              v-model="user.password"
              v-on:keyup.enter="signIn()"
              type="password"
              class="form-control form-control-sm"
              id="password"
              placeholder="กรุณากรอกรหัสผ่าน"
            />
          </div>
          <div class="social-auth-links text-center mb-3 mt-3">
            <button @click="signIn()" type="button" class="btn btn-success">เข้าสู่ระบบ</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import ConcertService from "../services/ConcertService.js";

export default {
  name: "Nav",  
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id:0,
      menu:[],
      user:{}
    }
  },
  mounted() {
    localStorage.removeItem('wx');
    // if (this.currentUser) {
    //   this.$router.push("/bannerlist");
    // }
    this.getconcert()
  },
  methods: {
     signIn() {
      if (this.user.username == "" || this.user.username == null) {
        alert("กรุณากรอกชื่อผู้ใช้งาน");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else {
        var user = {
          username: this.user.username,
          password: this.user.password,
        };
        this.$store.dispatch("auth/login", user).then(
          () => {
            this.loading = true;
            // const loggedIn = localStorage.getItem('user');
            // var user = JSON.parse(loggedIn)   
            this.$router.push("/");
            setTimeout(function () {
              location.reload();
            }, 500);
          },
          (error) => {
            alert("ชื่อหรือรหัสผ่านไม่ถูกต้อง");
            console.log(error);
            // this.loading = false;
            // this.message =
            //   (error.response &&
            //     error.response.data &&
            //     error.response.data.message) ||
            //   error.message ||
            //   error.toString();
          }
        );
      }
    },
    getconcert() {
      ConcertService.getconcerts().then((res) => {
        // console.log(res.data);
        this.concert_id = res.data.id;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04AA6D;
  color: white;
}
.card {
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
}
body{
background-color: gray;
}
</style>
