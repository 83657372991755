<template>
  <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout">
    <div class="row mb-3">
      <div class="col-md-6">
        <div class="form-group">
          <label for="exampleInputPassword1">แบนเนอร์</label>

          <div class="input-group">
            <input
              type="file"
              accept="image/*"
              @change="onFileChange"
              class="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
            />
            <button
              :disabled="!this.selectedFile"
              @click="onUploadFile"
              class="btn btn-primary"
              type="button"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="title.path">
        <img :src="LinkService.getLink()+'/' + title.path" />
      </div>
      <div class="col-md-12 mt-3">
        <label for="exampleFormControlInput1" class="form-label">ชื่องาน</label>
        <input
          v-model="title.concert_name"
          type="text"
          class="form-control"
          id="exampleFormControlInput1"
          placeholder="ชื่องาน"
        />
      </div>
      <div class="col-md-6 mt-3">
        <label for="exampleFormControlInput1" class="form-label">โซน</label>
        <select
          v-model="data.zone"
          class="form-select"
          aria-label="Default select example"
        >
          <option v-for="(z, i) in zones" :key="i" :value="z.id">
            {{ z.name }}
          </option>
        </select>
      </div>
      <div class="col-md-6 mt-3">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">ราคา</label>
          <input
            v-model="data.price"
            type="number"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="ราคา"
          />
        </div>
      </div>
    </div>
   <div class="row">
      <div
        class="col-md-9"
        style="text-align: center; background-color: #c7c7c9"
      >
        <table class="table table-borderless">
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r, i) in row1"
              :key="i"
            >
              <td
                v-if="r.status"
                :class="r.class"
                :style="r.color"
                :id="r.seat"
                @click="selectseat($event, r)"
              >
                {{ r.seat }}
              </td>
              <td
                v-else
                :class="r.class"
                style="background-color: red"
                :id="r.seat"
              >
                {{ r.seat }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r2, j) in row2"
              :key="j"
            >
              <td
              v-if="r2.status"
                :class="r2.class"
                :style="r2.color"
                :id="r2.seat"
                @click="selectseat($event, r2)"
              >
                {{ r2.seat }}
              </td>
              <td
                v-else
                :class="r2.class"
                style="background-color: red"
                :id="r2.seat"
              >
                {{ r2.seat }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r3, k) in row3"
              :key="k"
            >
              <td
              v-if="r3.status"
                :class="r3.class"
                :style="r3.color"
                :id="r3.seat"
                @click="selectseat($event, r3)"
              >
                {{ r3.seat }}
              </td>
              <td
                v-else
                :class="r3.class"
                style="background-color: red"
                :id="r3.seat"
              >
                {{ r3.seat }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="col-md-1"
        style="padding: 0px; text-align: center; background-color: gray"
      >
        <table class="table table-borderless">
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r11, k) in row11"
              :key="k"
            >
              <td
              v-if="r11.status"
                :class="r11.class"
                :style="r11.color"
                :id="r11.seat"
                @click="selectseat($event, r11)"
              >
                {{ r11.seat }}
              </td>
              <td
                v-else
                :class="r11.class"
                style="background-color: red"
                :id="r11.seat"
              >
                {{ r11.seat }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r22, k) in row22"
              :key="k"
            >
              <td
              v-if="r22.status"
                :class="r22.class"
                :style="r22.color"
                :id="r22.seat"
                @click="selectseat($event, r22)"
              >
                {{ r22.seat }}
              </td>
              <td
                v-else
                :class="r22.class"
                style="background-color: red"
                :id="r22.seat"
              >
                {{ r22.seat }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r33, k) in row33"
              :key="k"
            >
              <td
              v-if="r33.status"
                :class="r33.class"
                :style="r33.color"
                :id="r33.seat"
                @click="selectseat($event, r33)"
              >
                {{ r33.seat }}
              </td>
              <td
                v-else
                :class="r33.class"
                style="background-color: red"
                :id="r33.seat"
              >
                {{ r33.seat }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-2" style="text-align: center; background-color: wheat">
        <table class="table table-borderless">
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r111, k) in row111"
              :key="k"
            >
              <td
              v-if="r111.status"
                :class="r111.class"
                :style="r111.color"
                :id="r111.seat"
                @click="selectseat($event, r111)"
              >
                {{ r111.seat }}
              </td>
              <td
                v-else
                :class="r111.class"
                style="background-color: red"
                :id="r111.seat"
              >
                {{ r111.seat }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r222, k) in row222"
              :key="k"
            >
              <td
              v-if="r222.status"
                :class="r222.class"
                :style="r222.color"
                :id="r222.seat"
                @click="selectseat($event, r222)"
              >
                {{ r222.seat }}
              </td>
              <td
                v-else
                :class="r222.class"
                style="background-color: red"
                :id="r222.seat"
              >
                {{ r222.seat }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              style="text-align: center"
              class="flex"
              v-for="(r333, k) in row333"
              :key="k"
            >
              <td
              v-if="r333.status"
                :class="r333.class"
                :style="r333.color"
                :id="r333.seat"
                @click="selectseat($event, r333)"
              >
                {{ r333.seat }}
              </td>
              <td
                v-else
                :class="r333.class"
                style="background-color: red"
                :id="r333.seat"
              >
                {{ r333.seat }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-title">ที่นั่งที่เลือก</div>
              </div>
              <div class="col-md-12" v-for="(l, i) in list" :key="i">
                <span>{{ l.seat }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-title">เลือกสี</div>
              </div>
              <div class="col-md-3 mt-3">
                <input
                  type="color"
                  class="form-control form-control-color"
                  id="exampleColorInput"
                  :value="color"
                  @change="selectcolor($event)"
                  title="Choose your color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>     
    </div>
     <div class="col-md-12 mt-3" style="text-align: center">
        <button type="button" class="btn btn-success" @click="save()">
          บันทึก
        </button>
      </div>
  </div>
</template>

<script>
import ZoneService from "../services/ZoneService.js";
import SeatService from "../services/SeatService";
import ConcertService from "../services/ConcertService";
import axios from "axios";
import LinkService from "../services/LinkService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      list: [],
      color: "#0000FF",
      row1: [],
      row11: [],
      row111: [],
      row2: [],
      row22: [],
      row222: [],
      row3: [],
      row33: [],
      row333: [],
      concert_id: 0,
      zones: [],
      data: [],
      seats: [],
      selectedFile: "",
      banner: "",
      filename: "",
      docs: {},
      title: {},
    };
  },
  mounted() {
    this.getzones();
    this.concert_id = this.$route.params.id;
    // if (this.concert_id == 0) {
    //   var concert = {};
    //   ConcertService.createconcert(concert).then((res) => {
    //     this.concert_id = res.data.id;
    //     for (let c = 0; c < 38; c++) {
    //       var no = c + 1;
    //       var color = "background-color:black";
    //       var data = {
    //         concert_id: this.concert_id,
    //         seat: no,
    //         class: "badge",
    //         color: color,
    //       };
    //       SeatService.createseat(data).then((res) => {
    //         console.log(res.data);
    //       });
    //     }
    //     this.$router.push("/concert/" + this.concert_id);
    //     setTimeout(function () {
    //       location.reload();
    //     }, 500);
    //     window.scrollTo(0, 0);
    //   });
    // } else {
      this.getSeats();
      this.getConcert();
    // }
    // console.log(this.concert_id);
    // this.generaterow1();
    // this.generaterow11();
    // this.generaterow111();
  },
  methods: {
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.filename = selectedFile.name;
      // console.log(selectedFile);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      var http = LinkService.getLink()+"/upload";
      axios
        .post(http, formData)
        .then((res) => {
          // console.log(res.data.name);
          this.title.name = res.data.name;
          this.title.path = "uploads/" + res.data.name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSeats() {
      SeatService.getseatByID(this.concert_id).then((res) => {
        this.seats = res.data;
        // console.log(this.seats);
        this.generaterow();
      });
    },
    getConcert() {
      ConcertService.getconcert(this.concert_id).then((res) => {
        this.title = res.data;
        // console.log(this.title);
      });
    },
    getzones() {
      ZoneService.getzones().then((res) => {
        this.zones = res.data;
      });
    },
    generaterow() {
      for (let i = 0; i < this.seats.length; i++) {
        var data = {
            id: this.seats[i].id,
          seat: this.seats[i].seat,
          class: this.seats[i].class,
          color: this.seats[i].color,
          price: this.seats[i].price,
          status: this.seats[i].status,
          }
        if (i < 12) {
          this.row1.push(data);
        } else if (i < 24) {
          this.row2.push(data);
        } else if (i < 36) {
          this.row3.push(data);
        } else if (i < 37) {
          this.row11.push(data);
        } else if (i < 38) {
          this.row22.push(data);
        } else if (i < 39) {
          this.row33.push(data);
        } else if (i < 42) {
          this.row111.push(data);
        } else if (i < 45) {
          this.row222.push(data);
        } else if (i < 48) {
          this.row333.push(data);
        }
      }
      // console.log(this.row11);
    },
    generaterow1() {
      for (let i = 0; i < 12; i++) {
        var no = i + 1;
        var color = "background-color:black";
        // if (i == 5 || i == 6) {
        //   this.row1.push({
        //   id:'',
        //   class:'notbadge',
        // })
        // }
        // else{
        // if (i > 6) {
        //   no = no-2
        // }
        this.row1.push({
          id: no,
          class: "badge",
          color: color,
        });
        // }
      }
    },
    generaterow11() {
      for (let i = 12; i < 24; i++) {
        var no = i + 1;
        var color = "background-color:black";
        this.row11.push({
          id: no,
          class: "badge",
          color: color,
        });
      }
    },
    generaterow111() {
      for (let i = 26; i < 38; i++) {
        var no = i + 1;
        var color = "background-color:black";
        this.row111.push({
          id: no,
          class: "badge",
          color: color,
        });
      }
    },
    selectcolor(event) {
      this.color = event.target.value;
      var color = "background-color:" + this.color;
      for (let l = 0; l < this.list.length; l++) {
        for (let r = 0; r < this.seats.length; r++) {
          if (this.seats[r].seat == this.list[l].seat) {
            this.seats[r].color = color;
          }
        }
      }
    },
    save() {
      if (this.list.length > 0) {
        if (this.data.zone == null) {
          alert("กรุณาเลือกโซน");
        } else if (this.data.price == null) {
          alert("กรุณากรอกราคา");
        } else {
          var color = "background-color:" + this.color;
          for (let l = 0; l < this.list.length; l++) {
            for (let r = 0; r < this.seats.length; r++) {
              if (this.seats[r].seat == this.list[l].seat) {
                var data = {
                  zone: this.data.zone,
                  price: this.data.price,
                  class: "badge",
                  color: color,
                  status:true
                };
                // console.log(data);
                SeatService.updateseat(this.seats[r].id, data).then(() => {
                  // console.log(res.data);
                });
              }
            }
          }
        }
      }
      var concert = {
        concert_name: this.title.concert_name,
        name: this.title.name,
        path: this.title.path,
        status: true,
      };
      ConcertService.updateconcert(this.concert_id, concert).then(() => {
        // console.log(res.data);        
          setTimeout(function () {
            location.reload();
          }, 500);
          window.scrollTo(0, 0);
      });
    },
    selectseat(event,s) {
      // console.log(s);
      var id = event.srcElement.id;
      var classs = event.srcElement.className;
      var element = document.getElementById(id);
      // console.log(classs);
      // console.log(element);
      if (classs == "selectbadge") {
        element.classList.remove("selectbadge");
        element.classList.add("badge");
        if (s.color != "background-color:black") {
          var c = s.color.split(":")
          // console.log(c[1]);
          document.getElementById(id).style.background = c[1];
        }else{
        document.getElementById(id).style.background = "black";
        }
        for (let l = 0; l < this.list.length; l++) {
          if (this.list[l].seat == id) {
            this.list.splice(l, 1);
          }
        }
      } else if (classs == "badge") {
        document.getElementById(id).style.background = "blue";
        element.classList.remove("badge");
        element.classList.add("selectbadge");
        this.list.push({
          seat: id,
        });
      }
      // console.log(this.list);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 60px;
  background: white;
}
.flex-container {
  display: flex;
}
.flex {
  display: inline-flex;
}
.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.badge {
  cursor: pointer;
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: black; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.selectbadge {
  cursor: pointer;
  margin: 8px;
  padding: 6px;
  width: 40px;
  height: 40px;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.activebadge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 8px;
  transition: color 0.15s;
  width: 40px;
  height: 40px !important;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.theatre {
  display: flex;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cinema-seats {
  display: flex;
}

.cinema-seats .seat {
  cursor: pointer;
}

.select {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #da1b21;
  border-radius: 7px;
}

/* .active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #545e57;
  border-radius: 7px;
} */
/* // Left Seats */
.left {
  margin-right: 100px;
}

.left .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.left .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}
/* 
// Right Seats */

.right .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}

.right .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.right .screen {
  width: 1000px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  text-align: center;
  border: 10px solid;
  top: 300px;
  right: -60px;
}
/* //button */
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  font-size: 15px;
  position: relative;
  top: 400px;
  right: 800px;
}
</style>
