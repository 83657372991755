<template>
  <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout">
    <div class="col mb-3" style="text-align: right">
      <input
        id="my_file"
        class="form-control"
        type="file"
        accept="image/*"
        @change="onFileChange"
      />
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="onchange()"
        >
          <i class="fa fa-plus"></i> เพิ่มข้อมูล
        </button></a
      >
    </div>
    <table class="table table-bordered"> 
      <thead>
        <tr class="table-active">
            <th scope="col">ลำดับที่</th>
          <th scope="col">แบนเนอร์</th>
           <th scope="col">สถานะ</th>
            <th scope="col">จัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
            <td>
            {{i+1}}
          </td>
          <td>
            <img :src="l.banner" style="width:500px ">
          </td>
          <td v-if="l.status"><div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
  <label class="form-check-label" for="flexSwitchCheckChecked"></label>
</div></td>
<td v-if="!l.status"><div class="form-check form-switch">
  <input @change="onchangestatus($event,l.id)" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked">
  <label class="form-check-label" for="flexSwitchCheckChecked"></label>
</div></td>
 <td>
             <a>
                <button
                @click="getid(l.id)"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a>
          </td>
          <!-- <td v-if="l.status"><a style="color:black;text-decoration:none;" :href="'concert/'+l.id">ใช้งานอยู่</a
        ></td>
        <td v-if="!l.status"><a style="color:black;text-decoration:none;" :href="'concert/'+l.id">ไม่ใช้งาน</a
        ></td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>
 
<script>
import BannertService from "../services/BannertService";
import axios from "axios";
import LinkService from '../services/LinkService';
// import ConcertService from "../services/ConcertService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return { list: [],
    concert_id:0 };
  },
  mounted() {
    // ConcertService.getconcerts().then((res)=>{
      // console.log(res.data);
      this.concert_id = this.$route.params.id
    this.getbanners();
    if( window.localStorage )
  {
    if( !localStorage.getItem('firstLoad') )
    {
      localStorage['firstLoad'] = true;
      window.location.reload();
    }  
    else
      localStorage.removeItem('firstLoad');
  }
    // });
  },
  methods: {
    getid(id){
BannertService.deleteBanner(id).then(()=>{
  this.getbanners()
})
    },
    getbanners() {
      BannertService.getbanners(this.concert_id).then((res) => {
        this.list = res.data;
        //console.log(this.list);
      });
    },
    onchangestatus(event,id) {
      // console.log(event);
      // console.log(id);
      var b={}
      BannertService.updatebanner(this.concert_id,b).then(()=>{
              //console.log(res.data);
              var bb ={}
              BannertService.updatebannerstatus(id,bb).then(()=>{
              //console.log(res.data);
            this.getbanners()
            })
            })
    },
    onchange() {
      document.getElementById("my_file").click();
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      this.selectedFile = evt.target.files[0];
      
      this.filename = this.selectedFile.name;
      if (!files.length) return;
      this.onUploadFile();
      // }
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      // var http = "http://localhost:8080/uploadbanner?name="+this.filename;
      var http = LinkService.getLink()+ "/uploadbanner?name="+this.filename;
      axios
        .post(http, formData)
        .then(() => {         
          var data= {}
            BannertService.updatebanner(this.concert_id,data).then(()=>{
              var ban = {
            banner: LinkService.getLink() +"/uploads/banners/" + this.filename,
            status: true,
            concert_id:this.concert_id
          };
          BannertService.createbanner(ban).then(() => {
            this.getbanners()
            })
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 60px;
  background: white;
}
.flex-container {
  display: flex;
}
.flex {
  display: inline-flex;
}
.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.badge {
  cursor: pointer;
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: black; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.selectbadge {
  cursor: pointer;
  margin: 8px;
  padding: 6px;
  width: 40px;
  height: 40px;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.activebadge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 8px;
  transition: color 0.15s;
  width: 40px;
  height: 40px !important;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.theatre {
  display: flex;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cinema-seats {
  display: flex;
}

.cinema-seats .seat {
  cursor: pointer;
}

.select {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #da1b21;
  border-radius: 7px;
}

/* .active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #545e57;
  border-radius: 7px;
} */
/* // Left Seats */
.left {
  margin-right: 100px;
}

.left .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.left .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}
/* 
// Right Seats */

.right .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}

.right .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.right .screen {
  width: 1000px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  text-align: center;
  border: 10px solid;
  top: 300px;
  right: -60px;
}
/* //button */
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  font-size: 15px;
  position: relative;
  top: 400px;
  right: 800px;
}
#my_file {
  display: none;
}
</style>
