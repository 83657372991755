<template>
  <div>
    <img v-if="title.banner" :src="title.banner" style="width:100%;">
    <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout mb-5">
      <div class="row" v-if="seats.length > 0" style="padding:0px;margin: auto;
            border: 8px solid black;width:1132px">
          <div
          class="col-sm-8"
          style="
            text-align: center;
            background-color: #fefefe;
            width:780px
          "
        >
          <div class="row" style="height: 50px;">
            <div class="col-sm-12" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r1 in row1"
                    :key="r1.id"
                  >
                    <td v-if="r1.status" :class="r1.class" :style="r1.color" :id="r1.seat">{{ r1.seat }}</td>
                    <td v-else :class="r1.class" style="background-color: red" :id="r1.seat">{{ r1.seat }}</td>
                  </tr>
                  <tr style="text-align: center;" class="flex">
                    <td class="stage" style="margin-top: 0px;">STAGE</td>
                  </tr>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r1p2 in row1part2"
                    :key="r1p2.id"
                  >
                    <td v-if="r1p2.status" :class="r1p2.class" :style="r1p2.color" :id="r1p2.seat">
                      {{ r1p2.seat }}
                    </td>
                     <td v-else :class="r1p2.class" style="background-color: red" :id="r1p2.seat">
                      {{ r1p2.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" style="height: 50px">
            <div class="col-sm-12" style="text-align: left;">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r2 in row2"
                    :key="r2.id"
                  >
                    <td v-if="r2.status" :class="r2.class" :style="r2.color" :id="r2.seat">{{ r2.seat }}</td>
                    <td v-else :class="r2.class" style="background-color: red" :id="r2.seat">{{ r2.seat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" style="height: 50px">
            <div class="col-sm-12" style="text-align: left;">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r3 in row3"
                    :key="r3.id"
                  >
                    <td v-if="r3.status" :class="r3.class" :style="r3.color" :id="r3.seat">{{ r3.seat }}</td>
                    <td v-else :class="r3.class" style="background-color: red" :id="r3.seat">{{ r3.seat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" style="height: 50px">
            <div class="col-sm-12" style="text-align: left;">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r4 in row4"
                    :key="r4.id"
                  >
                    <td v-if="r4.status" :class="r4.class" :style="r4.color" :id="r4.seat" >{{ r4.seat }}</td>
                    <td v-else :class="r4.class" style="background-color: red" :id="r4.seat" >{{ r4.seat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" style="height: 50px">
            <div class="col-sm-12" style="text-align: left;">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r5 in row5"
                    :key="r5.id"
                  >
                    <td v-if="r5.status" :class="r5.class" :style="r5.color" :id="r5.seat" >{{ r5.seat }}</td>
                     <td v-else :class="r5.class" style="background-color: red" :id="r5.seat">{{ r5.seat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" style="height: 60px; margin-left: 45px">
            <div class="col-sm-11" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r6p1 in row6part1"
                    :key="r6p1.id"
                  >
                    <td v-if="r6p1.status" :class="r6p1.class" :style="r6p1.color" :id="r6p1.seat" >
                      {{ r6p1.seat }}
                    </td>
                    <td  v-else :class="r6p1.class" style="background-color: red" :id="r6p1.seat">
                      {{ r6p1.seat }}
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="bar">เสา</td>
                  </tr>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r6p2 in row6part2"
                    :key="r6p2.id"
                  >
                    <td v-if="r6p2.status" :class="r6p2.class" :style="r6p2.color" :id="r6p2.seat" >
                      {{ r6p2.seat }}
                    </td>
                     <td v-else :class="r6p2.class" style="background-color: red" :id="r6p2.seat">
                      {{ r6p2.seat }}
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="bar">เสา</td>
                  </tr>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r6p3 in row6part3"
                    :key="r6p3.id"
                  >
                    <td v-if="r6p3.status" :class="r6p3.class" :style="r6p3.color" :id="r6p3.seat">
                      {{ r6p3.seat }}
                    </td>
                      <td v-else :class="r6p3.class" style="background-color: red" :id="r6p3.seat">
                      {{ r6p3.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-1">
              <div style="width: 28px; margin-left: 23px; margin-top: 30px">
                <div class="a"></div>
                <div class="b">
                  <span class="stair" style="margin-left: 3px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                </div>
                <div class="c"></div>
              </div>
            </div>
          </div>
          <div class="row" style="height: 60px;">
            <div class="col-sm-10" style="text-align: left; margin-left: 25px">
              <table class="table table-borderless">
                <tbody>
                  <tr style="text-align: center" class="flex">
                    <td class="badge" style="background-color: white">6</td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="badge" style="background-color: white">12</td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="bar" style="background-color: white">เสา</td>
                  </tr>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r7 in row7"
                    :key="r7.id"
                  >
                    <td v-if="r7.status" :class="r7.class" :style="r7.color" :id="r7.seat" >{{ r7.seat }}</td>
                    <td v-else :class="r7.class" style="background-color: red" :id="r7.seat">{{ r7.seat }}</td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="bar" style="background-color: white">เสา</td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="badge" style="background-color: white">66</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-sm-1 float-right">
              <tr
                style="text-align: center; margin-left: 40px"
                class="flex"
                v-for="r6p4 in row6part4"
                :key="r6p4.id"
              >
                <td v-if="r6p4.status" :class="r6p4.class" :style="r6p4.color" :id="r6p4.seat">{{ r6p4.seat }}</td>
                <td v-else :class="r6p4.class" style="background-color: red" :id="r6p4.seat">{{ r6p4.seat }}</td>
              </tr>
              <div style="width: 28px; margin-left: 65px">
                <div class="a"></div>
                <div class="b">
                  <span class="stair" style="margin-left: 3px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                </div>
                <div class="c"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-11" style="padding: 0px">
              <div
                style="
                  background-color: black;
                  color: #f7fa11;
                  font-size: 20px;
                  font-weight: bold;
                "
              >
                ทางเดิน
              </div>
            </div>
            <div class="col-sm-1" style="border-left: 8px solid black"></div>
          </div>
          <div class="row">
            <div class="col-sm-11">
              <div class="row">
                <div
                  class="col-sm-12"
                  style="
                    width: 100%;
                    text-align: left;
                    height: 60px;
                  "
                >
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        style="text-align: center"
                        class="flex"
                        v-for="r8 in row8"
                        :key="r8.id"
                      >
                        <td v-if="r8.status" :class="r8.class" :style="r8.color" :id="r8.seat">
                          {{ r8.seat }}
                        </td>
                        <td v-else :class="r8.class" style="background-color: red" :id="r8.seat">
                          {{ r8.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="col-sm-3"
                  style="
                    text-align: left;
                    padding-right: 0px;
                  "
                >
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        style="text-align: center"
                        class="flex"
                        v-for="r9p1 in row9part1"
                        :key="r9p1.id"
                      >
                        <td v-if="r9p1.status" :class="r9p1.class" :style="r9p1.color" :id="r9p1.seat">
                          {{ r9p1.seat }}
                        </td>
                         <td v-else :class="r9p1.class" style="background-color: red" :id="r9p1.seat">
                          {{ r9p1.seat }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr
                        style="text-align: center"
                        class="flex"
                        v-for="r10p1 in row10part1"
                        :key="r10p1.id"
                      >
                        <td v-if="r10p1.status" :class="r10p1.class" :style="r10p1.color" :id="r10p1.seat" >
                          {{ r10p1.seat }}
                        </td>
                        <td v-else :class="r10p1.class" style="background-color: red" :id="r10p1.seat">
                          {{ r10p1.seat }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr
                        style="text-align: center; margin-left: 20px"
                        class="flex"
                        v-for="r11p1 in row11part1"
                        :key="r11p1.id"
                      >
                        <td v-if="r11p1.status" :class="r11p1.class" :style="r11p1.color" :id="r11p1.seat" >
                          {{ r11p1.seat }}
                        </td>
                         <td v-else :class="r11p1.class" style="background-color: red" :id="r11p1.seat">
                          {{ r11p1.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="verticalbar">เสา</div>
                    </div>
                    <div
                      class="col-sm-6"
                      style="
                        text-align: right;
                        margin-top: 30px;
                        padding-right: 10px;
                      "
                    >
                      <div style="width: 20px; margin-left: 55px">
                        <div class="a"></div>
                        <div class="b">
                          <span class="stair" style="margin-left: 2px"></span>
                          <span class="stair" style="margin-left: 1.5px"></span>
                          <span class="stair" style="margin-left: 1.5px"></span>
                        </div>
                        <div class="c"></div>
                      </div>
                      <table
                        class="table table-borderless"
                        style="margin-top: 20px"
                      >
                        <tbody v-for="r14 in row14" :key="r14.id">
                          <tr
                            style="text-align: center; margin-left: 20px"
                            class="flex"
                          >
                            <td v-if="r14.status" :class="r14.class" :style="r14.color" :id="r14.seat">
                              {{ r14.seat }}
                            </td>
                             <td v-else :class="r14.class" style="background-color: red" :id="r14.seat">
                              {{ r14.seat }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  class="col-sm-7"
                  style="text-align: left; padding-left: 0px; width: 458px"
                >
                  <div style="border-style: solid; width: 458px">
                    <table class="table table-borderless">
                      <tbody>
                        <tr
                          style="text-align: center"
                          class="flex"
                          v-for="r9p2 in row9part2"
                          :key="r9p2.id"
                        >
                          <td v-if="r9p2.status" :class="r9p2.class" :style="r9p2.color" :id="r9p2.seat">
                            {{ r9p2.seat }}
                          </td>
                          <td v-else :class="r9p2.class" style="background-color: red" :id="r9p2.seat">
                            {{ r9p2.seat }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr
                          style="text-align: center"
                          class="flex"
                          v-for="r10p2 in row10part2"
                          :key="r10p2.id"
                        >
                          <td v-if="r10p2.status" :class="r10p2.class" :style="r10p2.color" :id="r10p2.seat">
                            {{ r10p2.seat }}
                          </td>
                           <td v-else :class="r10p2.class" style="background-color: red" :id="r10p2.seat">
                            {{ r10p2.seat }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr
                          style="text-align: center"
                          class="flex"
                          v-for="r11p2 in row11part2"
                          :key="r11p2.id"
                        >
                          <td v-if="r11p2.status" :class="r11p2.class" :style="r11p2.color" :id="r11p2.seat">
                            {{ r11p2.seat }}
                          </td>
                          <td v-else :class="r11p2.class" style="background-color: red" :id="r11p2.seat">
                            {{ r11p2.seat }}
                          </td>
                        </tr>
                      </tbody>
                      <div class="row">
                        <div
                          class="col-sm-3"
                          style="width: 145px; padding-right: 0px"
                        >
                          <tbody>
                            <tr
                              style="text-align: center"
                              class="flex"
                              v-for="r12 in row12"
                              :key="r12.id"
                            >
                              <td v-if="r12.status" :class="r12.class" :style="r12.color" :id="r12.seat">
                                {{ r12.seat }}
                              </td>
                              <td v-else :class="r12.class" style="background-color: red" :id="r12.seat">
                                {{ r12.seat }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr
                              style="text-align: center"
                              class="flex"
                              v-for="r13p1 in row13part1"
                              :key="r13p1.id"
                            >
                              <td
                              v-if="r13p1.status"
                                :class="r13p1.class"
                                :style="r13p1.color"
                                :id="r13p1.seat"
                                
                              >
                                {{ r13p1.seat }}
                              </td>
                               <td
                                v-else
                                :class="r13p1.class"
                                style="background-color: red"
                                :id="r13p1.seat"
                              >
                                {{ r13p1.seat }}
                              </td>
                            </tr>
                          </tbody>
                        </div>
                        <div class="col-sm-4" style="padding-left: 0px">
                          <img src="../assets/dj.png" height="140px" />
                        </div>
                        <div class="col-sm-3" style="width: 30%; padding: 0px;margin-left:28px">
                          <tbody>
                            <tr style="text-align: center" class="flex">
                              <td
                                class="badge"
                                style="
                                  background-color: white;
                                  margin-left: 35px;
                                "
                              >
                                90
                              </td>
                            </tr>
                            <tr style="text-align: center" class="flex">
                              <td class="badge" style="background-color: white">
                                96
                              </td>
                            </tr>
                              <tr
                              style="text-align: center;margin-left: 18px;"
                              class="flex"
                              v-for="rv in rowvip"
                              :key="rv.id"
                            >
                              <td
                              v-if="rv.status"
                                :class="rv.class"
                                :style="rv.color"
                                :id="rv.seat"
                                 
                              >
                                {{ rv.seat }}
                              </td>
                               <td
                               v-else
                                :class="rv.class"
                                style="background-color: red"
                                :id="rv.seat"
                              >
                                {{ rv.seat }}
                              </td>
                            </tr>
                            <tr
                              style="text-align: center"
                              class="flex"
                              v-for="r13p2 in row13part2"
                              :key="r13p2.id"
                            >
                              <td
                              v-if="r13p2.status"
                                :class="r13p2.class"
                                :style="r13p2.color"
                                :id="r13p2.seat"
                                 
                              >
                                {{ r13p2.seat }}
                              </td>
                               <td
                               v-else
                                :class="r13p2.class"
                                style="background-color: red"
                                :id="r13p2.seat"
                              >
                                {{ r13p2.seat }}
                              </td>
                            </tr>
                            <tr style="text-align: center" class="flex">
                              <td
                                class="badge"
                                style="
                                  background-color: white;
                                  margin-left: 30px;
                                "
                              >
                                VIP
                              </td>
                            </tr>
                            <tr
                              style="text-align: center"
                              class="flex"
                              v-for="r13p3 in row13part3"
                              :key="r13p3.id"
                            >
                              <td v-if="r13p3.status" :class="r13p3.class" :style="r13p3.color" :id="r13p3.seat">
                                {{ r13p3.seat }}
                              </td>
                              <td v-else :class="r13p3.class" style="background-color: red" :id="r13p3.seat">
                                {{ r13p3.seat }}
                              </td>
                            </tr>
                          </tbody>
                        </div>
                      </div>
                    </table>
                  </div>
                  <div
                    class="mt-3"
                    style="
                      text-align: center;
                      background-color: black;
                      color: white;
                      font-size: 20px;
                      font-weight: bold;
                      width: 458px;
                    "
                  >
                    เสา
                  </div>
                </div>
                <div class="col-sm-1" style="width: 50px;padding:0px">
                  <table
                    class="table table-borderless"
                    style="margin-top: 30px"
                  >
                    <tbody>
                      <tr
                        style="text-align: center"
                        class="flex"
                        v-for="r9p3 in row9part3"
                        :key="r9p3.id"
                      >
                        <td
                        v-if="r9p3.status"
                          :class="r9p3.class"
                          :id="r9p3.seat"
                          :style="r9p3.color + ';margin-top:20px'"
                          
                        >
                          {{ r9p3.seat }}
                        </td>
                         <td
                         v-else
                          :class="r9p3.class"
                          :id="r9p3.seat"
                          style="background-color: red;margin-top:20px'"
                        >
                          {{ r9p3.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="width: 20px; margin-left: 0px">
                    <div class="a"></div>
                    <div class="b">
                      <span class="stair" style="margin-left: 3px"></span>
                      <span class="stair" style="margin-left: 1.5px"></span>
                      <span class="stair" style="margin-left: 1.5px"></span>
                    </div>
                    <div class="c"></div>
                  </div>
                  <table
                    class="table table-borderless"
                    style="margin-top: 30px"
                  >
                    <tbody>
                      <tr
                        style="text-align: center"
                        class="flex"
                        v-for="r9p5 in row9part5"
                        :key="r9p5.id"
                      >
                        <td
                        v-if="r9p5.status"
                          :class="r9p5.class"
                          :id="r9p5.seat"
                          :style="r9p5.color + ';margin-top:20px'"
                          
                        >
                          {{ r9p5.seat }}
                        </td>
                         <td
                          v-else
                          :class="r9p5.class"
                          :id="r9p5.seat"
                          style="background-color: red;margin-top:20px'"
                        >
                          {{ r9p5.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="col-sm-1"
              style="
                border-left: 28px solid black;
                padding: 0px;
                margin-left: -20px;
              "
            >            
            <div class="row">
              <div class="col-sm-2" style="padding: 0px;"><div class="verticaltext">ทางเดิน</div></div>
              <div class="col-sm-6" style="padding: 0px;margin:0px"> <table class="table table-borderless" style="margin-top: 60px">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="r9p4 in row9part4"
                    :key="r9p4.id"
                  >
                    <td v-if="r9p4.status" :class="r9p4.class" :style="r9p4.color" :id="r9p4.seat" >
                      {{ r9p4.seat }}
                    </td>
                    <td  v-else :class="r9p4.class" style="background-color: red" :id="r9p4.seat">
                      {{ r9p4.seat }}
                    </td>
                  </tr>
                </tbody>
              </table></div>
            </div>
             
            </div>
          </div>
        </div>
        <div
          class="col-sm-2"
          style="
            text-align: center;
            background-color: white;
            width: 150px;
            border-left: 8px solid black;
            border-right: 8px solid black;
          "
        >
          <div class="row">
            <div class="col-sm-6">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="c2c1 in col2col1"
                    :key="c2c1.id"
                  >
                    <td v-if="c2c1.status" :class="c2c1.class" :style="c2c1.color" :id="c2c1.seat">
                      {{ c2c1.seat }}
                    </td>
                    <td v-else :class="c2c1.class" style="background-color: red" :id="c2c1.seat">
                      {{ c2c1.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-6" style="padding-left: 0px">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="c2c2 in col2col2"
                    :key="c2c2.id"
                  >
                    <td v-if="c2c2.status" :class="c2c2.class" :style="c2c2.color" :id="c2c2.seat">
                      {{ c2c2.seat }}
                    </td>
                    <td v-else :class="c2c2.class" style="background-color: red" :id="c2c2.seat">
                      {{ c2c2.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-6" style="margin-top: 30px; padding: 0px">
              <div
                style="
                  background-color: black;
                  color: white;
                  font-size: 20px;
                  font-weight: bold;
                  height: 50px;
                  padding: 6px;
                  width: 50px;
                "
              >
                เสา
              </div>
              <table class="table table-borderless" style="margin-top: 60px">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="c2c1p1 in col2col1p1"
                    :key="c2c1p1.id"
                  >
                    <td v-if="c2c1p1.status" :class="c2c1p1.class" :style="c2c1p1.color" :id="c2c1p1.seat">
                      {{ c2c1p1.seat }}
                    </td>
                      <td v-else :class="c2c1p1.class" style="background-color: red" :id="c2c1p1.seat">
                      {{ c2c1p1.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-6">
              <div style="width: 28px; margin-left: 30px">
                <div class="a"></div>
                <div class="b">
                  <span class="stair" style="margin-left: 3px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                </div>
                <div class="c"></div>
              </div>
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="c2c1p2 in col2col1p2"
                    :key="c2c1p2.id"
                  >
                    <td v-if="c2c1p2.status" :class="c2c1p2.class" :style="c2c1p2.color" :id="c2c1p2.seat">
                      {{ c2c1p2.seat }}
                    </td>
                     <td v-else :class="c2c1p2.class" style="background-color: red" :id="c2c1p2.seat">
                      {{ c2c1p2.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="col-sm-2"
          style="
            text-align: center;
          "
        >
          <div class="row">
            <div class="col-sm-12">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    style="text-align: center"
                    class="flex"
                    v-for="c3r1 in col3row1"
                    :key="c3r1.id"
                  >
                    <td v-if="c3r1.status" :class="c3r1.class" :style="c3r1.color" :id="c3r1.seat">
                      {{ c3r1.seat }}
                    </td>
                    <td v-else :class="c3r1.class" style="background-color: red" :id="c3r1.seat">
                      {{ c3r1.seat }}
                    </td>
                  </tr>
                  <tr
                    style="text-align: center; margin-top: 15px"
                    class="flex"
                    v-for="c3r2 in col3row2"
                    :key="c3r2.id"
                  >
                    <td v-if="c3r2.status" :class="c3r2.class" :style="c3r2.color" :id="c3r2.seat" >
                      {{ c3r2.seat }}
                    </td>
                    <td v-else :class="c3r2.class" style="background-color: red" :id="c3r2.seat">
                      {{ c3r2.seat }}
                    </td>
                  </tr>
                  <tr
                    style="text-align: center; margin-top: 15px"
                    class="flex"
                    v-for="c3r3 in col3row3"
                    :key="c3r3.id"
                  >
                    <td v-if="c3r3.status" :class="c3r3.class" :style="c3r3.color" :id="c3r3.seat">
                      {{ c3r3.seat }}
                    </td>
                    <td v-else :class="c3r3.class" style="background-color: red" :id="c3r3.seat">
                      {{ c3r3.seat }}
                    </td>
                  </tr>
                   <tr style="text-align: center" class="flex">
                              <td
                                class="badge"
                                style="
                                  background-color: white;
                                "
                              >
                                90
                              </td>
                            </tr>  
                  <tr
                    style="text-align: center; margin-top: 15px"
                    class="flex"
                    v-for="c3r4 in col3row4"
                    :key="c3r4.id"
                  >
                    <td v-if="c3r4.status" :class="c3r4.class" :style="c3r4.color" :id="c3r4.seat">
                      {{ c3r4.seat }}
                    </td>
                    <td v-else :class="c3r4.class" style="background-color: red" :id="c3r4.seat">
                      {{ c3r4.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="col-sm-12"
              style="
                background-color: black;
                color: white;
                margin-top: 50px;
                height: 524px;
                padding:0px;
              "
            >           
              <div class="verticaloffice">office</div>
              <div style="margin-top:100px;margin-left:-15px">
               <a href="#" class="fas fa fa-facebook"></a><span style="font-size:14px">hollywood pub phayao</span>
               <br/>
            <a href="#" class="fas fa fa-instagram"></a><span style="font-size:13px">hollywood_pub_phayao</span>
            </div>
            <img style="margin: auto;" src="../assets/line.png"/>
            </div>
          </div>
        </div>
        </div>
      <div class="row mt-5">
        <div class="col-md-3" v-for="(z, i) in zones" :key="i">
          <div class="card mt-3">
            <div class="card-body">
              <div style="text-align: center">
                <p class="dot" :style="z.color"></p>
                <div>{{ z.name }}</div>
                <div>ราคา {{ z.price || "0" }} บาท</div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-md-3">
          <div class="card mt-3">
            <div class="card-body">
              <div style="text-align: center">
                <p class="dot" style="background:red"></p>
                <div>จองแล้ว</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZoneService from "../services/ZoneService.js";
import SeatService from "../services/SeatService";
import ConcertService from "../services/ConcertService";
import axios from "axios";
import BannertService from "../services/BannertService";
import LinkService from "../services/LinkService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      list: [],
      color: "#0000FF",
      row1: [],
      row1part2: [],
      row2: [],
      row3: [],
      row4: [],
      row5: [],
      row6part1: [],
      row6part2: [],
      row6part3: [],
      row6part4: [],
      row7: [],
      row8: [],
      row9part1: [],
      row9part2: [],
      row9part3: [],
      row9part4: [],
      row9part5: [],
      row10part1: [],
      row10part2: [],
      row11part1: [],
      row11part2: [],
      row12: [],
      row13part1: [],
      row13part2: [],
      row13part3: [],
      row14: [],
      col2col1: [],
      col2col2: [],
      col2col1p1: [],
      col2col1p2: [],
      col3row1: [],
      col3row2: [],
      col3row3: [],
      col3row4: [],
      rowvip:[],
      concert_id: 0,
      zones: [],
      data: [],
      seats: [],
      selectedFile: "",
      banner: "",
      filename: "",
      docs: {},
      title: {},
      zone: "",
    };
  },
  mounted() {
    // ConcertService.getconcerts().then((res)=>{
      // console.log(res.data);
      this.concert_id = this.$route.params.id
    this.getbanners();
    this.getzones();
    this.getSeats();
    // })

  },
  methods: {
    getbanners() {
      BannertService.getbanner(this.concert_id).then((res) => {
        // console.log(res.data);
        this.title.banner = res.data.banner;
      });
    },
    create() {
      var concert = {};
      ConcertService.createconcert(concert).then((res) => {
        this.concert_id = res.data.id;
        var no = 0;
        for (let c = 0; c < 50; c++) {
          no = c + 1;
          var color = "background-color:black";
          var data = {
            concert_id: this.concert_id,
            seat: no,
            class: "badge",
            color: color,
            status: true,
          };
          SeatService.createseat(data).then(() => {
            // console.log(res.data);
          });
        }
        this.$router.push("/zone/" + this.concert_id);
        setTimeout(function () {
          location.reload();
        }, 500);
        window.scrollTo(0, 0);
      });
    },
    createzone() {
      for (let z = 0; z < this.zone; z++) {
        var no = z + 1;
        var data = {
          concert_id: this.concert_id,
          name: "โซน " + no,
          color: "background-color:black",
        };
        ZoneService.createzone(data).then(() => {
          // console.log(res);
        });
      }
      this.getzones();
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.filename = selectedFile.name;
      // console.log(selectedFile);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      var http = LinkService.getLink()+"/upload";
      axios
        .post(http, formData)
        .then((res) => {
          // console.log(res.data.name);
          this.title.name = res.data.name;
          this.title.path = "uploads/" + res.data.name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSeats() {
      SeatService.getseatByID(this.concert_id).then((res) => {
        this.seats = res.data;
        // console.log(this.seats);
        this.generaterow();
      });
    },
    getConcert() {
      ConcertService.getconcert(this.concert_id).then((res) => {
        this.title = res.data;
        // console.log(this.title);
      });
    },
    getzones() {
      ZoneService.getzonebyconcertid(this.concert_id).then((res) => {
        this.zones = res.data;
      });
    },
   generaterow() {
      this.row1= [];
      this.row1part2= [];
      this.row2= [];
      this.row3= [];
      this.row4= [];
      this.row5= [];
      this.row6part1= [];
      this.row6part2= [];
      this.row6part3= [];
      this.row6part4= [];
      this.row7= [];
      this.row8= [];
      this.row9part1= [];
      this.row9part2= [];
      this.row9part3= [];
      this.row9part4= [];
      this.row9part5= [];
      this.row10part1= [];
      this.row10part2= [];
      this.row11part1= [];
      this.row11part2= [];
      this.row12= [];
      this.row13part1= [];
      this.row13part2= [];
      this.row13part3= [];
      this.row14= [];
      this.col2col1= [];
      this.col2col2= [];
      this.col2col1p1= [];
      this.col2col1p2= [];
      this.col3row1= [];
      this.col3row2= [];
      this.col3row3= [];
      this.col3row4= [];
      this.rowvip=[];
      for (let i = 0; i < this.seats.length; i++) {
        var classss = "";
        if (
          this.seats[i].seat == 24 ||
          this.seats[i].seat == 30 ||
          this.seats[i].seat == 36 ||
          this.seats[i].seat == 43 ||
          this.seats[i].seat == 50 ||
          this.seats[i].seat == 57 ||
          this.seats[i].seat == 62 ||
          this.seats[i].seat == 25 ||
          this.seats[i].seat == 31 ||
          this.seats[i].seat == 37 ||
          this.seats[i].seat == 44 ||
          this.seats[i].seat == 51 ||
          this.seats[i].seat == 58 ||
          this.seats[i].seat == 63 ||
          this.seats[i].seat == 26 ||
          this.seats[i].seat == 32 ||
          this.seats[i].seat == 38 ||
          this.seats[i].seat == 45 ||
          this.seats[i].seat == 52 ||
          this.seats[i].seat == 59 ||
          this.seats[i].seat == 64 ||
          this.seats[i].seat == 27 ||
          this.seats[i].seat == 33 ||
          this.seats[i].seat == 39 ||
          this.seats[i].seat == 46 ||
          this.seats[i].seat == 53 ||
          this.seats[i].seat == 60 ||
          this.seats[i].seat == 65 ||
          this.seats[i].seat == 85 ||
          this.seats[i].seat == 92 ||
          this.seats[i].seat == 98 ||
          this.seats[i].seat == 102 ||
          this.seats[i].seat == 106 ||
          this.seats[i].seat == 110 ||
          this.seats[i].seat == 114 ||
          this.seats[i].seat == 93 ||
          this.seats[i].seat == 99 ||
          this.seats[i].seat == 103 ||
          this.seats[i].seat == 107 ||
          this.seats[i].seat == 111 ||
          this.seats[i].seat == 94 ||
          this.seats[i].seat == 100 ||
          this.seats[i].seat == 104 ||
          this.seats[i].seat == 108 ||
          this.seats[i].seat == 112 ||
          this.seats[i].seat == 95 ||
          this.seats[i].seat == 101 ||
          this.seats[i].seat == 105 ||
          this.seats[i].seat == 109 ||
          this.seats[i].seat == 113
        ) {
          classss += this.seats[i].class + " leftbadge";
        } else {
          classss += this.seats[i].class;
        }
        if (this.seats[i].seat == 91 || this.seats[i].seat == 97) {
          this.seats[i].color += ';margin-left: 17px;'
        }
        if (this.seats[i].seat == 136 || this.seats[i].seat == 137) {
          this.seats[i].color += ';margin-top: 20px;'
        }
         if (this.seats[i].seat == 156) {
          this.seats[i].seat = "VIP"
        }
        var data = {
          id: this.seats[i].id,
          seat: this.seats[i].seat,
          class: classss,
          color: this.seats[i].color,
          price: this.seats[i].price,
          status: this.seats[i].status,
        };
        if (
          this.seats[i].seat == 1 ||
          this.seats[i].seat == 7 ||
          this.seats[i].seat == 13 ||
          this.seats[i].seat == 18 ||
          this.seats[i].seat == 23
        ) {
          this.row1.push(data);
        } else if (
          this.seats[i].seat == 42 ||
          this.seats[i].seat == 49 ||
          this.seats[i].seat == 56 ||
          this.seats[i].seat == 61 ||
          this.seats[i].seat == 67
        ) {
          this.row1part2.push(data);
        } else if (
          this.seats[i].seat == 2 ||
          this.seats[i].seat == 8 ||
          this.seats[i].seat == 14 ||
          this.seats[i].seat == 19 ||
          this.seats[i].seat == 24 ||
          this.seats[i].seat == 30 ||
          this.seats[i].seat == 36 ||
          this.seats[i].seat == 43 ||
          this.seats[i].seat == 50 ||
          this.seats[i].seat == 57 ||
          this.seats[i].seat == 62 ||
          this.seats[i].seat == 68
        ) {
          this.row2.push(data);
        } else if (
          this.seats[i].seat == 3 ||
          this.seats[i].seat == 9 ||
          this.seats[i].seat == 15 ||
          this.seats[i].seat == 20 ||
          this.seats[i].seat == 25 ||
          this.seats[i].seat == 31 ||
          this.seats[i].seat == 37 ||
          this.seats[i].seat == 44 ||
          this.seats[i].seat == 51 ||
          this.seats[i].seat == 58 ||
          this.seats[i].seat == 63 ||
          this.seats[i].seat == 69
        ) {
          this.row3.push(data);
        } else if (
          this.seats[i].seat == 4 ||
          this.seats[i].seat == 10 ||
          this.seats[i].seat == 16 ||
          this.seats[i].seat == 21 ||
          this.seats[i].seat == 26 ||
          this.seats[i].seat == 32 ||
          this.seats[i].seat == 38 ||
          this.seats[i].seat == 45 ||
          this.seats[i].seat == 52 ||
          this.seats[i].seat == 59 ||
          this.seats[i].seat == 64 ||
          this.seats[i].seat == 70
        ) {
          this.row4.push(data);
        } else if (
          this.seats[i].seat == 5 ||
          this.seats[i].seat == 11 ||
          this.seats[i].seat == 17 ||
          this.seats[i].seat == 22 ||
          this.seats[i].seat == 27 ||
          this.seats[i].seat == 33 ||
          this.seats[i].seat == 39 ||
          this.seats[i].seat == 46 ||
          this.seats[i].seat == 53 ||
          this.seats[i].seat == 60 ||
          this.seats[i].seat == 65 ||
          this.seats[i].seat == 71
        ) {
          this.row5.push(data);
        } else if (this.seats[i].seat == 6 || this.seats[i].seat == 12) {
          this.row6part1.push(data);
        } else if (
          this.seats[i].seat == 28 ||
          this.seats[i].seat == 34 ||
          this.seats[i].seat == 40 ||
          this.seats[i].seat == 47 ||
          this.seats[i].seat == 54
        ) {
          this.row6part2.push(data);
        } else if (this.seats[i].seat == 66 || this.seats[i].seat == 72) {
          this.row6part3.push(data);
        } else if (this.seats[i].seat == 73) {
          this.row6part4.push(data);
        } else if (
          this.seats[i].seat == 29 ||
          this.seats[i].seat == 35 ||
          this.seats[i].seat == 41 ||
          this.seats[i].seat == 48 ||
          this.seats[i].seat == 55
        ) {
          this.row7.push(data);
        } else if (
          this.seats[i].seat == 74 ||
          this.seats[i].seat == 78 ||
          this.seats[i].seat == 82 ||
          this.seats[i].seat == 85 ||
          this.seats[i].seat == 92 ||
          this.seats[i].seat == 98 ||
          this.seats[i].seat == 102 ||
          this.seats[i].seat == 106 ||
          this.seats[i].seat == 110 ||
          this.seats[i].seat == 114
        ) {
          this.row8.push(data);
        } else if (
          this.seats[i].seat == 75 ||
          this.seats[i].seat == 79 ||
          this.seats[i].seat == 83
        ) {
          this.row9part1.push(data);
        } else if (
          this.seats[i].seat == 86 ||
          this.seats[i].seat == 93 ||
          this.seats[i].seat == 99 ||
          this.seats[i].seat == 103 ||
          this.seats[i].seat == 107 ||
          this.seats[i].seat == 111
        ) {
          this.row9part2.push(data);
        } else if (this.seats[i].seat == 117 || this.seats[i].seat == 118) {
          this.row9part3.push(data);
        } else if (this.seats[i].seat == 119) {
          this.row9part5.push(data);
        } else if (
          this.seats[i].seat == 120 ||
          this.seats[i].seat == 121 ||
          (this.seats[i].seat == 122) | (this.seats[i].seat == 123)
        ) {
          this.row9part4.push(data);
        } else if (
          this.seats[i].seat == 76 ||
          this.seats[i].seat == 80 ||
          this.seats[i].seat == 84
        ) {
          this.row10part1.push(data);
        } else if (
          this.seats[i].seat == 87 ||
          this.seats[i].seat == 94 ||
          this.seats[i].seat == 100 ||
          this.seats[i].seat == 104 ||
          this.seats[i].seat == 108 ||
          this.seats[i].seat == 112
        ) {
          this.row10part2.push(data);
        } else if (this.seats[i].seat == 77 || this.seats[i].seat == 81) {
          this.row11part1.push(data);
        } else if (
          this.seats[i].seat == 88 ||
          this.seats[i].seat == 95 ||
          this.seats[i].seat == 101 ||
          this.seats[i].seat == 105 ||
          this.seats[i].seat == 109 ||
          this.seats[i].seat == 113
        ) {
          this.row11part2.push(data);
        } else if (this.seats[i].seat == 89) {
          this.row12.push(data);
        } else if (
          this.seats[i].seat == 90 ||
          this.seats[i].seat == 96 ||
          this.seats[i].seat == 91 ||
          this.seats[i].seat == 97
        ) {
          this.row13part1.push(data);
        } else if (this.seats[i].seat == 115) {
          this.row13part2.push(data);
        } else if (this.seats[i].seat == 'VIP') {
          this.rowvip.push(data);
        } else if (this.seats[i].seat == 116) {
          this.row13part3.push(data);
        } else if (this.seats[i].seat == 154 || this.seats[i].seat == 155) {
          this.row14.push(data);
        } else if (
          this.seats[i].seat == 124 ||
          this.seats[i].seat == 125 ||
          this.seats[i].seat == 126 ||
          this.seats[i].seat == 127 ||
          this.seats[i].seat == 128
        ) {
          this.col2col1.push(data);
        } else if (
          this.seats[i].seat == 135 ||
          this.seats[i].seat == 136 ||
          this.seats[i].seat == 137
        ) {
          this.col2col2.push(data);
        } else if (
          this.seats[i].seat == 129 ||
          this.seats[i].seat == 130 ||
          this.seats[i].seat == 131 ||
          this.seats[i].seat == 132 ||
          this.seats[i].seat == 133 ||
          this.seats[i].seat == 134
        ) {
          this.col2col1p1.push(data);
        } else if (
          this.seats[i].seat == 138 ||
          this.seats[i].seat == 139 ||
          this.seats[i].seat == 140 ||
          this.seats[i].seat == 141 ||
          this.seats[i].seat == 142
        ) {
          this.col2col1p2.push(data);
        } else if (
          this.seats[i].seat == 143 ||
          this.seats[i].seat == 146 ||
          this.seats[i].seat == 150
        ) {
          this.col3row1.push(data);
        } else if (
          this.seats[i].seat == 144 ||
          this.seats[i].seat == 147 ||
          this.seats[i].seat == 151
        ) {
          this.col3row2.push(data);
        } else if (
          this.seats[i].seat == 145 ||
          this.seats[i].seat == 148 ||
          this.seats[i].seat == 152
        ) {
          this.col3row3.push(data);
        } else if (this.seats[i].seat == 149 || this.seats[i].seat == 153) {
          this.col3row4.push(data);
        }
      }
      // console.log(this.row11);
    },
    generaterow1() {
      for (let i = 0; i < 12; i++) {
        var no = i + 1;
        var color = "background-color:black";
        // if (i == 5 || i == 6) {
        //   this.row1.push({
        //   id:'',
        //   class:'notbadge',
        // })
        // }
        // else{
        // if (i > 6) {
        //   no = no-2
        // }
        this.row1.push({
          id: no,
          class: "badge",
          color: color,
        });
        // }
      }
    },
    generaterow11() {
      for (let i = 12; i < 24; i++) {
        var no = i + 1;
        var color = "background-color:black";
        this.row11.push({
          id: no,
          class: "badge",
          color: color,
        });
      }
    },
    generaterow111() {
      for (let i = 26; i < 38; i++) {
        var no = i + 1;
        var color = "background-color:black";
        this.row111.push({
          id: no,
          class: "badge",
          color: color,
        });
      }
    },
    selectcolor(event) {
      this.color = event.target.value;
      var color = "background-color:" + this.color;
      for (let l = 0; l < this.list.length; l++) {
        for (let r = 0; r < this.seats.length; r++) {
          if (this.seats[r].seat == this.list[l].seat) {
            this.seats[r].color = color;
          }
        }
      }
    },
    selectzone(event) {
      // console.log(event.target.value);
      ZoneService.getzone(event.target.value).then((res) => {
        this.data.price = res.data.price;
        var c = res.data.color.split(":");
        this.color = c[1];
        SeatService.getseatbyzoneid(event.target.value).then((res) => {
          this.list = res.data;
          // console.log(res.data);
        });
      });
    },
    save() {
      if (this.data.zone == null) {
        alert("กรุณาเลือกโซน");
      } else if (this.data.price == null) {
        alert("กรุณากรอกราคา");
      } else if (this.list.length == 0) {
        alert("กรุณาเลือกที่นั่ง");
      } else {
        var color = "background-color:" + this.color;
        for (let l = 0; l < this.list.length; l++) {
          for (let r = 0; r < this.seats.length; r++) {
            if (this.seats[r].seat == this.list[l].seat) {
              var data = {
                zone: this.data.zone,
                price: this.data.price,
                class: "selectbadge",
                color: color,
                status: true,
              };
              // console.log(data);
              SeatService.updateseat(this.seats[r].id, data).then(() => {
                // console.log(res.data);
              });
            }
          }
        }
        var zone = {
          color: color,
          price: this.data.price,
        };
        ZoneService.updatezone(this.data.zone, zone).then(() => {
          // console.log(res);
        });
        this.getzones();
        setTimeout(function () {
          location.reload();
        }, 500);
        window.scrollTo(0, 0);
      }
    },
    selectseat(event) {
      // console.log(s);
      var id = event.srcElement.id;
      var classs = event.srcElement.className;
      var element = document.getElementById(id);
      // console.log(classs);
      // console.log(element);
      if (classs == "selectbadge") {
        element.classList.remove("selectbadge");
        element.classList.add("badge");
        document.getElementById(id).style.background = "black";
        for (let l = 0; l < this.list.length; l++) {
          if (this.list[l].seat == id) {
            this.list.splice(l, 1);
          }
        }
      } else if (classs == "badge") {
        document.getElementById(id).style.background = this.color;
        element.classList.remove("badge");
        element.classList.add("selectbadge");
        this.list.push({
          seat: id,
        });
      }
      // console.log(this.list);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 60px;
  background: white;
}
.flex-container {
  display: flex;
}
.flex {
  display: inline-flex;
}
.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.leftbadge {
  margin-left: 20px !important;
}
.stage {
  margin: 8px;
  padding: 2px;
  width: 180px;
  height: 40px;
  background: black; /*important*/
  background-color: black;
  justify-content: center;
  align-items: center;
  color: #f2972f; /*no need*/
  font-size: 25px;
  font-weight: bold;
}
.bar {
  margin: 8px;
  padding: 15px;
  width: 60px;
  height: 60px;
  background: black; /*important*/
  background-color: #5e9cd9;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.verticalbar {
  font-weight: bold;
  letter-spacing: 0.7px;
      width: 100px;
      height: 50px;
font-size: 20px;
  /* transform-origin: center left; */
  top:100%;
  transform: rotate(-90deg) translateX(-50%);
  padding:0px;
  margin-left:-40px;
  margin-top:90px;
  background-color: black;
  color:white;
  text-align: center;
}
.verticaltext {
  letter-spacing: 0.7px;
      width: 60px;
font-size: 17px;
  /* transform-origin: center left; */
  top:100%;
  transform: rotate(-90deg) translateX(-50%);
  padding:0px;
  margin-left:-32px;
  margin-top:150px;
  background-color: black;
  color:yellow;
  text-align: center
}
.verticaloffice {
  letter-spacing: 0.7px;
font-size: 40px;
  /* transform-origin: center left; */
  top:100%;
  transform: rotate(-90deg) translateX(-50%);
  padding:0px;
  margin-top:50px;
  color:white;
  text-align: center
}
.fas {
  padding: 5px;
  font-size: 25px;
  width: 35px;
  height: 35px;
  text-align: center;
  text-decoration: none;
  margin: 10px;
  border-radius: 50%;
}

.fa-facebook {
  background: #3E58A3;
  color: white;
}
.fa-instagram {
  background: #F82153;
  color: white;
}
.badge {
  cursor: pointer;
  margin: 7px;
  padding: 8px;
  padding-top: 12px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 16px;
  font-weight: 100;
}
.selectbadge {
  cursor: pointer;
  margin: 7px;
  padding: 8px;
  width: 40px;
  height: 40px;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 16px;
  font-weight: 100;
}

div.a {
  width: 100%;
  height: 3px;
  background-color: black;
  -ms-transform: skewX(30deg); /* IE 9 */
  transform: skewX(30deg);
}
div.b {
  width: 100%;
  height: 20px;
  background-color: white;
}
div.c {
  width: 100%;
  height: 3px;
  background-color: black;
  -ms-transform: skewX(30deg); /* IE 9 */
  transform: skewX(30deg);
}
.stair {
  display: inline-block;
  width: 4px;
  background-color: black;
  height: 20px;
  background-color: black;
}
.activebadge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 8px;
  transition: color 0.15s;
  width: 40px;
  height: 40px !important;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.theatre {
  display: flex;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cinema-seats {
  display: flex;
}

.cinema-seats .seat {
  cursor: pointer;
}

.select {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #da1b21;
  border-radius: 7px;
}

/* .active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #545e57;
  border-radius: 7px;
} */
/* // Left Seats */
.left {
  margin-right: 100px;
}

.left .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.left .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}
/* 
// Right Seats */

.right .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}

.right .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.right .screen {
  width: 1000px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  text-align: center;
  border: 10px solid;
  top: 300px;
  right: -60px;
}
/* //button */
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  font-size: 15px;
  position: relative;
  top: 400px;
  right: 800px;
}
</style>
