<template>
  <div>
    <div class="vertical-menu">
      <router-link :to="m.url" v-for="(m, i) in menu" :key="i" :class="m.class">
        <div @click="clickmenu(m.id)">
          {{ m.name }}
        </div></router-link
      >

      <!-- <a v-for="(m, i) in menu"
        :key="i"
        :class="m.class"
        @click="clickmenu(m.id)"><router-link :to="m.url" 
        >{{m.name}}</router-link></a> -->

      <a v-if="!currentUser" href="login">เข้าสู่ระบบ</a>
      <a v-else @click.prevent="logOut" href="#">ออกจากระบบ</a>
      <a></a>
      <a v-if="currentUser" href="/concertchart" target="_blank">ลูกค้า</a>
    </div>
  </div>
</template>

<script>
import ConcertService from "../services/ConcertService.js";
import UserDataService from "../services/UserDataService";
import SeatService from "../services/SeatService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      menu: [],
    };
  },
  mounted() {
    this.menu = [];
    // console.log(this.$route.name );
    this.concert_id = this.$route.params.id
    // console.log(this.concert_id);
    this.getmenu();
    // ConcertService.getconcerts().then((res) => {
    //   // console.log(res.data);
    //   if (res.data.length == 0) {
    //     this.concert_id = 0;
    //   } else {
    //     this.concert_id = res.data.id;
    //   }
    //   if (this.currentUser) {
    //     this.getmenu();
    //   }
    //   // console.log(this.currentUser);
    // });
  },
  methods: {
    create() {
      var concert = {};
      ConcertService.createconcert(concert).then((res) => {
        this.concert_id = res.data.id;
        var no = 0;
        for (let c = 0; c < 50; c++) {
          no = c + 1;
          var color = "background-color:black";
          var data = {
            concert_id: this.concert_id,
            seat: no,
            class: "badge",
            color: color,
            status: true,
          };
          SeatService.createseat(data).then(() => {
            // console.log(res.data);
          });
        }
      });
    },
    activeconfirm() {
      // console.log(this.$route.name);
      if (this.$route.name == "confirm") {
        for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = "";
          if (this.menu[m].id == 4) {
            this.menu[m].class = "active";
          }
        }
      } else {
        if (this.$route.name == "zone") {
          for (let m = 0; m < this.menu.length; m++) {
            this.menu[m].class = "";
            if (this.menu[m].id == 3) {
              this.menu[m].class = "active";
            }
          }
        }
      }
      // console.log(this.menu);
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      setTimeout(function () {
        location.reload();
      }, 500);
      this.$router.push("/login");
    },
    getmenu() {
      UserDataService.getMenus(this.currentUser.role_id).then((res) => {
        // console.log(res.data);
        // this.menu = res.data
        for (let m = 0; m < res.data.length; m++) {
          // console.log(m);
          var classs = "";
          if (this.currentUser.role_id == 1) {
            if (m == 1) {
              classs = "active";
            }
          } else {
            if (m == 0) {
              classs = "active";
            }
          }
          var link = res.data[m].url;
          if (res.data[m].url == "/confirm") {
            link += "/" + this.concert_id + "/0";
          }
          this.menu.push({
            id: res.data[m].id,
            name: res.data[m].name,
            url: link,
            class: classs,
          });
        }
        this.activeconfirm();
      });
      // this.menu.push(
      //   {
      //     id: 1,
      //     name: "จัดการแบนเนอร์",
      //     url: "/bannerlist",
      //     class: "active",
      //   },
      //   {
      //     id: 2,
      //     name: "จัดการโซน",
      //     url: "/zone/" + this.concert_id,
      //     class: "",
      //   },
      //   {
      //     id: 3,
      //     name: "ยืนยันการจอง",
      //     url: "/confirmlist",
      //     class: "",
      //   },
      //   {
      //     id: 4,
      //     name: "รายงาน",
      //     url: "/report",
      //     class: "",
      //   }
      // );
    },
    clickmenu(id) {
      // console.log(id);
      // console.log(this.menu);
      if (id==9) {
        window.open('/concertchart', '_blank');
      }
      if (id == 1) {
        this.create();
        for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = "";
          if (this.menu[m].id == 3) {
            this.menu[m].class = "active";
          }
        }
        setTimeout(function () {
          location.reload();
        }, 500);
        window.scrollTo(0, 0);
      } else {
        for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = "";
          if (this.menu[m].id == id) {
            this.menu[m].class = "active";
          }
        }
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
</style>
