class LinkService {
  getLink() {
    // return 'http://localhost:8000'
    // return 'https://api-booking.ponnipa.in.th'
    return 'https://api.hollywoodpubphayao.com'
  }
  getLinkQrcode(){
    // return 'http://localhost:8081/qrcode/'
    // return 'https://booking.ponnipa.in.th/qrcode/'
    return 'https://hollywoodpubphayao.com/qrcode/'
  }
  getLinkQrcodebook(){
    // return 'http://localhost:8081/qrcodebook/'
    // return 'https://booking.ponnipa.in.th/qrcodebook/'
    return 'https://hollywoodpubphayao.com/qrcodebook/'
  }
}

export default new LinkService();