import http from "../http-common";

class ZoneService {
  createzone(data) {
    return http.post('/zone', data);
  }
  getzones() {
    return http.get(`/zone`);
  }
  getzonebyconcertid(id){
    return http.get(`/zone/getzonebyconcertid/`+id);
  }
  getzonebyticketid(id){
    return http.get(`/zone/getzonebyticketid/`+id);
  }
  getzone(id) {
    return http.get(`/zone/`+id);
  }
  updatezone(id, data) {
    return http.put(`/zone/${id}`, data);
  }
  deletezone(id) {
    return http.delete(`/zone/${id}`);
  }
}

export default new ZoneService();