import http from "../http-common";

class PerSeatsService {
  createper_seat(data) {
    return http.post('/per_seats', data);
  }
  getper_seats() {
    return http.get(`/per_seats`);
  }
  getper_seat(id) {
    return http.get(`/per_seats/${id}`);
  }
  updateper_seat(id, data) {
    return http.put(`/per_seats/${id}`, data);
  }
  deleteper_seat(id) {
    return http.delete(`/per_seats/${id}`);
  }
}

export default new PerSeatsService();