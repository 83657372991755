import http from "../http-common";

class ConcertService {
  createconcert(data) {
    return http.post('/concerts', data);
  }
  getconcerts() {
    return http.get(`/concerts`);
  }
  getconcertsbydate(date) {
    return http.get('/concerts?date='+date);
  }
  getconcertlist() {
    return http.get(`/concerts/getconcertlist`);
  }
  getconcertpublic() {
    return http.get(`/concerts/getconcertpublic`);
  }
  getconcertsAll() {
    return http.get(`/concerts/getall`);
  }
  getconcert(id) {
    return http.get(`/concerts/`+id);
  }
  getconcertseat(id,seat) {
    return http.get(`/concerts/getbookseat?id=`+id+'&&seat='+seat);
  }
  deleteconcert(id) {
    return http.delete(`/concerts/`+id);
  }
  updateStatusconcert(id, data) {
    return http.put(`/concerts/updatestatus/${id}`, data);
  }
  updateTimeconcert(id, data) {
    return http.put(`/concerts/updatetime/${id}`, data);
  }
  updatebanner(id, data) {
    return http.put(`/concerts/updatebanner/${id}`, data);
  } 
  deleteconcertall(id) {
    return http.delete(`/concerts/deleteconcert/`+id);
  }
  updateconcert(id, data) {
    return http.put(`/concerts/updateconcert/${id}`, data);
  }
  updateconcertstatus(id, data) {
    return http.put(`/concerts/updateconcertstatus/${id}`, data);
  }
  updatestatusseat(id, data) {
    return http.put(`/concerts/updatestatusseat/${id}`, data);
  }
  updateclosestatus(id, data) {
    return http.put(`/concerts/updateclosestatus/${id}`, data);
  }
  getcloseconcert() {
    return http.get(`/concerts/getcloseconcert`);
  }
  getcloseconcertbydate(date) {
    return http.get('/concerts/getcloseconcertbydate?date='+date);
  }
}

export default new ConcertService();