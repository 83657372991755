<template>
  <div id="app">
    <div class="row" style="margin-right: 0px" v-if="status">
      <div class="bg" v-if="!currentUser">
        <table width="100%">
          <tr>
            <!-- <td width="18%"> -->
              <!-- <img src="./assets/logoleft.png" width="230px"/> -->
              <!-- </td> -->
            <td width="100%" height="200px">
              <center style="vertical-align: middle;
    text-align: right;
    margin-right: 10%;"><img src="./assets/logo_name.jpg" style="width: 65%;"/></center>
            </td>
          </tr>
        </table>
        <div class="row" style="margin-top:5px">
        <div class="col-md-2" style="heigh:100%;padding-right:0px;background-color:black;">
        <Nav msg="Welcome to Your Vue.js App" />
      </div> 
      <div class="col-md-10" style="background-color:white">  
        <!-- <div class="row">
          <div id="example1">123</div>
        </div> -->
        <div class="mt-3">
        <router-view />
        </div>
      </div>
        </div>
        <!-- <table width="100%">
          <tr>
            <td width="15%">
              <Nav msg="Welcome to Your Vue.js App" />
            </td>

            <td width="84%">
              <div class="col-md-12 mt-5" style="padding:5px">
                <router-view />
              </div>
            </td>
          </tr>
        </table> -->
      </div>
       <div v-else>
        <div class="row">
        <div class="col-md-2" style="heigh:100%;padding-right:0px">
        <Nav msg="Welcome to Your Vue.js App" />
      </div> 
      <div class="col-md-10">  
        <router-view />
      </div>
        </div>
      </div>
      <!-- <div class="col-md-2" style="background:white;heigh:100%;padding-right:0px">
         <table width="100%" v-if="!currentUser">
		<tr><td width="100%" height="200px"><center><img src="./assets/logoleft.png" style="width:100%"></center></td></tr>
	</table>  
        <Nav msg="Welcome to Your Vue.js App" />
      </div> -->
      <!-- <div class="col-md-10">  
        <table width="100%" v-if="!currentUser">
		<tr><td width="100%" height="200px"><center><img src="./assets/logo_name.jpg" style="width:80%"></center></td></tr>
	</table>  
        <router-view />
      </div> -->
    </div>
    <div class="row" style="margin-right: 0px" v-else>
      <div class="col-md-12" style="padding: 0px">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";

export default {
  name: "App",
  data() {
    return {
      status: true,
    };
  },
  components: {
    Nav,
  },
  mounted() {
    // console.log(this.currentUser);
    // console.log(this.$route.name);
    if (this.$route.name == "qrcode" || this.$route.name == "concertfront" || this.$route.name == "PromotionImg") {
      this.status = false;
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
div {
  font-family: "Kanit", sans-serif;
  font-size: 14px;
}
.bg {
  margin: 6px;
  background-image: url("./assets/background.jpg");
}
#example1 {
  border-top: 2px solid black;
  /* border-left: 2px solid black; */
  /* border-top-left-radius: 25px; */
}

</style>