import http from "../http-common";

class UsersDataService {
  getUsers() {
    return http.get('/user');
  }
  getUser(id) {
    return http.get(`/user/`+id);
  }
  getMenus(id,type) {
    return http.get('/role_menu?id='+id+'&&type='+type);
  }
  getRole(id) {
    // console.log(`/role/`, id);
    return http.get(`/roles/`+id);
  }
  createRole(data) {
    return http.post('/roles', data);
  }  
  createUser(data) {
    return http.post('/user', data);
  }  
  updateUser(id,data) {
    return http.put(`/user/${id}`, data);
  }
  updateRole(id,data) {
    return http.put(`/roles/${id}`, data);
  }
  getMenusAll() {
    return http.get('/menus');
  }
  updateMenu(id,data) {
    return http.put(`/menus/${id}`, data);
  }
  getonemenu(id) {
    return http.get(`/menus/`+id);
  }
  getRolesAll() {
    return http.get('/roles');
  }
  getMenubyRoleIDAll(id) {
    return http.get('/user/getmenuarray/' + id);
}
deleteRoleMenu(id) {
  return http.delete(`/role_menu/` + id);
}
createRoleMenu(data) {
  return http.post('/role_menu', data);
}
}

export default new UsersDataService();