<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddUser"
        >
          <i class="fa fa-plus"></i> เพิ่มแบนเนอร์หน้าหลักเว็บไซต์
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">แบนเนอร์</th>
          <th scope="col">คอนเสิร์ต</th>
          <th scope="col">วันที่แสดงคอนเสิร์ต</th>
           <th scope="col">จัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }} 
          </td>
          <td>
            <img :src="l.banner_path" style="width:500px ">
          </td>
          <td>{{ l.concert_name }} </td>
           <td>
            {{ convertdate(l.date) }}
          </td>
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddUser"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
              <a>
                <button
                @click="deleteid(l.id)"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              id="closeduser"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group">
                  <label for="username">แบนเนอร์</label>
                  <img  class="mb-3" :src="data.banner_path" style="width:100%"/>
                  <input
        id="my_file"
        class="form-control"
        type="file"
        accept="image/*"
        @change="onFileChange"
      />
                </div>
                <!-- <div class="form-group mt-3">
                  <label for="username">วันที่แสดงคอนเสิร์ต</label>
                 <br/><date-picker
                 disabled
              v-model="data.date"
              format="DD/MM/YYYY"
              valueType="format"
            ></date-picker>
                </div> -->
                <div class="form-group mt-3">
                  <label for="username">คอนเสิร์ต</label>
                 <select
          v-model="data.concert_id"
          class="form-select"
          aria-label="Default select example"
        >
          <option v-for="(z, i) in concert" :key="i" :value="z.id">
            {{ z.concert_name }}
          </option>
        </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainBannerService from "../services/MainBannerService";
import ConcertService from "../services/ConcertService";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import axios from "axios";
import LinkService from "../services/LinkService";

export default {
  name: "Nav",
  components: {
    // DatePicker,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      con_id: 0,
      hash: 0,
      title: "",
      roles:[],
      concert:[],
      data:{}
    };
  },
  mounted() {
    this.getUsers();
    this.getConcerts()
  },
  methods: {
    convertdate(date){
var dates = new Date(date)
var dd = dates.getDate()
var mm = dates.getMonth()+1
var yy = dates.getFullYear()
if (String(dd).length == 1) {
  dd = '0'+dd
}
return dd+'/'+mm+'/'+yy
    },
    convertdatesave(date){
      // console.log(date);
var dd = date.split('/')[1]
var mm = date.split('/')[0]
var yy = date.split('/')[2]
return yy+'/'+dd+'/'+mm
    },
    getConcerts(){
 ConcertService.getconcerts().then((res) => {
          // console.log(res.data);
          for (let c = 0; c < res.data.length; c++) {
            if (res.data[c].id != 17) {
            this.concert.push(res.data[c])                
            }          
          }
 });
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      this.selectedFile = evt.target.files[0];
      
      this.filename = this.selectedFile.name;
      if (!files.length) return;
      this.onUploadFile();
      // }
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      // var http = "http://localhost:8080/uploadbanner?name="+this.filename;
      var http = LinkService.getLink()+"/uploadbanner?name="+this.filename;
      axios
        .post(http, formData)
        .then(() => {   
          this.data.banner_path =  LinkService.getLink()+"/uploads/banners/" + this.filename
          // console.log(this.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteid(id) {
      MainBannerService.deletemain_banner(id).then(()=>{
this.getUsers();
    })
    },
    getid(id) {
      this.con_id = id;
      if (this.con_id != 0) {
        this.title = "แก้ไขข้อมูลแบนเนอร์";
        MainBannerService.getmain_banner(this.con_id).then((res) => {
          this.data = res.data;
          this.data.date = this.convertdate(this.data.date)
          
          console.log(this.data);
        });
      } else {
        this.title = "เพิ่มข้อมูลแบนเนอร์";
        this.data = {};
      }
    },
    save() {
      console.log(this.data);
      if (this.data.banner_path == null) {
        alert("กรุณาเลือกแบนเนอร์");
      } 
      // else if (this.data.date == null) {
      //   alert("กรุณากรอกวันที่แสดงคอนเสิร์ต");
      // } 
      else if (this.data.concert_id == null) {
        alert("กรุณาเลือกคอนเสิร์ต");
      }  else {
        var con = {
          banner_path: this.data.banner_path,
          concert_id: this.data.concert_id,
          // date: this.convertdatesave(this.data.date),
          status: true,
        };
        // console.log(con);
        if (this.con_id == 0) {
          MainBannerService.createmain_banner(con).then(() => {
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          MainBannerService.updatemain_banner(this.con_id, con).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      MainBannerService.getmain_banners().then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
