<template>
    <div class="row">
      <div class="col-md-12">
          <img :src="list.path" style="width:100%" />
      </div>
  </div>

</template>

<script>
import PromotionService from "../services/PromotionService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      list:{}
    };
  },
  mounted() {
    this.getpromotion()
    // console.log(this.$route.query.id);
  },
  methods: {
    getpromotion(){
      PromotionService.getpromotion(this.$route.query.id).then((res)=>{
        this.list = res.data
      })
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-device-width: 1025px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .col93 {
    margin-left: 15px !important;
  }

  .stair119 {
    width: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .footleft {
    margin-left: -8%;
  }

  .col12 {
    width: 90%;
    margin: auto;
  }

  .toiletwomen {
    height: 40px;
    margin-top: 70px;
    text-align: center;
    background-color: black;
    width: 30px;
  }

  .toilettext {
    color: white;
    font-size: 5px;
    text-decoration: overline;
    margin-left: -23px;
  }

  #famen {
    color: white;
    margin-top: 5px;
    font-size: 10px;
    margin-left: -25px;
  }

  .toiletmen {
    text-align: center;
    background-color: black;
    width: 30px;
    height: 28px;
  }

  .sizefireexitleft {
    width: 10px;
  }

  .fireexitleftone {
    display: inline-block;
    margin-top: 30px;
    margin-left: 20px;
  }

  .fireexitlefttwo {
    display: inline-block;
    margin-top: 80px;
    margin-left: 20px;
  }

  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 77px;
    margin-left: 20px;
  }

  .contactheight {
    height: 428px;
  }

  .widthline {
    width: 80px;
  }

  .contact {
    margin-top: 60px;
    margin-left: -9px;
  }

  .stair138 {
    width: 15px;
    margin-left: 15px;
  }

  .col2124 {
    width: 100px;
    height: 428px;
  }

  .mt-3 {
    margin-top: 0.3rem !important;
  }

  .polestyle {
    background-color: black;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    width: 30px;
    margin-top: 15px;
  }

  .margintop120 {
    margin-top: 20px;
  }

  .pole {
    margin-top: 26px;
    width: 30px;
  }

  .backgrountfoot {
    background-color: white;
    height: 212px;
  }

  .footpath {
    margin-left: -16px;
  }

  .fontsizefootpath {
    font-size: 10px;
  }

  .margintop20 {
    margin-top: 20px !important;
  }

  .margintop30 {
    margin-top: 3px;
  }

  .addmargin17 {
    margin-left: 0px;
  }

  .col117 {
    width: 30px;
    margin-top: 5px;
  }

  .heightrow60 {
    height: 30px;
  }

  .heightrow {
    height: 25px;
  }

  .office {
    height: 244px;
    background-color: black;
    color: white;
    margin-top: 10px;
    padding: 0px;
  }

  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }

  .fireexitdownone {
    margin-left: 105px;
    width: 20px;
  }

  .fireexitdowntwo {
    margin-left: 180px;
    width: 20px;
    margin-top: -5px;
  }

  .facetext {
    font-size: 8px;
  }

  .igtext {
    font-size: 8px;
  }

  .doortop {
    margin-left: -45px;
    margin-top: 118px;
  }

  .stair154 {
    width: 15px;
    margin-left: 5px;
  }

  .colrow14 {
    margin-left: -10px;
    margin-top: 0px;
  }

  .col116 {
    margin-left: 15px;
  }

  .colvip {
    margin-left: 15px;
  }

  .col90 {
    margin-left: 27px;
    width: 30%;
  }

  .afterdj {
    margin-left: 10px;
  }

  .col4dj {
    width: 41px;
  }

  .imgdj {
    height: 65px;
    margin-top: 20px;
  }

  .col3dj {
    width: 53px;
  }

  .col77 {
    margin-left: 5px;
  }

  .col7border {
    width: 170px;
    height: 160px;
  }

  .col73 {
    margin-left: 15px;
  }

  .stair73 {
    margin-top: 2px;
    margin-left: 22px;
    width: 15px;
  }

  .colstair1 {
    margin-left: -2px;
    width: 15px;
    margin-top: 10px;
  }

  .widthcol2 {
    width: 10%;
  }

  .widthcol5 {
    width: 30%;
  }

  .widthcol4 {
    width: 30%;
  }

  .col10 {
    margin-left: 18px;
  }

  .col8width {
    height: 428px;
    width: 325px;
  }

  .colbar6 {
    margin-left: 15px;
  }

  .leftbadge {
    margin-left: 7px !important;
  }

  .stage {
    margin: 8px;
    padding: 2px;
    width: 80px;
    height: 20px;
    background: black;
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f;
    font-size: 10px;
    font-weight: bold;
  }

  .bar {
    padding: 5px;
    width: 25px;
    height: 25px;
    background: black;
    /*important*/
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 10px;
    font-weight: 100;
  }

  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 80px;
    height: 14px;
    font-size: 12px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: 5px;
    background-color: white;
    color: black;
    text-align: center;
  }

  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 50px;
    height: 40px;
    font-size: 12px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -10px;
    margin-top: -30px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 50px;
    height: 30px;
    font-size: 14px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -24px;
    margin-top: 33px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 10px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: 40px;
    background-color: black;
    color: yellow;
    text-align: center;
  }

  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 20px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: -15px;
    color: white;
    text-align: center;
  }

  .fas {
    padding: 5px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    text-decoration: none;
    margin: 1px;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }

  .fa-instagram {
    background: #f82153;
    color: white;
  }

  .badge {
    cursor: pointer;
    margin: 0.1px;
    padding: 5px !important;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 8px;
    font-weight: 100;
  }

  .selectbadge {
    cursor: pointer;
    margin: 0.1px;
    padding: 4px;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 8px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 2px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  div.b {
    width: 100%;
    height: 15px;
    background-color: white;
  }

  div.c {
    width: 100%;
    height: 2px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  .stair {
    display: inline-block;
    width: 2px;
    background-color: black;
    height: 15px;
    background-color: black;
  }

  .doorone {
    display: inline-block;
    width: 15px;
    height: 40px;
    background: black;
    border: 1px solid red;
    border-radius: 10%;
  }

  .doortwo {
    display: inline-block;
    width: 15px;
    height: 40px;
    background: black;
    border: 1px solid red;
    border-radius: 10%;
  }

  .handone {
    width: 2px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    background: red;
    border-radius: 20%;
  }

  .handtwo {
    width: 2px;
    height: 20px;
    margin-left: 2px;
    margin-top: 15px;
    background: red;
    border-radius: 20%;
  }
}

@media only screen and (min-width: 100px) {
  .col93 {
    margin-left: 10px !important;
  }

  .stair119 {
    width: 18px;
    margin-left: 0px;
    margin-bottom: 20px
  }

  .banner {
    width: 50%;
  }

  .bannerright {
    width: 30%;
  }

  .margintopbanner {
    margin-top: 10px !important;
  }

  .blankbanner {
    width: 10%;
  }

  .col1banner {
    width: 10%;
  }

  .col2banner {
    width: 8%;
  }

  .col5banner {
    width: 38%;
  }

  .col4banner {
    width: 40.5%;
  }

  .footleft {
    margin-left: -5%;
  }

  .col12 {
    width: 90%;
    margin: auto;
    padding: 0px;
  }

  .toiletwomen {
    height: 27px;
    margin-top: 40px;
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -19px;
  }

  .toilettext {
    color: white;
    font-size: 5px;
    text-decoration: overline;
    margin-left: -28px;
  }

  #famen {
    color: white;
    margin-top: 8px;
    font-size: 10px;
    margin-left: -17px;
  }

  .toiletmen {
    text-align: center;
    background-color: black;
    width: 30px;
    height: 28px;
    margin-left: -18px;
  }

  .sizefireexitleft {
    width: 11px;
    margin-left: -14px;
  }

  .fireexitleftone {
    display: inline-block;
    margin-top: 30px;
    margin-left: 55px;
  }

  .fireexitlefttwo {
    display: inline-block;
    margin-top: 80px;
    margin-left: 55px;
  }

  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 77px;
    margin-left: 52px;
  }

  .contactheight {
    height: 428px;
    width: 16%;
  }

  .widthline {
    width: 80px;
  }

  .contact {
    margin-top: 60px;
    margin-left: -9px;
  }

  .stair138 {
    width: 15px;
    margin-left: 20px;
    margin-top: -22px;
  }

  .col2124 {
    width: 100px;
    height: 428px;
  }

  .mt-3 {
    margin-top: 0.3rem !important;
  }

  .polestyle {
    background-color: black;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    width: 30px;
    margin-top: 15px;
  }

  .margintop120 {
    margin-top: 20px;
  }

  .pole {
    margin-top: 35px;
    width: 30px;
  }

  .backgrountfoot {
    background-color: white;
    height: 212px;
  }

  .footpath {
    margin-left: -16px;
  }

  .fontsizefootpath {
    font-size: 10px;
  }

  .margintop20 {
    margin-top: 0px !important;
  }

  .margintop125 {
    margin-top: 7px !important;
  }

  .margintop136 {
    margin-top: 12px !important;
  }

  .margintop30 {
    margin-top: -1px;
  }

  .addmargin17 {
    margin-left: 0px;
  }

  .col117 {
    width: 10px;
    margin-top: 15px;
    margin-left: -13px;
  }

  .heightrow60 {
    height: 30px;
  }

  .heightrow {
    height: 25px;
  }

  .office {
    height: 256px;
    background-color: black;
    color: white;
    margin-top: 31px;
    padding: 0px;
  }

  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }

  .fireexitdownone {
    margin-top: -8px;
    margin-left: 120px;
    width: 20px;
  }

  .fireexitdowntwo {
    margin-left: 180px;
    width: 20px;
    margin-top: -8px;
  }

  .facetext {
    font-size: 8px;
  }

  .igtext {
    font-size: 8px;
  }

  .doortop {
    margin-left: -45px;
    margin-top: 118px;
  }

  .stair154 {
    width: 15px;
    margin-left: 5px;
  }

  .colrow14 {
    margin-left: -4px;
    margin-top: 0px;
  }

  .col116 {
    margin-left: 5px;
  }

  .colvip {
    margin-left: 5px;
  }

  .col90 {
    margin-left: 32px;
    width: 33%;
  }

  .afterdj {
    margin-left: 10px;
  }

  .col4dj {
    width: 31px;
    margin-left: -12px;
  }

  .imgdj {
    height: 52px;
    margin-top: 13px;
  }

  .col3dj {
    width: 60px;
  }

  .col77 {
    margin-left: 5px;
  }

  .col7border {
    width: 175px;
  }

  .col73 {
    margin-left: 15px;
  }

  .stair73 {
    margin-top: 0px;
    margin-left: 20px;
    width: 15px;
  }

  .colstair1 {
    margin-left: 20px;
    width: 15px;
    margin-top: -25px;
  }

  .widthcol2 {
    width: 10%;
  }

  .widthcol5 {
    width: 30%;
  }

  .widthcol4 {
    width: 30%;
  }

  .col10 {
    margin-left: 13px;
  }

  .colleft {
    margin-left: 2px !important;
  }

  .col8width {
    height: 428px;
    width: 47%;
  }

  .col2width {
    width: 17%;
  }

  .colbar6 {
    margin-left: 3px;
  }

  .leftbadge {
    margin-left: 5px !important;
  }

  .stage {
    margin: 8px;
    padding: 2px;
    width: 47px;
    height: 20px;
    background: black;
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f;
    font-size: 10px;
    font-weight: bold;
  }

  .bar {
    padding: 3px;
    margin: 2px;
    width: 20px;
    height: 20px;
    background: black;
    /*important*/
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 8px;
    font-weight: 100;
  }

  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 47px;
    height: 14px;
    font-size: 10px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: 5px;
    background-color: white;
    color: black;
    text-align: center;
  }

  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 50px;
    height: 40px;
    font-size: 12px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -10px;
    margin-top: -30px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 50px;
    height: 30px;
    font-size: 12px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -24px;
    margin-top: 45px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 10px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: 40px;
    background-color: black;
    color: yellow;
    text-align: center;
  }

  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 20px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: -15px;
    color: white;
    text-align: center;
  }

  .fas {
    padding: 4px;
    font-size: 10px;
    width: 17px;
    height: 17px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }

  .fa-instagram {
    background: #f82153;
    color: white;
  }

  .badge {
    cursor: pointer;
    margin: 0.1px;
    padding: 2px !important;
    padding-top: 5px !important;
    width: 16px;
    height: 16px;
    margin-top: 5px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 7px;
    font-weight: 100;
  }

  .selectbadge {
    cursor: pointer;
    margin: 0.1px;
    padding: 3px;
    width: 16px;
    height: 16px;
    margin-top: 5px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 7px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 2px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  div.b {
    width: 100%;
    height: 12px;
    background-color: white;
  }

  div.c {
    width: 100%;
    height: 2px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  .stair {
    display: inline-block;
    width: 2px;
    background-color: black;
    height: 15px;
    background-color: black;
  }

  .doorone {
    display: inline-block;
    width: 15px;
    height: 40px;
    background: black;
    border: 1px solid red;
    border-radius: 10%;
  }

  .doortwo {
    display: inline-block;
    width: 15px;
    height: 40px;
    background: black;
    border: 1px solid red;
    border-radius: 10%;
  }

  .handone {
    width: 2px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    background: red;
    border-radius: 20%;
  }

  .handtwo {
    width: 2px;
    height: 20px;
    margin-left: 2px;
    margin-top: 15px;
    background: red;
    border-radius: 20%;
  }
}

@media only screen and (min-width: 1050px) {
  .col93 {
    margin-left: 17px !important;
  }

  .stair119 {
    width: 20px;
    margin-left: 0px;
    margin-top: -7px;
    margin-bottom: 0px;
  }

  .banner {
    width: 41%;
  }

  .bannerright {
    width: 30%;
  }

  .margintopbanner {
    margin-top: 15px !important;
  }

  .blankbanner {
    width: 20%;
  }

  .col1banner {
    width: 15%;
  }

  .col2banner {
    width: 8%;
  }

  .col5banner {
    width: 26.5%;
  }

  .col4banner {
    width: 35%;
  }

  .footleft {
    margin-left: -21px;
    height: 247px;
  }

  .col2width {
    width: 12% !important;
  }

  .colleft {
    margin-left: 6px !important;
  }

  .col12 {
    width: 90%;
    margin: auto;
    padding: 0px;
  }

  .backgrountfoot {
    height: 10px;
    background-color: transparent;
  }

  .toiletwomen {
    height: 40px;
    margin-top: 57px;
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: 1px;
  }

  .toilettext {
    color: white;
    font-size: 9px;
    text-decoration: overline;
    padding: 5px;
    margin-left: -10px;
  }

  #famen {
    color: white;
    margin-top: 5px;
    font-size: 18px;
    margin-left: -10px;
  }

  .toiletmen {
    text-align: center;
    background-color: black;
    width: 26px;
    height: 51px;
    margin-left: 1px;
  }

  .sizefireexitleft {
    width: 15px;
  }

  .fireexitleftone {
    display: inline-block;
    margin-top: 45px;
    margin-left: 122px;
  }

  .fireexitlefttwo {
    display: inline-block;
    margin-top: 90px;
    margin-left: 122px;
  }

  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 80px;
    margin-left: 100px;
  }

  .widthline {
    width: 90px;
  }

  .contact {
    margin-top: 97px;
    margin-left: -15px;
  }

  .stair138 {
    width: 21px;
    margin-left: 20px;
    margin-top: -28px;
  }

  .contactheight {
    width: 14%;
    height: 520px;
  }

  .col2124 {
    width: 14%;
    height: 748px;
  }

  .polestyle {
    background-color: black;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 6px;
    width: 37px;
    margin-top: 21px;
  }

  .margintop120 {
    margin-top: 23px;
    margin-left: 7px;
  }

  .margintop125 {
    margin-top: 5px !important;
  }

  .margintop136 {
    margin-top: 15px !important;
  }

  .pole {
    margin-top: 47px;
    margin-left: 8px;
  }

  .footpath {
    margin-left: -56px;
  }

  .fontsizefootpath {
    font-size: 15px;
  }

  .margintop20 {
    margin-top: 10px !important;
  }

  .margintop30 {
    margin-top: 1px;
  }

  .addmargin17 {
    margin-left: 17px;
  }

  .col117 {
    width: 0px;
    margin-top: 5px;
    margin-left: -12px;
  }

  .heightrow60 {
    height: 40px;
  }

  .heightrow {
    height: 30px;
  }

  .office {
    height: 315px;
    background-color: black;
    color: white;
    margin-top: 27px;
    padding: 0px;
  }

  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .fireexitdownone {
    margin-left: 190px;
    width: 35px;
    margin-top: 0px;
  }

  .fireexitdowntwo {
    margin-left: 242px;
    width: 35px;
    margin-top: 0px;
  }

  .facetext {
    font-size: 10px;
  }

  .igtext {
    font-size: 10px;
  }

  .doortop {
    margin-left: -36px;
    margin-top: 130px;
  }

  .stair154 {
    margin-left: 12px;
    width: 20px;
  }

  .colrow14 {
    margin-left: -4px;
    margin-top: 4px;
  }

  .col116 {
    margin-left: 5px;
  }

  .colvip {
    margin-left: 5px;
  }

  .col90 {
    margin-left: -22px;
    width: 30%;
  }

  .afterdj {
    margin-left: 10px;
  }

  .col4dj {
    width: 122px;
  }

  .imgdj {
    height: 75px;
  }

  .addmargin17 {
    margin-left: 0px;
  }

  .col3dj {
    width: 78px !important;
  }

  .col77 {
    margin-left: 5px;
  }

  .col7border {
    width: 250px;
    height: 240px;
    padding-left: 5px;
  }

  .col73 {
    margin-left: 20px;
  }

  .stair73 {
    margin-left: 35px;
    width: 21px;
  }

  .colstair1 {
    margin-left: 33px;
    width: 20px;
    margin-top: -23px;
  }

  .widthcol2 {
    width: 16%;
    height: 520px;
  }

  .widthcol5 {
    width: 40%;
  }

  .widthcol4 {
    width: 36%;
  }

  .col10 {
    margin-left: 9px;
  }

  .col8width {
    width: 45%;
    padding: 8px;
    height: 520px;
  }

  .colbar6 {
    margin-left: 6px;
  }

  .leftbadge {
    margin-left: 9px !important;
  }

  .stage {
    margin: 8px;
    padding: 2px;
    width: 70px;
    height: 30px;
    background: black;
    /*important*/
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f;
    /*no need*/
    font-size: 14px;
    font-weight: bold;
    margin-top: -5px;
  }

  .bar {
    padding: 10px;
    width: 40px;
    height: 40px;
    background: black;
    /*important*/
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 14px;
    font-weight: 100;
  }

  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 65px;
    height: 20px;
    font-size: 14px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -27px;
    margin-top: -15px;
    background-color: white;
    color: black;
    text-align: center;
  }

  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 50px;
    font-size: 20px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -30px;
    margin-top: -31px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 67px;
    height: 36px;
    font-size: 15px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -25px;
    margin-top: 39px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 17px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -32px;
    margin-top: 39px;
    background-color: black;
    color: yellow;
    text-align: center;
  }

  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 30px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: 0px;
    color: white;
    text-align: center;
  }

  .fas {
    padding: 5px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    text-decoration: none;
    margin: 1px;
    margin-left: 10px;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }

  .fa-instagram {
    background: #f82153;
    color: white;
  }

  .badge {
    cursor: pointer;
    padding: 2px !important;
    padding-top: 7px !important;
    margin: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: 100;
  }

  .selectbadge {
    cursor: pointer;
    padding: 2px !important;
    padding-top: 4px !important;
    width: 22px;
    height: 22px;
    margin: 1px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  div.b {
    width: 100%;
    height: 20px;
    background-color: white;
  }

  div.c {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  .stair {
    display: inline-block;
    width: 4px;
    background-color: black;
    height: 20px;
    background-color: black;
  }

  .doorone {
    display: inline-block;
    width: 30px;
    height: 50px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }

  .doortwo {
    display: inline-block;
    width: 30px;
    height: 50px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }

  .handone {
    width: 4px;
    height: 15px;
    margin-left: 18px;
    margin-top: 25px;
    background: red;
    border-radius: 20%;
  }

  .handtwo {
    width: 4px;
    height: 15px;
    margin-left: 2px;
    margin-top: 25px;
    background: red;
    border-radius: 20%;
  }
}

@media only screen and (min-width: 1366px) {
  .col93 {
    margin-left: 26px !important;
  }

  .margintop138 {
    margin-top: -13px;
  }

  .stair119 {
    width: 20px;
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .banner {
    width: 50.5%;
  }

  .bannerright {
    width: 30%;
  }

  .margintopbanner {
    margin-top: 15px;
  }

  .blankbanner {
    width: 10%;
  }

  .borderheight {
    height: 250px;
  }

  .col1banner {
    width: 12%;
  }

  .col2banner {
    width: 10%;
  }

  .col5banner {
    width: 33%;
  }

  .col4banner {
    width: 37.5%;
  }

  .footleft {
    margin-left: -19.5px;
    height: 330px;
  }

  .contactheight {
    width: 15.5%;
    height: 755px;
  }

  .col2width {
    width: 12% !important;
    height: 755px;
  }

  .colleft {
    margin-left: 7px !important;
  }

  .col12 {
    margin: auto;
    width: 100%;
    text-align: center;
  }

  .backgrountfoot {
    height: 10px;
  }

  .toiletwomen {
    height: 40px;
    margin-top: 90px;
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -5px;
  }

  .toilettext {
    color: white;
    font-size: 9px;
    text-decoration: overline;
    margin: auto;
  }

  #famen {
    color: white;
    margin-top: 5px;
    font-size: 18px;
    margin-left: 0px;
  }

  .toiletmen {
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -5px;
  }

  .sizefireexitleft {
    width: 30px;
  }

  .fireexitleftone {
    display: inline-block;
    margin-top: 55px;
    margin-left: 95px;
  }

  .fireexitlefttwo {
    display: inline-block;
    margin-top: 140px;
    margin-left: 95px;
  }

  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 170px;
    margin-left: 80px;
  }

  .widthline {
    width: 120px;
  }

  .contact {
    margin-top: 130px;
    margin-left: -15px;
    margin-bottom: 15px;
  }

  .stair138 {
    width: 28px;
    margin-left: 30px;
  }

  .col2124 {
    width: 150px;
    height: 806px;
  }

  .polestyle {
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 6px;
    width: 50px;
    margin-top: 50px;
  }

  .margintop120 {
    margin-top: 30px;
    margin-left: 5px;
  }

  .pole {
    margin-top: 50px;
  }

  .footpath {
    margin-left: -150px;
  }

  .fontsizefootpath {
    font-size: 20px;
  }

  .margintop20 {
    margin-top: 10px !important;
  }

  .margintop125 {
    margin-top: 17px !important;
  }

  .margintop136 {
    margin-top: 25px !important;
  }

  .margintop30 {
    margin-top: 5px;
  }

  .addmargin17 {
    margin-left: 17px;
  }

  .col117 {
    width: 50px;
    margin-top: 2px;
    margin-left: -15px;
  }

  .heightrow60 {
    height: 60px;
  }

  .heightrow {
    height: 50px;
  }

  .office {
    height: 490px;
    background-color: black;
    color: white;
    margin-top: 25px;
    padding: 0px;
  }

  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .fireexitdownone {
    margin-left: 210px;
    width: 40px;
    margin-top: 0px;
  }

  .fireexitdowntwo {
    margin-left: 330px;
    width: 40px;
    margin-top: 0px;
  }

  .facetext {
    font-size: 12px;
  }

  .igtext {
    font-size: 12px;
  }

  .doortop {
    margin-left: -36px;
    margin-top: 80px;
  }

  .stair154 {
    margin-left: 34px;
    width: 20px;
  }

  .colrow14 {
    margin-left: -2px;
    margin-top: 5px;
  }

  .col116 {
    margin-left: 10px;
  }

  .colvip {
    margin-left: 10px;
  }

  .col90 {
    margin-left: 35px;
    width: 30%;
  }

  .afterdj {
    margin-left: 20px;
  }

  .col4dj {
    width: 30%;
  }

  .imgdj {
    height: 53%;
  }

  .col3dj {
    width: 30% !important;
  }

  .col77 {
    margin-left: 10px;
  }

  .col7border {
    width: 65%;
    height: 200px !important;
  }

  .col73 {
    margin-left: 30px;
  }

  .stair73 {
    margin-left: 48px;
    width: 28px;
  }

  .colstair1 {
    margin-left: 45px;
    width: 28px;
    margin-top: -30px;
  }

  .widthcol2 {
    width: 9.5%;
  }

  .widthcol5 {
    width: 36.5%;
  }

  .widthcol4 {
    width: 43%;
  }

  .col8width {
    width: 53%;
    height: 755px;
  }

  .col10 {
    margin-left: 45px;
  }

  .colbar6 {
    margin-left: 20px;
  }

  .leftbadge {
    margin-left: 11px !important;
  }

  .stage {
    margin: 8px;
    padding: 2px;
    width: 100px;
    height: 40px;
    background: black;
    /*important*/
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f;
    /*no need*/
    font-size: 20px;
    font-weight: bold;
  }

  .bar {
    padding: 10px;
    width: 45px;
    height: 45px;
    margin-top: 0px;
    background: black;
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 100;
  }

  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 36px;
    font-size: 20px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: -15px;
    background-color: white;
    color: black;
    text-align: center;
  }

  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 90px;
    height: 48px;
    font-size: 20px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -30px;
    margin-top: -34px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 50px;
    font-size: 18px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -34px;
    margin-top: 38px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 17px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -32px;
    margin-top: 150px;
    background-color: black;
    color: yellow;
    text-align: center;
  }

  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 40px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: 50px;
    color: white;
    text-align: center;
  }

  .fas {
    padding: 5px;
    font-size: 25px;
    width: 35px;
    height: 35px;
    text-align: center;
    text-decoration: none;
    margin: 5px;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }

  .fa-instagram {
    background: #f82153;
    color: white;
  }

  .badge {
    cursor: pointer;
    margin: 2px;
    padding: 3px !important;
    padding-top: 8px !important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 14px;
    font-weight: 100;
  }

  .selectbadge {
    cursor: pointer;
    margin: 2px;
    padding: 6px;
    width: 30px;
    height: 30px;
    background: gray;
    /*important*/
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 14px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  div.b {
    width: 100%;
    height: 20px;
    background-color: white;
  }

  div.c {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  .stair {
    display: inline-block;
    width: 4px;
    background-color: black;
    height: 20px;
    background-color: black;
  }

  .doorone {
    display: inline-block;
    width: 30px;
    height: 80px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }

  .doortwo {
    display: inline-block;
    width: 30px;
    height: 80px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }

  .handone {
    width: 4px;
    height: 26px;
    margin-left: 18px;
    margin-top: 45px;
    background: red;
    border-radius: 20%;
  }

  .handtwo {
    width: 4px;
    height: 26px;
    margin-left: 2px;
    margin-top: 44px;
    background: red;
    border-radius: 20%;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1600px) {
  .col93 {
    margin-left: 26px !important;
  }

  .margintop138 {
    margin-top: -13px;
  }

  .stair119 {
    width: 20px;
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .banner {
    width: 50.5%;
  }

  .bannerright {
    width: 30%;
  }

  .margintopbanner {
    margin-top: 20px;
  }

  .blankbanner {
    width: 10%;
  }

  .borderheight {
    height: 266px;
  }

  .col1banner {
    width: 12%;
  }

  .col2banner {
    width: 10%;
  }

  .col5banner {
    width: 33%;
  }

  .col4banner {
    width: 37.5%;
  }

  .footleft {
    margin-left: -19.5px;
    height: 352px;
  }

  .contactheight {
    width: 15.5%;
    height: 775px;
  }

  .col2width {
    width: 12% !important;
    height: 775px;
  }

  .colleft {
    margin-left: 7px !important;
  }

  .col12 {
    margin: auto;
    width: 100%;
    text-align: center;
  }

  .backgrountfoot {
    height: 10px;
  }

  .toiletwomen {
    height: 40px;
    margin-top: 90px;
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -5px;
  }

  .toilettext {
    color: white;
    font-size: 9px;
    text-decoration: overline;
    margin: auto;
  }

  #famen {
    color: white;
    margin-top: 5px;
    font-size: 18px;
    margin-left: 0px;
  }

  .toiletmen {
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -5px;
  }

  .sizefireexitleft {
    width: 30px;
  }

  .fireexitleftone {
    display: inline-block;
    margin-top: 55px;
    margin-left: 119px;
  }

  .fireexitlefttwo {
    display: inline-block;
    margin-top: 140px;
    margin-left: 119px;
  }

  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 170px;
    margin-left: 97px;
  }

  .widthline {
    width: 120px;
  }

  .contact {
    margin-top: 130px;
    margin-left: -15px;
    margin-bottom: 15px;
  }

  .stair138 {
    width: 28px;
    margin-left: 30px;
  }

  .col2124 {
    width: 150px;
    height: 806px;
  }

  .polestyle {
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 6px;
    width: 50px;
    margin-top: 12px;
  }

  .margintop120 {
    margin-top: 30px;
    margin-left: 5px;
  }

  .pole {
    margin-top: 50px;
  }

  .footpath {
    margin-left: -150px;
  }

  .fontsizefootpath {
    font-size: 20px;
  }

  .margintop20 {
    margin-top: 10px !important;
  }

  .margintop125 {
    margin-top: 17px !important;
  }

  .margintop136 {
    margin-top: 25px !important;
  }

  .margintop30 {
    margin-top: 5px;
  }

  .addmargin17 {
    margin-left: 17px;
  }

  .col117 {
    width: 50px;
    margin-top: 2px;
    margin-left: -15px;
  }

  .heightrow60 {
    height: 60px;
  }

  .heightrow {
    height: 50px;
  }

  .office {
    height: 478px;
    background-color: black;
    color: white;
    margin-top: 25px;
    padding: 0px;
  }

  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .fireexitdownone {
    margin-left: 260px;
    width: 40px;
    margin-top: 0px;
  }

  .fireexitdowntwo {
    margin-left: 404px;
    width: 40px;
    margin-top: 0px;
  }

  .facetext {
    font-size: 12px;
  }

  .igtext {
    font-size: 12px;
  }

  .doortop {
    margin-left: -36px;
    margin-top: 97px;
  }

  .stair154 {
    margin-left: 34px;
    width: 20px;
  }

  .colrow14 {
    margin-left: -2px;
    margin-top: 5px;
  }

  .col116 {
    margin-left: 10px;
  }

  .colvip {
    margin-left: 10px;
  }

  .col90 {
    margin-left: 35px;
    width: 30%;
  }

  .afterdj {
    margin-left: 20px;
  }

  .col4dj {
    width: 30%;
  }

  .imgdj {
    height: 53%;
  }

  .col3dj {
    width: 30% !important;
  }

  .col77 {
    margin-left: 10px;
  }

  .col7border {
    width: 65%;
    height: 200px !important;
  }

  .col73 {
    margin-left: 45px;
  }

  .stair73 {
    margin-left: 61px;
    width: 28px;
  }

  .colstair1 {
    margin-left: 62px;
    width: 28px;
    margin-top: -30px;
  }

  .widthcol2 {
    width: 9.5%;
  }

  .widthcol5 {
    width: 36.5%;
  }

  .widthcol4 {
    width: 43%;
  }

  .col8width {
    width: 53%;
    height: 775px;
  }

  .col10 {
    margin-left: 45px;
  }

  .colbar6 {
    margin-left: 20px;
  }

  .leftbadge {
    margin-left: 11px !important;
  }

  .stage {
    margin: 8px;
    padding: 2px;
    width: 115px;
    height: 40px;
    background: black;
    /*important*/
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f;
    /*no need*/
    font-size: 20px;
    font-weight: bold;
  }

  .bar {
    padding: 10px;
    width: 45px;
    height: 45px;
    margin-top: 0px;
    background: black;
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 100;
  }

  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 36px;
    font-size: 20px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: -15px;
    background-color: white;
    color: black;
    text-align: center;
  }

  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 90px;
    height: 48px;
    font-size: 20px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -30px;
    margin-top: -34px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 50px;
    font-size: 18px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -34px;
    margin-top: 38px;
    background-color: black;
    color: white;
    text-align: center;
  }

  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 17px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -32px;
    margin-top: 150px;
    background-color: black;
    color: yellow;
    text-align: center;
  }

  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 40px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: 50px;
    color: white;
    text-align: center;
  }

  .fas {
    padding: 5px;
    font-size: 25px;
    width: 35px;
    height: 35px;
    text-align: center;
    text-decoration: none;
    margin: 5px;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }

  .fa-instagram {
    background: #f82153;
    color: white;
  }

  .badge {
    cursor: pointer;
    margin: 2px;
    padding: 3px !important;
    padding-top: 8px !important;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 14px;
    font-weight: 100;
  }

  .selectbadge {
    cursor: pointer;
    margin: 2px;
    padding: 6px;
    width: 38px;
    height: 38px;
    background: gray;
    /*important*/
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    /*no need*/
    font-size: 14px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  div.b {
    width: 100%;
    height: 20px;
    background-color: white;
  }

  div.c {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg);
    /* IE 9 */
    transform: skewX(30deg);
  }

  .stair {
    display: inline-block;
    width: 4px;
    background-color: black;
    height: 20px;
    background-color: black;
  }

  .doorone {
    display: inline-block;
    width: 30px;
    height: 80px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }

  .doortwo {
    display: inline-block;
    width: 30px;
    height: 80px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }

  .handone {
    width: 4px;
    height: 26px;
    margin-left: 18px;
    margin-top: 45px;
    background: red;
    border-radius: 20%;
  }

  .handtwo {
    width: 4px;
    height: 26px;
    margin-left: 2px;
    margin-top: 44px;
    background: red;
    border-radius: 20%;
  }
}

body {
  margin: 60px;
  background: white;
}

.flex-container {
  display: flex;
}

.flex {
  display: inline-flex;
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}

.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9;
  /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9;
  /*no need*/
  font-size: 20px;
  font-weight: 100;
}

.neonText {
  color: #f269d3;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #bc13fe,
    0 0 82px #bc13fe,
    0 0 92px #bc13fe,
    0 0 102px #bc13fe,
    0 0 151px #bc13fe;
  height: 70%;
}

.seatconfirm {
  width: 1.4%;
  position: absolute;
  margin-top: -2px;
  margin-left: -5px;
}

h1 {
  text-align: center;
  font-size: 1.9rem;
  animation: pulsate 1.5s infinite alternate;
  border: 0.1rem solid #fc0eb6;
  border-radius: 1rem;
  padding: 0.3em;
  box-shadow: 0 0 .2rem #fff,
    0 0 .2rem #fff,
    0 0 2rem #bc13fe,
    0 0 0.8rem #bc13fe,
    0 0 2.8rem #f478cf,
    inset 0 0 1.3rem #f478cf;
}

@keyframes pulsate {
  100% {
    text-shadow:
      0 0 4px #ed0f3e,
      0 0 11px #ed0f3e,
      0 0 19px #ed0f3e,
      0 0 40px #f367f1,
      0 0 80px #f367f1,
      0 0 90px #f367f1,
      0 0 100px #f367f1,
      0 0 150px #f367f1;
  }

  0% {
    text-shadow:
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 6px #fff,
      0 0 10px #bc13fe,
      0 0 45px #bc13fe,
      0 0 55px #bc13fe,
      0 0 70px #bc13fe,
      0 0 80px #bc13fe;
  }
}
</style>
