<template>
  <div class="container">
    <div class="mb-3 row mt-5">
      <label for="staticEmail" class="col-sm-12 col-form-label"
        >ตั้งเวลาการจองโต๊ะของลูกค้า</label
      >
    </div>
     <div class="mb-3 row mt-5">
      <label for="staticEmail" class="col-sm-2 col-form-label"
        >เวลาเริ่มต้นจองโต๊ะ</label
      >
      <div class="col-sm-10">
        <input
          v-model="data.time_start"
          class="form-control"
          type="time"
          id="appt"
          name="appt"
        />
      </div>
    </div>
     <div class="mb-3 row mt-5">
      <label for="staticEmail" class="col-sm-2 col-form-label"
        >สิ้นสุดเวลาจองโต๊ะ</label
      >
      <div class="col-sm-10">
        <input
          v-model="data.time_end"
          class="form-control"
          type="time"
          id="appt"
          name="appt"
        />
      </div>
    </div>
    <div class="col mb-3 mt-3" style="text-align: center">
      <a>
        <button type="button" class="btn btn-success" @click="save()">
          บันทึก
        </button></a
      >
    </div>
  </div>
</template>
<script>
import MainTimeBookingEndService from "../services/MainTimeBookingEndService";

export default {
  name: "Nav",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      data: {},
      con_id: {},
    };
  },
  mounted() {
    this.getTime();
  },
  methods: {
    convertdatesave(date) {
      //       console.log(date);
      // var dates = date.split(":");
      return date + ":00";
    },
    getTime() {
      MainTimeBookingEndService.getmanage_bookings().then((res) => {
        console.log(res.data);
        this.data = res.data[0];
      });
    },
    save() {
      if (this.data.time_end == null) {
        alert("กรุณากรอกเวลาเริ่มต้นการจองโต๊ะของลูกค้า");
      } else  if (this.data.time_start == null) {
        alert("กรุณากรอกเวลาสิ้นสุดการจองโต๊ะของลูกค้า");
      } else {
        var con = {
          time_end: this.data.time_end,
          time_start:this.data.time_start
        };
        console.log(con);
        if (this.con_id == 0) {
          MainTimeBookingEndService.createmanage_booking(con).then(() => {
            alert("บันทึกสำเร็จ");
            this.getTime();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          MainTimeBookingEndService.updatemanage_booking(this.data.id, con).then(() => {
            // console.log(res.data);
            alert("บันทึกสำเร็จ");
            this.getTime();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
