import Vue from "vue";
import Router from "vue-router";
import concert from './components/concert.vue';
import concertlist from './components/concertlist.vue';
import confirmlist from './components/confirmlist.vue';
import confirm from './components/confirm.vue';
import Qrcode from './components/Qrcode.vue';
import bannerlist from './components/bannerlist.vue';
import zone from './components/zone.vue';
import concertchart from './components/concertchart.vue';
import report from './components/report.vue';
import login from './components/login.vue';
import concertchartadmin from './components/concertchartadmin.vue';
import users from './components/users.vue';
import concertall from './components/concertall.vue';
import concertchartbanner from './components/concertchartbanner.vue';
import confirmseat from './components/confirmseat.vue';
import cancleconfirmseat from './components/cancleconfirmseat.vue';
import headerconcert from './components/headerconcert.vue';
import concertfront from './components/concertfront.vue';
import timeconcert from './components/timeconcert.vue';
import managemenus from './components/managemenus.vue';
import concertpublic from './components/concertpublic.vue';
import concertmainlist from './components/concertmainlist.vue';
import concertbanner from './components/concertbanner.vue';
import managetime from './components/managetime.vue';
import booking from './components/booking.vue';
import bookinglist from './components/bookinglist.vue';
import bookadmin from './components/bookadmin.vue';
import QrcodeBook from './components/QrcodeBook.vue';
import managetimebook from './components/managetimebook.vue';
import concertmap from './components/concertmap.vue';
import managetimebookend from './components/managetimebookend.vue';
import manageperseat from './components/manageperseat.vue';
import bookingadmin from './components/bookingadmin.vue';
import manageseats from './components/manageseats.vue';
import concertremark from './components/concertremark.vue';
import managebooking from './components/managebooking.vue';
import concertclose from './components/concertclose.vue';
import managemenu from './components/managemenu.vue';
import closebooking from './components/closebooking.vue';
import updatenewseat from './components/updatenewseat.vue';
import promotions from './components/promotions.vue';
import Promotionlist from './components/Promotionlist.vue';
import PromotionImg from './components/PromotionImg.vue';


Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/concert/:id",
      alias: "/concert",
      name: "concert",
      component: concert
    },
    {
      path: "/bannerlist/:id",
      alias: "/bannerlist",
      name: "bannerlist",
      component: bannerlist
    },
    {
      path: "/concertlist",
      alias: "/concertlist",
      name: "concertlist",
      component: concertlist
    },
    {
      path: "/confirmlist",
      alias: "/confirmlist",
      name: "confirmlist",
      component: confirmlist
    },
    {
      path: "/confirm/:id/:ticketid",
      alias: "/confirm",
      name: "confirm",
      component: confirm
    },
    {
      path: "/qrcode/:id",
      alias: "/qrcode",
      name: "qrcode",
      component: Qrcode
    },
    {
      path: "/qrcodebook/:id",
      alias: "/qrcodebook",
      name: "qrcodebook",
      component: QrcodeBook
    },
    {
      path: "/zone/:id",
      alias: "/zone",
      name: "zone",
      component: zone
    },
    {
      path: "/concertchart/:id",
      alias: "/concertchart",
      name: "concertchart",
      component: concertchart
    },
    {
      path: "/concertchartadmin/:id",
      alias: "/concertchartadmin",
      name: "concertchartadmin",
      component: concertchartadmin
    },
    {
      path: "/report/:id",
      alias: "/report",
      name: "report",
      component: report
    },
    {
      path: "/bookinglist/:id",
      alias: "/bookinglist",
      name: "bookinglist",
      component: bookinglist
    },
    {
      path: "/bookadmin/:id/:ticketid",
      alias: "/bookadmin",
      name: "bookadmin",
      component: bookadmin
    },
    {
      path: "/login",
      alias: "/login",
      name: "login",
      component: login
    },
    {
      path: "/managemenu/:id",
      alias: "/managemenu",
      name: "managemenu",
      component: managemenu
    },
    {
      path: "/users/:id",
      alias: "/users",
      name: "users",
      component: users
    },
    {
      path: "/concertall/:id",
      alias: "/concertall",
      name: "concertall",
      component: concertall
    },
    {
      path: "/concertchartbanner/:id",
      alias: "/concertchartbanner",
      name: "concertchartbanner",
      component: concertchartbanner
    },
    
    {
      path: "/promotionlist",
      alias: "/promotionlist",
      name: "promotionlist",
      component: Promotionlist
    },
    {
      path: "/confirmseat/:id",
      alias: "/confirmseat",
      name: "confirmseat",
      component: confirmseat
    },
    {
      path: "/cancleconfirmseat/:id",
      alias: "/cancleconfirmseat",
      name: "cancleconfirmseat",
      component: cancleconfirmseat
    },
    {
      path: "/headerconcert/:id",
      alias: "/headerconcert",
      name: "headerconcert",
      component: headerconcert
    },
    {
      path: "/concertfront/:id",
      alias: "/concertfront",
      name: "concertfront",
      component: concertfront
    },
    {
      path: "/concertpublic/:id",
      alias: "/concertpublic",
      name: "concertpublic",
      component: concertpublic
    },
    {
      path: "/concertmap/:id",
      alias: "/concertmap",
      name: "concertmap",
      component: concertmap
    },
    {
      path: "/timeconcert/:id",
      alias: "/timeconcert",
      name: "timeconcert",
      component: timeconcert
    },
    {
      path: "/menus/:id",
      alias: "/menus",
      name: "menus",
      component: managemenus
    },
    {
      path: "/concertmainlist/:id",
      alias: "/concertmainlist",
      name: "concertmainlist",
      component: concertmainlist
    },
    {
      path: "/promotions/:id",
      alias: "/promotions",
      name: "promotions",
      component: promotions
    },
    {
      path: "/concertbanner/:id",
      alias: "/concertbanner",
      name: "concertbanner",
      component: concertbanner
    },
    {
      path: "/managetime/:id",
      alias: "/managetime",
      name: "managetime",
      component: managetime
    },
    {
      path: "/managetimebook/:id",
      alias: "/managetimebook",
      name: "managetimebook",
      component: managetimebook
    },
    {
      path: "/managetimebookend/:id",
      alias: "/managetimebookend",
      name: "managetimebookend",
      component: managetimebookend
    },    
    {
      path: "/manageperseat/:id",
      alias: "/manageperseat",
      name: "manageperseat",
      component: manageperseat
    },
    {
      path: "/book",
      alias: "/booking",
      name: "booking",
      component: booking
    },
    {
      path: "/bookingadmin/:id",
      alias: "/bookingadmin",
      name: "bookingadmin",
      component: bookingadmin
    },
    {
      path: "/manageseats/:id",
      alias: "/manageseats",
      name: "manageseats",
      component: manageseats
    },    
    {
      path: "/concertremark/:id",
      alias: "/concertremark",
      name: "concertremark",
      component: concertremark
    },
    {
      path: "/managebooking/:id",
      alias: "/managebooking",
      name: "managebooking",
      component: managebooking
    },
    {
      path: "/concertclose/:id",
      alias: "/concertclose",
      name: "concertclose",
      component: concertclose
    },
    {
      path: "/closebooking/:id",
      alias: "/closebooking",
      name: "closebooking",
      component: closebooking
    },
    {
      path: "/updatenewseat",
      alias: "/updatenewseat",
      name: "updatenewseat",
      component: updatenewseat
    },
    {
      path: "/PromotionImg",
      alias: "/PromotionImg",
      name: "PromotionImg",
      component: PromotionImg
    },
    
  ]
});


router.beforeEach((to, from, next) => {
  var page = to.path.split("/")
  // console.log(page[1]);
  var qrcode = ''
  if (page[1] == 'qrcode' || page[1] == 'promotionlist' || page[1] == 'concertchartbanner' || page[1] == 'concertpublic' | page[1] == 'concertbanner' || page[1] == 'qrcodebook'|| page[1] ==  'updatenewseat' || page[1] == 'PromotionImg') {
    qrcode = to.path
  }
  const publicPages = ['/concertchart','/login',qrcode,'/book'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) { 
    var user = JSON.parse(loggedIn)
    if (user.role_id == 1){
      next('/bannerlist');
    }else{

      next('/confirmlist');
    }
    // next();
  } else {
    next();
  }

});

export default router;
