import http from "../http-common";

class TicketSeatService {
  createticketseat(data) {
    return http.post('/ticket_seats', data);
  }
  getticketseats(concert_id) {
    return http.get(`/ticket_seats?concert_id=`+concert_id);
  }  
  getticketseat(id) {
    return http.get(`/ticket_seats/`+id);
  }
  updateticketseat(id, data) {
    return http.put(`/ticket_seats/${id}`, data);
  }
 deleteticketseat(id) {
    return http.delete(`/ticket_seats/`+id);
  }
}

export default new TicketSeatService();