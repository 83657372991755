import http from "../http-common";

class RemarkService {
  createremark(data) {
    return http.post('/remark_concert', data);
  }
  getremarks() {
    return http.get(`/remark_concert`);
  }
  getremark(id) {
    return http.get(`/remark_concert/${id}`);
  }
  updateremark(id, data) {
    return http.put(`/remark_concert/${id}`, data);
  }
  deleteremark(id) {
    return http.delete(`/remark_concert/${id}`);
  }
}

export default new RemarkService();