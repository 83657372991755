<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0, '')"
          data-bs-toggle="modal"
          data-bs-target="#AddUser"
        >
          <i class="fa fa-plus"></i> เพิ่มสิทธิ์การใช้งาน
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">สิทธิ์การใช้งาน</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.name }}
          </td>
          <td>
            <a @click="getid(l.id, l.name)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddUser"
              >
                <i class="fa fa-edit"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              id="closeduser"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group">
                  <label for="username">ชื่อสิทธิ์การใช้งาน</label>
                  <input
                    v-model="user.name"
                    type="text"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>

                <label for="exampleFormControlSelect1" class="mt-5"
                  >จัดการเมนูใช้งาน</label
                >
                <div class="col-12" v-for="m in menus" :key="m.menu_id">
                  <div class="form-group mt-3" style="text-align: left">
                    <label>
                      <input
                        type="checkbox"
                        v-model="menu_id"
                        :id="m.page"
                        :value="m.id"
                      />
                      <span> {{ m.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "../services/UserDataService.js";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      menus: [],
      menu_id: [],
      role_type: "",
    };
  },
  mounted() {
    this.getRoles();
    this.getMenu();
  },
  methods: {
    getMenu() {
      UserDataService.getMenusAll().then((res) => {
        // console.log(res.data);
        this.menus = res.data;
      });
    },
    getid(id, name) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลสิทธิ์การใช้งาน";
        this.user.name = name;
        UserDataService.getMenubyRoleIDAll(this.user_id).then((res) => {
          if (res.data.menu == null) {
            this.menu_id = [];
          } else {
            this.menu_id = JSON.parse(res.data.menu);
          }
        });
      } else {
        this.title = "เพิ่มข้อมูลสิทธิ์การใช้งาน";
        this.user = [];
        this.menu_id = [];
      }
    },
    save() {
      // console.log(this.user);
      if (this.user.name == "") {
        alert("กรุณากรอกชื่อสิทธิ์การใช้งาน");
      } else {
        var userdata = {
          name: this.user.name,
        };
        if (this.user_id == 0) {
          UserDataService.createRole(userdata).then((res) => {
            this.user_id = res.data.id;
            for (let m = 0; m < this.menu_id.length; m++) {
              var menu = {
                menu_id: this.menu_id[m],
                role_id: this.user_id,
              };
              // console.log(menu);
              UserDataService.createRoleMenu(menu)
                .then(() => {
                  // console.log(response.data);
                })
                .catch((e) => {
                  console.log(e);
                });
              document.getElementById("closeduser").click();
              this.getRoles();
              this.getMenu();
            }
          });
        } else {
          UserDataService.updateRole(this.user_id, userdata).then((res) => {
            console.log(res.data);
          });
          UserDataService.deleteRoleMenu(this.user_id).then(() => {
            for (let m = 0; m < this.menu_id.length; m++) {
              var menu = {
                menu_id: this.menu_id[m],
                role_id: this.user_id,
              };
              // console.log(menu);
              UserDataService.createRoleMenu(menu)
                .then(() => {
                  // console.log(response.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 500);
            window.scrollTo(0, 0);
          });
        }
      }
    },
    getRoles() {
      UserDataService.getRolesAll().then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
    },
    signIn() {
      if (this.user.username == "" || this.user.username == null) {
        alert("กรุณากรอกชื่อผู้ใช้งาน");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else {
        var user = {
          username: this.user.username,
          password: this.user.password,
        };
        this.$store.dispatch("auth/login", user).then(
          () => {
            this.loading = true;
            const loggedIn = localStorage.getItem("user");
            var user = JSON.parse(loggedIn);
            if (user.role_id == 1) {
              this.$router.push("/bannerlist");
            } else {
              this.$router.push("/confirmlist");
            }
            setTimeout(function () {
              location.reload();
            }, 500);
          },
          (error) => {
            alert("ชื่อหรือรหัสผ่านไม่ถูกต้อง");
            console.log(error);
            // this.loading = false;
            // this.message =
            //   (error.response &&
            //     error.response.data &&
            //     error.response.data.message) ||
            //   error.message ||
            //   error.toString();
          }
        );
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
