<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="createseate()"
          data-bs-toggle="modal"
          data-bs-target="#AddUser"
        >
          <i class="fa fa-plus"></i> อัพเดตที่นั่ง
        </button>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="createmanageseats()"
          data-bs-toggle="modal"
          data-bs-target="#AddUser"
        >
          <i class="fa fa-plus"></i> อัพเดตที่นั่งของ VIP
        </button>
    </div>
    
  </div>
</template>

<script>
import SeatService from "../services/SeatService.js";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles:[]
    };
  },
  mounted() {
  },
  methods: {
    createmanageseats() {
         var data = {
            concert_id: 1,
            seat: 1,
            class: "badge",
            color: 'background-color:white',
            status: true,
          };
SeatService.createmanageseats(data).then((res)=>{
  console.log(res.data);
  alert('บันทึกสำเร็จ')
})
    },
    createseate() {
         var data = {
            concert_id: 1,
            seat: 1,
            class: "badge",
            color: 'background-color:white',
            status: true,
          };
SeatService.createseats(data).then((res)=>{
  console.log(res.data);
  alert('บันทึกสำเร็จ')
})
    },
   
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
