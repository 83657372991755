import http from "../http-common";

class MainBannerService {
  createmain_banner(data) {
    return http.post('/main_banner', data);
  }
  getmain_banners() {
    return http.get(`/main_banner`);
  }
  getmain_banner(id) {
    return http.get(`/main_banner/${id}`);
  }
  updatemain_banner(id, data) {
    return http.put(`/main_banner/${id}`, data);
  }
  deletemain_banner(id) {
    return http.delete(`/main_banner/${id}`);
  }
}

export default new MainBannerService();