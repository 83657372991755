import http from "../http-common";

class CloseBookingService {
  createclose_booking(data) {
    return http.post('/close_booking', data);
  }
  getclose_bookings() {
    return http.get(`/close_booking`);
  }
  getclose_booking(id) {
    return http.get(`/close_booking/${id}`);
  }
  updateclose_booking(id, data) {
    return http.put(`/close_booking/${id}`, data);
  }
  deleteclose_booking(id) {
    return http.delete(`/close_booking/${id}`);
  }
}

export default new CloseBookingService();