<template>
  <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout">
     <div class="row mt-3">
       <div class="col-md-6 mt-3">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">ชื่อคอนเสิร์ต</label>
          <input
            v-model="data.banner_name"
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="ชื่อคอนเสิร์ต"
          />
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <label for="exampleFormControlInput1" class="form-label">เลือกสี</label>
        <input
          type="color"
          class="form-control form-control-color"
          id="exampleColorInput"
          v-model="data.color"
          title="Choose your color"
        />
      </div>
       <div class="col-md-6 mt-3">
        <label for="exampleFormControlInput1" class="form-label">Font</label>
        <select
        @change="getfont()"
          v-model="data.font"
          class="form-select"
          aria-label="Default select example"
        >
          <option value="Georgia, serif">Georgia, serif</option>
          <option value="Arial">Arial</option>
            <option value="sans-serif">sans-serif</option>
              <option value="serif">serif</option>
                <option value="cursive">cursive</option>
                 <option value="Sans">Sans</option>
                  <option value="Gill Sans">Gill Sans</option>
                   <option value="Century Gothic">Century Gothic</option>
                    <option value="Franklin Gothic Medium">Franklin Gothic Medium</option>
                     <option value="Segoe UI">Segoe UI</option>
          
        </select>
      </div>
      <div class="col-md-6 mt-3">
        <label for="exampleFormControlInput1" class="form-label">ตัวอย่างข้อความจาก Font</label><br/>
      <div :style="data.style">{{data.banner_name}}</div>
      </div>
    </div>
    <div
      class="col-md-12 mt-3 mb-3"
      style="text-align: center"
    >
      <button type="button" class="btn btn-success" @click="save()">
        บันทึก
      </button>
    </div>
  </div>
</template>

<script>

import ConcertService from "../services/ConcertService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return { 
      list: [],
    concert_id:0 ,data:{}}
  },
  mounted() {
    // ConcertService.getconcerts().then((res)=>{
      // console.log(res.data);
      this.concert_id = this.$route.params.id
    this.getbanners();
    if( window.localStorage )
  {
    if( !localStorage.getItem('firstLoad') )
    {
      localStorage['firstLoad'] = true;
      window.location.reload();
    }  
    else
      localStorage.removeItem('firstLoad');
  }
    // });
  },
  methods: {
    getfont(){
this.data.style = 'font-family:'+this.data.font+';font-size:20px'
        // console.log(this.data.style);
    },
    getbanners() {
      ConcertService.getconcert(this.concert_id).then((res) => {
        this.data = res.data;
        this.data.style = 'font-family:'+this.data.font+';font-size:20px'
        // console.log(this.data.style);
      });
    },
    save(){
      var data= {
        banner_name:this.data.banner_name,
        font:this.data.font,
        color:this.data.color,
      }
      console.log(data);
      console.log(this.concert_id);
      ConcertService.updatebanner(this.concert_id,data).then(()=>{
        alert('บันทึกสำเร็จ')
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 60px;
  background: white;
}
.flex-container {
  display: flex;
}
.flex {
  display: inline-flex;
}
.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.badge {
  cursor: pointer;
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: black; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.selectbadge {
  cursor: pointer;
  margin: 8px;
  padding: 6px;
  width: 40px;
  height: 40px;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.activebadge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 8px;
  transition: color 0.15s;
  width: 40px;
  height: 40px !important;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.theatre {
  display: flex;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cinema-seats {
  display: flex;
}

.cinema-seats .seat {
  cursor: pointer;
}

.select {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #da1b21;
  border-radius: 7px;
}

/* .active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #545e57;
  border-radius: 7px;
} */
/* // Left Seats */
.left {
  margin-right: 100px;
}

.left .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.left .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}
/* 
// Right Seats */

.right .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}

.right .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.right .screen {
  width: 1000px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  text-align: center;
  border: 10px solid;
  top: 300px;
  right: -60px;
}
/* //button */
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  font-size: 15px;
  position: relative;
  top: 400px;
  right: 800px;
}
#my_file {
  display: none;
}
</style>
