import http from "../http-common";

class MainTimeService {
  createmanage_time(data) {
    return http.post('/manage_time', data);
  }
  getmanage_times() {
    return http.get(`/manage_time`);
  }
  getmanage_time(id) {
    return http.get(`/manage_time/${id}`);
  }
  updatemanage_time(id, data) {
    return http.put(`/manage_time/${id}`, data);
  }
  deletemanage_time(id) {
    return http.delete(`/manage_time/${id}`);
  }
}

export default new MainTimeService();