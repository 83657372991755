import http from "../http-common";

class BookingSeatService {
  createbooking(data) {
    return http.post('/booking_seat', data);
  }
  getbookings() {
    return http.get(`/booking_seat`);
  }
  getbooking(id) {
    return http.get(`/booking_seat/${id}`);
  }
  updatebooking(id, data) {
    return http.put(`/booking_seat/${id}`, data);
  }
  deletebooking(id) {
    return http.delete(`/booking_seat/${id}`);
  }
}

export default new BookingSeatService();