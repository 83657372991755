import http from "../http-common";

class BannertService {
  createbanner(data) {
    return http.post('/banners', data);
  }
  getbanners(concert_id) {
    return http.get(`/banners?concert_id=`+concert_id);
  }
  getbanner(id) {
    return http.get(`/banners/`+id);
  }
  getimage(name) {
    return http.get(`/img?name=`+name);
  }
  updatebanner(id, data) {
    return http.put(`/banners/${id}`, data);
  }
  updatebannerstatus(id, data) {
    return http.put(`/banners/updatestatus/${id}`, data);
  }
  deleteBanner(id) {
    return http.delete(`/banners/${id}`);
  }
}

export default new BannertService();