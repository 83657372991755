<template>
  <div class="container mt-5">
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">วันปิดจอง</th>
          <th scope="col">หมายเหตุวันปิดจอง</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            <div class="form-check">
  <input class="form-check-input" type="checkbox" v-model="l.checked" value="1" :id="l.nameth">
  <label class="form-check-label" :for="l.nameth">
    {{l.nameth}}
  </label>
</div>
            
          </td>
          <td>
            <textarea
              rows="3"
              v-model="l.remark"
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="หมายเหตุ"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>

  </div>
</template>
<script>
import MainBannerService from "../services/MainBannerService";
import CloseBookingService from "../services/CloseBookingService";

export default {
  name: "Nav",
  components: {
  },
  props: {
    msg: String,
  },
  data() {
    return {
      remarks:{},
      concert_id: 0,
      list: [],
      user: {},
      con_id: 0,
      hash: 0,
      title: "",
      roles:[],
      concert:[],
      data:{}
    };
  },
  mounted() {
    this.getconcert()
  },
  methods: {
    getconcert(){
      CloseBookingService.getclose_bookings().then((res) => {
  this.list =res.data 
  console.log(res.data);
 });
    },
    save() {
      for (let l = 0; l < this.list.length; l++) {
        var closebook = {
          checked : this.list[l].checked,
          remark :this.list[l].remark
        }
        CloseBookingService.updateclose_booking(this.list[l].id,closebook).then((res)=>{
          console.log(res.data);
         console.log(l,this.list.length);
          if (l+1 == this.list.length) {
            this.getconcert();
            alert('บันทึกสำเร็จ')
          }
        })
      }
    },
    getUsers() {
      MainBannerService.getmain_banners().then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
