import http from "../http-common";

class BookingService {
  createbooking(data) {
    return http.post('/booking', data);
  }
  getbookings() {
    return http.get(`/booking`);
  }
  
  getbookmore(date,numberpeople) {
    return http.get(`/booking/getbookmore?name=`+date+'&&numberpeople='+numberpeople);
  }
  getbookday(date,seat) {
    return http.get(`/booking/getbookday?name=`+date+'&&seat='+seat);
  }
  findbydateandseat(date,seat) {
    return http.get(`/booking/findbydateandseat?date=`+date+'&&seat='+seat);
  }
  getbooking(id) {
    return http.get(`/booking/${id}`);
  }
  updatebooking(id, data) {
    return http.put(`/booking/${id}`, data);
  }
  updatestatusbooking(id, data) {
    return http.put(`/booking/updatestatus/${id}`, data);
  }
  updatestatuscheckbooking(id, data) {
    return http.put(`/booking/updatestatuscheck/${id}`, data);
  }
  deletebooking(id) {
    return http.delete(`/booking/${id}`);
  }
}

export default new BookingService();