<template>
  <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout">
    <div class="row g-3">
  <div class="col-auto">
    <label for="inputPassword2" class="visually-hidden">เลขโต๊ะ</label>
    <input v-model="seatno" type="number" class="form-control" id="inputPassword2" placeholder="เลขโต๊ะ">
  </div>
  <div class="col-auto">
    <button type="submit" class="btn btn-success mb-3" @click="search()">ค้นหา</button>
  </div>
</div>
    <div class="col-12" align="right" style="display: none">
      <vue-excel-xlsx
        id="excel"
        :data="data"
        :columns="columns"
        :file-name="filename"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
      >
        Export Excel
      </vue-excel-xlsx>
    </div>
    <div class="col mb-3" style="text-align: right">
      <a @click="exportToPDF()">
        <button class="btn btn-primary">
          <i class="fa fa-file"></i> Export
        </button></a
      >
    </div>
    <div id="app" ref="document">
      <div style="font-size:16px" class="mb-3">คอนเสิร์ต {{concert.concert_name}}</div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">ชื่อลูกค้า</th>
            <th scope="col">เบอร์โทรศัพท์</th>
            <th scope="col">โซน</th>
            <th scope="col">เลขโต๊ะ</th>
            <th scope="col">หมายเหตุ</th>
            <th scope="col">คิวอาร์โค้ด</th>
             <th scope="col">ยืนยันโดย</th>
            <th scope="col" v-if="statuspdf"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in list" :key="i">
            <td style="width: 120px">
              <a style="color: black; text-decoration: none">{{ l.name }}</a>
            </td>
            <td style="width: 100px">
              <a style="color: black; text-decoration: none">{{ l.phone }}</a>
            </td>
            <td style="width: 70px">
              <a style="color: black; text-decoration: none">{{ l.zone || '-' }}</a>
            </td>
            <td style="width: 300px">
              <a style="color: black; text-decoration: none"> {{ l.seat }}</a>
            </td>
             <td style="width: 400px">
              <a style="color: black; text-decoration: none">{{
                l.remark || "-"
              }}</a>
            </td>
            <td style="width: 100px">
              <a style="color: black; text-decoration: none">
                <qr-code
                  style="display: inline !important; width: 100px !important"
                  :text="l.qrcode"
                  :size="150"
                ></qr-code
              ></a>
            </td>           
             <td style="width: 100px">
              <a style="color: black; text-decoration: none">{{
                l.user
              }}</a>
            </td>
            <td v-if="statuspdf" style="width: 130px">
              <router-link :to="'/confirm/' + concert_id + '/' + l.id" v-if="l.type == 'admin'">
                <button type="button" class="btn btn-warning">
                  <i class="fa fa-edit"></i></button
              ></router-link>
              <router-link :to="'/bookadmin/' + 17 + '/' + l.id" v-if="l.type == 'user'">
                <button type="button" class="btn btn-warning">
                  <i class="fa fa-edit"></i></button
              ></router-link>&nbsp;
              <a>
                <button
                @click="getid(l.id,l.type)"
                  data-bs-toggle="modal" data-bs-target="#exampleModal1"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a>
               
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ</h5>
            <button 
            id="closed" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from "../services/TicketService";
import ConcertService from "../services/ConcertService";
// import ZoneService from "../services/ZoneService";
import html2pdf from "html2pdf.js";
import SeatService from "../services/SeatService";
import TicketSeatService from "../services/TicketSeatService";
import BookingService from "../services/BookingService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      list: [],
      columns: [],
      data: [],
      filename: "",
      zone: "",
      seat: "",
      statuspdf: true,
      ticket_id:0,
      concert:'',
      seatno:'',
      type:''
    };
  },
  mounted() {
    this.concert_id = this.$route.params.id
    
        this.gettickets();
        this.getconcerts();
        if( window.localStorage )
  {
    if( !localStorage.getItem('firstLoad') )
    {
      localStorage['firstLoad'] = true;
      window.location.reload();
    }  
    else
      localStorage.removeItem('firstLoad');
  }
  },
  methods: {
    getconcerts(){
ConcertService.getconcert(this.concert_id).then((res)=>{
  this.concert = res.data
})
    }, 
    deletedata() {
      if (this.type == 'admin') {
        TicketSeatService.getticketseat(this.ticket_id).then((res) => {
                console.log(res.data);
              for (let r = 0; r < res.data.length; r++) {
                var s = {
                  class:"badge",
                  status:true
                };
                SeatService.updatestatusseat(res.data[r].id, s).then(() => {
                  // console.log(res.data);
                });
              }
              TicketService.deleteticket(this.ticket_id).then(() => {
                 document.getElementById("closed").click();
              setTimeout(function () {
          location.reload();
        }, 500);
              });
      });
    
      }else if (this.type == 'user') {
        BookingService.deletebooking(this.ticket_id).then(() => {
              TicketSeatService.getticketseat(this.ticket_id).then(() => {
              document.getElementById("closed").click();
             this.gettickets();
            });
          
              });
      }
      
    },
    getid(id,type) {
      this.ticket_id = id
      this.type = type
    },
    exportToPDF() {
      this.statuspdf = false;
      html2pdf(this.$refs.document, {
        margin: [1, 1.5, 0, 1.2],
        filename: this.concert.concert_name + ".pdf",
        jsPDF: { unit: "cm", format: "a4", orientation: "p" },
      });
      setTimeout(function () {
        location.reload();
      }, 1500);
      window.scrollTo(0, 0);
    },
    generatedata() {
      this.data = this.list;
      this.columns = [];
      this.columns.push(
        {
          label: "ชื่อลูกค้า",
          field: "name",
        },
        {
          label: "เบอร์โทรศัพท์",
          field: "phone",
        },
        {
          label: "โซน",
          field: "zone",
        },
        {
          label: "ที่นั่ง",
          field: "seat",
        },
        {
          label: "หมายเหตุ",
          field: "remark",
        },
        {
          label: "คิวอาร์โค้ด",
          field: "qrcode",
        }        ,
        {
          label: "ยืนยันโดย",
          field: "user",
        }
      );
      this.filename = "123";
    },
    generateexcel() {
      this.generatedata();
      document.getElementById("excel").click();
    },
    search(){
this.gettickets()
    },
    gettickets() {
      document.getElementById("excel").click();
      // console.log(this.seatno);
      TicketService.getticketsreport(this.concert_id,this.seatno).then((res) => {
        this.list = []
        this.list = res.data;
        console.log(this.list);
      });
    },
    getconcert() {
      ConcertService.getconcerts().then((res) => {
        // console.log(res.data);
        this.concert_id = res.data.id;
        this.gettickets();
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 60px;
  background: white;
}
.flex-container {
  display: flex;
}
.flex {
  display: inline-flex;
}
.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.badge {
  cursor: pointer;
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: black; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.selectbadge {
  cursor: pointer;
  margin: 8px;
  padding: 6px;
  width: 40px;
  height: 40px;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.activebadge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 8px;
  transition: color 0.15s;
  width: 40px;
  height: 40px !important;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.theatre {
  display: flex;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cinema-seats {
  display: flex;
}

.cinema-seats .seat {
  cursor: pointer;
}

.select {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #da1b21;
  border-radius: 7px;
}

/* .active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #545e57;
  border-radius: 7px;
} */
/* // Left Seats */
.left {
  margin-right: 100px;
}

.left .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.left .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}
/* 
// Right Seats */

.right .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}

.right .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.right .screen {
  width: 1000px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  text-align: center;
  border: 10px solid;
  top: 300px;
  right: -60px;
}
/* //button */
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  font-size: 15px;
  position: relative;
  top: 400px;
  right: 800px;
}
img {
  display: inline !important;
  width: 100px !important;
}
</style>
