<template>
  <div v-if="currentUser">
    <div class="vertical-menu" v-if="showmain">
      <div>
        <div
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
        <a href="/">
          <i
            class="fa fa-home"
            style="color: black; font-size: 30px"
            id="home"
            aria-hidden="true"
          ></i>
        </a>
        </div>
        <div
        v-for="me in menumain" :key="me.id"
          @click="book(me.url)"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          {{me.name}}
          <div>
          <router-link
            :to="'/confirm/' + c.id + '/' + '0'"
            class="vertical-left"
            style="background-color: wheat"
            v-for="(c, j) in me.concert"
            :key="j + 10"
          >
            <div @click="change(c.id)">
              >&nbsp;&nbsp;{{ c.concert_name }}
            </div></router-link
          >
        </div>
        </div>
        <!-- <div
          v-if="currentUser.role_id == 1"
          @click="create()"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          ดำเนินกิจกรรมใหม่
        </div>
        <div v-else>
          <router-link
            :to="'/bannerlist/' + c.id"
            class="vertical-left"
            v-for="(c, j) in concertlist"
            :key="j + 10"
          >
            <div @click="change(c.id)">
              {{ c.concert_name }}
            </div></router-link
          >
        </div> -->
      </div>
      <div>
        <!-- <div v-if="currentUser.role_id != 3">
          <router-link
            :to="'/confirm/' + c.id + '/' + '0'"
            class="vertical-left"
            style="background-color: wheat"
            v-for="(c, j) in concertlist"
            :key="j + 10"
          >
            <div @click="change(c.id)">
              >&nbsp;&nbsp;{{ c.concert_name }}
            </div></router-link
          >
        </div>
        <div v-else>
          <router-link
            :to="'/bannerlist/' + c.id"
            class="vertical-left"
            v-for="(c, j) in concertlist"
            :key="j + 10"
          >
            <div @click="change(c.id)">
              {{ c.concert_name }}
            </div></router-link
          >
        </div> -->
      </div>
      <div class="vertical-menu">
        <!-- <div
          v-if="currentUser.role_id == 1"
          @click="getusers()"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          จัดการผู้ใช้งาน
        </div>
        <div
          v-if="currentUser.role_id == 1"
          @click="getroles()"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          จัดการกลุ่มผู้ใช้งาน
        </div>
        <div
          v-if="currentUser.role_id == 1"
          @click="getmainconcert()"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          จัดการแบนเนอร์หน้าหลักเว็บไซต์
        </div>
        <div
          v-if="currentUser.role_id == 1"
          @click="gettimeconcert()"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          จัดการเวลาในการรีเซ็ตคอนเสิร์ตต่อวัน
        </div>
        <div
          v-if="currentUser.role_id == 1"
          @click="managetimebook()"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          จัดการเวลาในการยกเลิกการจองรายวัน
        </div>
        <div
          v-if="currentUser.role_id == 1"
          @click="getbookconcert()"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          จัดการเวลาตั้งเวลาการจองโต๊ะของลูกค้า
        </div>
        <div
          v-if="currentUser.role_id == 1"
          @click="getconcertall()"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
          "
        >
          คืนการยกเลิกคอนเสิร์ต
        </div> -->
        <div
          @click.prevent="logOut"
          href="#"
          style="
            color: blue;
            margin-left: 15px;
            padding-top: 15px;
            padding-bottom: 25px;
            cursor: pointer;
          "
        >
          ออกจากระบบ
        </div>
      </div>
    </div>
    <div v-else>
      <div class="vertical-menu">
        <router-link to="/concertbanner/0">
          <div @click="clickshowmain()">
            <i
              class="fa fa-home"
              style="color: black; font-size: 30px"
              id="home"
              aria-hidden="true"
            ></i></div
        ></router-link>
        <a></a>
        <a style="color:blue;font:weight:bold;font-size:16px">{{
          title.concert_name
        }}</a>
        <router-link
          :to="m.url"
          v-for="(m, i) in menu"
          :key="i"
          :class="m.class"
        >
          <div @click="clickmenu(m.id)">
            {{ m.name }}
          </div></router-link
        >
        <router-link to="/" v-if="currentUser.role_id == 1 && title.id != 17">
          <div
            @click="getid($route.params.id)"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            ยกเลิกคอนเสิร์ต
          </div></router-link
        >
        <!-- <a
          v-if="currentUser"
          :href="'/concertchart/' + $route.params.id"
          target="_blank"
          >ลูกค้า</a
        > -->
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                ยืนยันการยกเลิกคอนเสิร์ต
              </h5>
              <button
                id="closed"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mt-3">
                <label for="exampleFormControlInput1" class="form-label"
                  >ยืนยันรหัสผ่าน <span style="color: red">* </span></label
                >
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" @click="cancle()">
                ยืนยัน
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <table width="100%">
      <tr>
        <td colspan="2" style="color: white"></td>
      </tr>
      <tr>
        <td colspan="2" algin="left">
          <a href="/">
            <i
              class="fa fa-home"
              style="color: #ffffff; font-size: 30px; margin-top: 10px"
              id="home"
              aria-hidden="true"
              width="30"
              height="30"
            ></i> </a
          ><br />
          <hr />
        </td>
      </tr>
      <tr>
        <td colspan="2" algin="left">
          <a
            style="text-decoration: none; color: #ffffff"
            v-if="!currentUser"
            href="/login"
            >เข้าสู่ระบบ</a
          ><br />
          <hr style="color: white" />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <a
            style="text-decoration: none; color: #ffffff"
            v-if="!currentUser"
            href="/book"
            >จองที่นั่งล่วงหน้า</a
          ><br />
          <hr style="color: white" />
        </td>
      </tr>
      <tr>
        <td colspan="2" v-if="currentconcert.id == 17">
          <a
            style="text-decoration: none; color: #ffffff"
            v-if="!currentUser"
            :href="'/concertpublic/' + currentconcert.id"
            >ผังที่นั่งประจำวัน {{currentconcert.concert_name}}</a
          ><br />
          <hr style="color: white" />
        </td>
        <td colspan="2" v-else>
          <a
            style="text-decoration: none; color: #ffffff"
            v-if="!currentUser"
            :href="'/concertpublic/' + currentconcert.id"
            >{{ currentconcert.concert_name }}</a
          ><br />
          <hr style="color: white" />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <a
            style="text-decoration: none; color: #ffffff"
            v-if="!currentUser"
            href="/promotionlist"
            >โปรโมชัน</a
          ><br />
          <hr style="color: white" />
        </td>
      </tr>
      <tr>
        <td colspan="2" style="color: black">6</td>
      </tr>
      <tr>
        <td
          colspan="2"
          style="background-color: #f40592"
          height="40"
          width="100%"
        >
          Coming Soon
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <!-- <tr v-for="(c, j) in concertpublic" :key="j + 10">
              <td><img src="../assets/Ficon.jpg" /></td>
              <td bgcolor="#e45aac" height="30">
                <font>
                <a
                  style="text-decoration: none; color: white"
                  v-if="!currentUser"
                  color="#ffffff"
                  :href="'/concertchartbanner/' + c.id"
                  >{{ c.concert_name }}</a
                ></font>
              </td><br/>
            </tr> -->
    </table>
    <div
      class="row"
      style="margin-top: 5px"
      v-for="(c, j) in concertpublic"
      :key="j + 10"
    >
      <div class="col-md-1"><img src="../assets/Ficon.jpg" /></div>
      <div
        class="col-md-10"
        style="color: white; background-color: #e45aac; padding: 0px"
      >
        <a
          style="text-decoration: none; color: white; margin-top: 5px"
          v-if="!currentUser"
          color="#ffffff"
          :href="'/concertchartbanner/' + c.id"
          >{{ c.concert_name  }} 
          <!-- <br>{{ convertdate(c.date) }} -->
          </a
        >
      </div>
    </div>
    <!-- <div class="vertical-menu">
      <a v-if="!currentUser" href="/">หน้าหลัก</a>
    </div>
    <div class="vertical-menu">
      <a v-if="!currentUser" href="/login">เข้าสู่ระบบ</a>
    </div>
    <div class="vertical-menu-booking">
      <a v-if="!currentUser" href="/book">จองโต๊ะ</a>
    </div>
    <div class="vertical-menu" v-if="!statuscurrent">
      <a v-if="!currentUser" :href="'/concertpublic/' + currentconcert.id">{{
        currentconcert.concert_name
      }}</a>
    </div>
    <div class="vertical-menu">
      <a style="font-weight: bold; color: #eeeeee" href="#"> </a>
    </div>
    <div class="vertical-menu">
      <a style="font-weight: bold; color: #eeeeee" href="#"> </a>
    </div>
    <div class="vertical-menu-coming">
      <a style="font-weight: bold">Coming Soon</a>
    </div>
    <div
      class="vertical-menu-concert"
      v-for="(c, j) in concertpublic"
      :key="j + 10"
    >
      <a v-if="!currentUser" :href="'/concertchartbanner/' + c.id"
        ><img src="../assets/Ficon.jpg" /><span style="color: white">{{
          c.concert_name
        }}</span></a
      >
    </div> -->
  </div>
</template>
<script>
import ConcertService from "../services/ConcertService.js";
import UserDataService from "../services/UserDataService";
// import SeatService from "../services/SeatService";
import Menu from "./Menu.vue";
import MainBannerService from "../services/MainBannerService";
import MainTimeService from "../services/MainTimeService";
import TicketService from "../services/TicketService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  component: {
    Menu,
  },
  data() {
    return {
      concert_id: 0,
      menu: [],
      concert: [],
      showmain: true,
      title: {},
      password: "",
      concertpublic: [],
      currentconcert: {},
      bannerlist: [],
      statuscurrent: true,
      time: {},
      concertlist: [],
      menumain:[]
    };
  },
  mounted() {
    
    ConcertService.getconcertlist().then((res) => {
      // console.log(res.data);      
      this.concertlist = []
      this.concertlist = res.data
      if (this.currentUser) {
        
      
     UserDataService.getMenus(this.currentUser.role_id,1).then((res) => {

        for (let me = 0; me < res.data.length; me++) {
          // console.log(res.data[me].url);
          if (res.data[me].url == 'create') {
            if (this.currentUser.role_id == 1) {              
            this.menumain.push({
              id:res.data[me].id,
              name:res.data[me].name,
              url:res.data[me].url,
              concert:this.concertlist
            })
            }else{              
            this.menumain.push({
              id:res.data[me].id,
              name:'',
              url:'/',
              concert:this.concertlist
            })
            }
          }else{
            this.menumain.push({
              id:res.data[me].id,
              name:res.data[me].name,
              url:res.data[me].url,
            })
          }    
        }
    });
    }
    // console.log(this.menumain);
     });
    // console.log(this.isMobile());
    // console.log(screen.width);
    // console.log(screen.height);
    if( !localStorage.getItem('wx') )
    {
      if (this.isMobile() && (screen.width < screen.height)) {      
      localStorage.removeItem('wx');
      localStorage['wx'] = screen.height;
      alert('กรุณาหมุนโทรศัพท์ในแนวขวาง หรือแนวนอน เพื่อให้เว็บแสดงผลอย่างมีประสิทธิภาพ')
      // alert('wx'+localStorage['wx'])
    }
    }  

    //  localStorage.removeItem('wx');
    // if(!localStorage.getItem('wx') )
    // {
    // localStorage['wx'] = 2000;
    // console.log(1);
    // }
    // alert('wx'+localStorage['wx'])
    // alert('width'+document.body.offsetWidth)
    //  alert('height'+document.body.offsetHeight)
    // if (this.isMobile() && (screen.width < screen.height)) {      
    //   localStorage.removeItem('wx');
    //   localStorage['wx'] = screen.height;
    //   alert('กรุณาหมุนโทรศัพท์ในแนวขวาง หรือแนวนอน เพื่อให้เว็บแสดงผลอย่างมีประสิทธิภาพ')
    //   // alert('wx'+localStorage['wx'])
    // }
    this.gettime();
    this.showmain = true;
    
    this.concertpublic = []
    // console.log(this.showmain);
    
    ConcertService.getconcertpublic().then((res) => {
      this.concertpublic = res.data
      // console.log(res.data);
    });
    ConcertService.getconcert(17).then((res) => {
      this.currentconcert = res.data;
      ConcertService.getconcerts().then((res) => {
        for (let con = 0; con < res.data.length; con++) {
          if (res.data[con].date != null) {
            var conc = new Date(res.data[con].date);
            var ddcon = conc.getDate();
            var mmcon = conc.getMonth() + 1;
            var yycon = conc.getFullYear();
            if (String(ddcon).length == 1) {
              ddcon = "0" + ddcon;
            }
            if (String(mmcon).length == 1) {
              mmcon = "0" + mmcon;
            }
            var datecon = ddcon + "/" + mmcon + "/" + yycon;
            // console.log(datecon);
            // console.log(this.currentconcert.concert_name);
            if (this.currentconcert.concert_name == datecon) {
              // console.log(res.data[con]);
              this.currentconcert = {
                id: res.data[con].id,
                concert_name: res.data[con].concert_name,
                date: res.data[con].date,
              };
            }
          }
        }
      });
    });
    ConcertService.getconcert(17).then((res) => {
      this.currentconcert = res.data;
      // console.log(this.statuscurrent);
      if (this.statuscurrent == false) {
        // var statusmain = true;
        ConcertService.getconcerts().then((res) => {
          for (let con = 0; con < res.data.length; con++) {
            if (res.data[con].date != null) {
              var conc = new Date(res.data[con].date);
              var ddcon = conc.getDate();
              var mmcon = conc.getMonth() + 1;
              var yycon = conc.getFullYear();
              if (String(ddcon).length == 1) {
                ddcon = "0" + ddcon;
              }
              if (String(mmcon).length == 1) {
                mmcon = "0" + mmcon;
              }
              var datecon = ddcon + "/" + mmcon + "/" + yycon;
              // console.log(datecon);
              // console.log(this.currentconcert.concert_name);
              if (this.currentconcert.concert_name == datecon) {
                // console.log(res.data[con]);
                this.currentconcert = {
                  id: res.data[con].id,
                  concert_name: res.data[con].concert_name,
                  date: res.data[con].date,
                };
                // console.log(this.currentconcert);
                // statusmain = false;
              }
            }
          }
          // console.log(this.currentconcert);
          if (this.$route.fullPath != "/") {
            this.$router.push(this.$route.fullPath);
          } else {
            this.$router.push("/concertpublic/" + this.currentconcert.id);
          }
          ConcertService.getconcerts().then((res) => {
            // console.log(res.data);

            this.concert = res.data;
            // console.log(this.currentconcert);
            // for (let cc = 0; cc < this.concert.length; cc++) {
            //   if (this.concert[cc].id != this.currentconcert.id) {
            //     this.concertpublic.push(this.concert[cc]);
            //   }
            // }
            // if (statusmain == false) {
            //   for (let cc = 0; cc < this.concertpublic.length; cc++) {
            //     if (this.concertpublic[cc].id == 17) {
            //       this.concertpublic.splice(cc, 1);
            //     }
            //   }
            // }
          });
        });
      } else {
        ConcertService.getconcert(17).then((res) => {
          this.currentconcert = res.data;
          ConcertService.getconcerts().then((res) => {
            // console.log(res.data);
            this.concert = res.data;
            for (let con = 0; con < res.data.length; con++) {
              if (res.data[con].date != null) {
                var conc = new Date(res.data[con].date);
                var ddcon = conc.getDate();
                var mmcon = conc.getMonth() + 1;
                var yycon = conc.getFullYear();
                if (String(ddcon).length == 1) {
                  ddcon = "0" + ddcon;
                }
                if (String(mmcon).length == 1) {
                  mmcon = "0" + mmcon;
                }
                var datecon = ddcon + "/" + mmcon + "/" + yycon;
                // console.log(datecon);
                // console.log(this.currentconcert.concert_name);
                if (this.currentconcert.concert_name == datecon) {
                  // console.log(res.data[con]);
                  this.currentconcert = {
                    id: res.data[con].id,
                    concert_name: res.data[con].concert_name,
                    date: res.data[con].date,
                  };
                  // console.log(this.currentconcert);
                }
              }
            }
            // for (let cc = 0; cc < this.concert.length; cc++) {
            //   if (
            //     this.concert[cc].id != 17 &&
            //     this.concert[cc].id != this.currentconcert.id
            //   ) {
            //     this.concertpublic.push(this.concert[cc]);
            //   }
            // }
          });
        });

        if (this.$route.fullPath != "/") {
          this.$router.push(this.$route.fullPath);
        } else {
          this.$router.push('/concertbanner/'+0)
          // this.$router.push(/z);
        }
      }
    });
    // console.log(this.$route.name);
    if (this.$route.name == null || this.$route.name == "users") {
      ConcertService.getconcerts().then((res) => {
        // console.log(res.data);
        this.concert = res.data;
        if (this.currentUser) {
          this.menu.push({
            id: 2,
            name: "ดำเนินกิจกรรมใหม่",
            class: "",
            url: "/create",
          });
        }
      });
    } else if (this.$route.name == "concertbanner" || this.$route.name =="bookinglist"|| this.$route.name =="bookadmin" || this.$route.name =="managebooking" || this.$route.name == "concertclose") {
      this.showmain = true;
    } else {
      this.showmain = false;
      this.menu = [];
      ConcertService.getconcert(this.$route.params.id).then((res) => {
        // console.log(res.data);
        this.title = res.data;
        if (this.currentUser) {
          UserDataService.getMenus(this.currentUser.role_id,2).then((res) => {
            // this.menu.push({
            //   id: 1,
            //   name: "หน้าหลัก",
            //   class: "",
            //   url: "/",
            // });

            for (let m = 0; m < res.data.length; m++) {
              // console.log(m);
              var classs = "";
              if (this.$route.name == "report") {
                if (res.data[m].url == "/report") {
                  classs = "active";
                }
              } else if (this.$route.name == "bannerlist") {
                if (res.data[m].url == "/bannerlist") {
                  classs = "active";
                }
              } else if (this.$route.name == "zone") {
                if (res.data[m].url == "/zone") {
                  classs = "active";
                }
              } else if (this.$route.name == "confirm") {
                if (res.data[m].url == "/confirm") {
                  classs = "active";
                }
              } else if (this.$route.name == "bookingadmin") {
                if (res.data[m].url == "/confirm") {
                  classs = "active";
                }
              } else if (this.$route.name == "users") {
                if (res.data[m].url == "/users") {
                  classs = "active";
                }
              } else if (this.$route.name == "concertchartadmin") {
                if (res.data[m].url == "/concertchartadmin") {
                  classs = "active";
                }
              } else if (this.$route.name == "headerconcert") {
                if (res.data[m].url == "/headerconcert") {
                  classs = "active";
                }
              } else if (this.$route.name == "timeconcert") {
                if (res.data[m].url == "/timeconcert") {
                  classs = "active";
                }
              } else if (this.$route.name == "confirmseat") {
                if (res.data[m].url == "/confirmseat") {
                  classs = "active";
                }
              } else if (this.$route.name == "cancleconfirmseat") {
                if (res.data[m].url == "/cancleconfirmseat") {
                  classs = "active";
                }
              }else if (this.$route.name == "concertchartbanner") {
                if (res.data[m].url == "/concertchartbanner") {
                  classs = "active";
                }
              }

              // else if (this.$route.name == "concert") {
              //   if (res.data[m].url == "/concert") {
              //     classs = "active";
              //   }
              // }
              var link = res.data[m].url;
              if (res.data[m].url == "/confirm") {
                link += "/" + this.$route.params.id + "/0";
              } else {
                link += "/" + this.$route.params.id;
              }
              this.menu.push({
                id: res.data[m].id,
                name: res.data[m].name,
                url: link,
                class: classs,
              });
            }
          });
          // console.log(this.menu);
        }
      });
    }
  },
  methods: {
    convertdate(date){
      var res = ''
      if (date) {
        var d = date.split('-')
var dd = d[2].split('T')
// console.log(dd);
res = dd[0] + '/'+d[1]+ '/'+d[0]
      }
return res
    },
    isMobile() {
      // console.log(navigator);
   if(/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
 },
    gettime() {
      // console.log(this.$route);
      // if (this.$route.name == "/login") {
      //   this.$router.push(this.$route.fullPath);
      // } else {
      MainTimeService.getmanage_times().then((res) => {
        this.time = res.data[0];
        var hour = new Date().getHours();
        // var hour = 6
        var start_time = this.time.start_time.split(":");
        var end_time = this.time.end_time.split(":");
        start_time = parseInt(start_time[0]);
        end_time = parseInt(end_time[0]);
        // hour = parseInt('19')
        // console.log(hour);
        // console.log(start_time);
        // console.log(end_time);
        if (hour < start_time && hour < end_time && hour > 5) {
          // console.log('show');
          this.statuscurrent = true;
          var current = new Date();
          var ddcurrent = current.getDate();
          var mmcurrent = current.getMonth() + 1;
          var yycurrent = current.getFullYear();
          if (String(ddcurrent).length == 1) {
            ddcurrent = "0" + ddcurrent;
          }
          if (String(mmcurrent).length == 1) {
            mmcurrent = "0" + mmcurrent;
          }
          var datecurrent = ddcurrent + "/" + mmcurrent + "/" + yycurrent;
          
          ConcertService.getconcert(17).then((res) => {
          //   console.log(datecurrent);
          // console.log(res.data.concert_name);
            if (res.data.concert_name != datecurrent) {
              var cons = {
                concert_name: datecurrent,
                // date:yycurrent + "-" +mmcurrent+ "-" +ddcurrent
              };
              // console.log(cons);
              ConcertService.updateconcert(17, cons).then(() => {
                // console.log(res.data);
                var c = {
                  class:"strong",
                  status:true
                };
                ConcertService.updatestatusseat(17, c).then(() => {
                  // console.log(res.data);
                  TicketService.updateticketbyconcertid(17, c).then(() => {
                    ConcertService.getconcert(17).then((res) => {
                      this.currentconcert = res.data;
                    });
                  });
                });
              });
            }
          });
        } else {
          // console.log('noshow');
          this.statuscurrent = false;
          // this.$router.push("/concertpublic/" + this.currentconcert.id);
        }
        // console.log(this.statuscurrent);
        // if (this.$route.path == "/") {
        //   if (this.statuscurrent == false) {
        //       this.$router.push('/concertbanner/'+0)
        //   }else{
        //   this.$router.push("/concertpublic/" + 17);
        //   }
        // }
      });
      // }
    },
    getMainBannerService() {
      MainBannerService.getmain_banners().then((res) => {
        this.bannerlist = res.data;
      });
    },
    getid(id) {
      this.concert_id = id;
      // console.log(this.concert_id);
    },
    getconcertall() {
      this.$router.push("/concertall/0");
    },
    getusers() {
      this.$router.push("/users/0");
    },
    getroles() {
      this.$router.push("/menus/0");
    },
    getmainconcert() {
      this.$router.push("/concertmainlist/0");
    },
    book(url) {
      if (url == 'create') {
        this.create()
      }else{
      this.$router.push(url);
      }
    },
    managetimebook() {
      this.$router.push("/managetimebook/0");
    },
    getbookconcert() {
      this.$router.push("/managetimebookend/0");
    },
    gettimeconcert() {
      this.$router.push("/managetime/0");
    },
    cancle() {
      if (this.password == "1234") {
        ConcertService.deleteconcert(this.concert_id).then(() => {
          // console.log(res.data);
          this.$router.push("/");
          if (this.showmain == false) {
            this.showmain = true;
          }
          ConcertService.getconcerts().then((res) => {
            // console.log(res.data);
            this.concert = res.data;
            if (this.currentUser) {
              this.menu.push({
                id: 2,
                name: "ดำเนินกิจกรรมใหม่",
                class: "",
                url: "/create",
              });
            }
          });
          setTimeout(function () {
            location.reload();
          }, 500);
          window.scrollTo(0, 0);
        });
      } else {
        alert("รหัสผ่านไม่ถูกต้อง");
      }
    },
    change(id) {
      console.log(id);
      this.showmain = false;
      this.concert_id = id;
      this.menu = [];
      this.getmenu();
      ConcertService.getconcert(this.concert_id).then((res) => {
        // console.log(res.data);
        this.title = res.data;
      });
      ConcertService.getconcerts().then((res) => {
        // console.log(res.data);
        this.concert = res.data;
      });
      // setTimeout(function () {
      //   location.reload();
      // }, 500);
      // window.scrollTo(0, 0);
    },

    create() {
      // var concert = { status: true };
      // var conid = "";
      // ConcertService.createconcert(concert).then((res) => {
      //   conid = res.data.id;
      //   var no = 0;
      //   for (let c = 0; c < 50; c++) {
      //     no = c + 1;
      //     var color = "background-color:black";
      //     var data = {
      //       concert_id: conid,
      //       seat: no,
      //       class: "badge",
      //       color: color,
      //       status: true,
      //     };
      //     SeatService.createseat(data).then(() => {
      //       // console.log(res.data);
      //     });
      //   }
      // this.$router.push("/zone/" + conid);
      // });
      this.$router.push("/zone/" + 0);
    },
    activeconfirm() {
      // console.log(this.$route.name);
      if (this.$route.name == "confirm") {
        for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = "";
          if (this.menu[m].id == 4) {
            this.menu[m].class = "active";
          }
        }
      } else {
        if (this.$route.name == "zone") {
          for (let m = 0; m < this.menu.length; m++) {
            this.menu[m].class = "";
            if (this.menu[m].id == 3) {
              this.menu[m].class = "active";
            }
          }
        }
      }
      // console.log(this.menu);
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      setTimeout(function () {
        location.reload();
        this.$router.push("/");
      }, 500);
    },
    getmenu() {
      // this.menu.push({
      //   id: 1,
      //   name: "หน้าหลัก",
      //   class: "",
      //   url: "/",
      // });
      if (this.currentUser) {
        UserDataService.getMenus(this.currentUser.role_id,2).then((res) => {
          console.log(res.data);
          // this.menu = res.data
          for (let m = 0; m < res.data.length; m++) {
            // console.log(m);
            var classs = "";
            if (m == 0) {
              classs = "active";
            }
            var link = res.data[m].url;
            if (res.data[m].url == "/confirm") {
              link += "/" + this.concert_id + "/0";
            } else {
              link += "/" + this.concert_id;
            }
            this.menu.push({
              id: res.data[m].id,
              name: res.data[m].name,
              url: link,
              class: classs,
            });
          }
          // console.log(this.menu);
          this.activeconfirm();
        });
      }
    },
    clickshowmain() {
      if (this.showmain == false) {
        this.showmain = true;
      }
    },
    clickmenu(id) {
      if (id == 12) {
        this.$router.push("/concertfront/" + this.$route.params.id);
        location.reload();
      }
      console.log(id);
      if (id == 1) {
        for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = "";
          if (this.menu[m].id == 3) {
            this.menu[m].class = "active";
          }
        }
      }if (id == 4 &&  this.$route.params.id == 17) {
        this.$router.push("/bookingadmin/" + this.$route.params.id);
      } else {
        setTimeout(function () {
          location.reload();
              }, 500);
              window.scrollTo(0, 0);
        for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = "";
          if (this.menu[m].id == id) {
            this.menu[m].class = "active";
          }
        }
      }
    },
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.vertical-menu-concert {
  background-color: #e45aac;
}

.vertical-menu-concert a {
  background-color: #e45aac;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu-concert a.active {
  background-color: #e45aac;
  color: white;
}

.vertical-menu-booking {
  background-color: #70d6d9;
}
.vertical-menu-booking a {
  background-color: #70d6d9;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu-booking a.active {
  background-color: #04aa6d;
  color: white;
}
.vertical-menu-coming {
  background-color: #f40592;
}

.vertical-menu-coming a {
  background-color: #f40592;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-left {
  margin-left: 0px;
}
.title {
  color: blue;
}
</style>
