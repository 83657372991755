<template>
  <div ref="printMap" class="mt-3" id="my-node" :name="concert_name" style="margin-bottom:50px;width:100%">
    <img :src="banner" style="width: 100%" />
    <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout mb-5" style="margin: auto;">
      <div>
         <div
        class="row mt-3"
        v-if="seats.length > 0"
        style="padding: 0px; margin: 0px"
      >
         <div class="col-sm-12">
          <div class="row">
            <div 
              class="col-sm-1 col1banner"
              style="border-right: 8px solid black"
            ></div>
             <div class="col-sm-11 banner" style="padding: 0px;background:black">
              <br/><img
                src="../assets/banner.png"
                style="width: 100%; text-align: center"
              />
            </div>
            <div
              class="col-sm-1 bannerright" style="background-color:black;text-align:center"
            > <div class="margintopbanner" :style="style">{{title.banner_name}}
</div></div>
          </div>
        </div>
        <div class="col-sm-1 col1banner" style="border-right: 8px solid black">
          <div class="fireexitleftone">
            <img src="../assets/fireexitleft.png" class="sizefireexitleft" />
          </div>
          <div class="fireexitlefttwo">
            <img src="../assets/fireexitleft.png" class="sizefireexitleft" />
          </div>
          <div class="toiletstyle">
            <div class="toiletmen">
              <i class="fa fa-male" id="famen" aria-hidden="true"></i><br />

              <span class="toilettext">Men</span>
            </div>
            <div class="verticaltoilet">Toilet</div>
            <div class="toiletwomen">
              <i class="fa fa-female" id="famen" aria-hidden="true"></i><br />

              <span class="toilettext">Women</span>
            </div>
          </div>
        </div>
        <div
          class="col-sm-8 col8width"
          style="
            text-align: center;
            background-color: #fefefe;
            border-top: 8px solid black;
            border-bottom: 8px solid black;
          "
        >
          <div class="row">
            <div
              style="
                width: 50%;
                background-color: black;
                height: 9px;
                margin: auto;
                margin-top: -9px;
              "
            ></div>
          </div>
          <div class="row heightrow">
            <div class="col-sm-12" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    :style="'text-align: center;'+r1.background"
                    class="flex"
                    v-for="r1 in row1"
                    :key="r1.id"
                  >              
                    <td
                      v-if="r1.status"
                      :class="r1.class"
                      :style="r1.color"
                      :id="r1.seat"                     
                    >                     
                    {{ r1.seat }}
                    </td>
                    <td
                      v-else
                      :class="r1.class"
                      style="background-color: red"
                      :id="r1.seat"
                       
                    >                    
                      {{ r1.seat }}
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="stage" style="margin-top: 0px">STAGE</td>
                  </tr>
                  <tr
                    :style="'text-align: center;'+r1p2.background"
                    class="flex"
                    v-for="r1p2 in row1part2"
                    :key="r1p2.id"
                  >
                    <td
                      v-if="r1p2.status"
                      :class="r1p2.class"
                      :style="r1p2.color"
                      :id="r1p2.seat"
                      
                    >                    
                      {{ r1p2.seat }}
                    </td>
                    <td
                      v-else
                      :class="r1p2.class"
                      style="background-color: red"
                      :id="r1p2.seat"
                       
                    >                  
                      {{ r1p2.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row heightrow">
            <div class="col-sm-12" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr
                  :style="'text-align: center;'+r2.background"
                    class="flex"
                    v-for="r2 in row2"
                    :key="r2.id"
                  >
                    <td
                      v-if="r2.status"
                      :class="r2.class"
                      :style="r2.color"
                      :id="r2.seat"
                      
                    >                    
                      {{ r2.seat }}
                    </td>
                    <td
                      v-else
                      :class="r2.class"
                      style="background-color: red"
                      :id="r2.seat"
                       
                    >                    
                      {{ r2.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row heightrow">
            <div class="col-sm-12" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr
                  :style="'text-align: center;'+r3.background"
                    class="flex"
                    v-for="r3 in row3"
                    :key="r3.id"
                  >
                    <td
                      v-if="r3.status"
                      :class="r3.class"
                      :style="r3.color"
                      :id="r3.seat"
                      
                    >                    
                      {{ r3.seat }}
                    </td>
                    <td
                      v-else
                      :class="r3.class"
                      style="background-color: red"
                      :id="r3.seat"
                       
                    >                    
                      {{ r3.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row heightrow">
            <div class="col-sm-12" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr
                   :style="'text-align: center;'+r4.background"
                    class="flex"
                    v-for="r4 in row4"
                    :key="r4.id"
                  >
                    <td
                      v-if="r4.status"
                      :class="r4.class"
                      :style="r4.color"
                      :id="r4.seat"
                      
                    >                    
                      {{ r4.seat }}
                    </td>
                    <td
                      v-else
                      :class="r4.class"
                      style="background-color: red"
                      :id="r4.seat"
                       
                    >                    
                      {{ r4.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row heightrow">
            <div class="col-sm-12" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    :style="'text-align: center;'+r5.background"
                    class="flex"
                    v-for="r5 in row5"
                    :key="r5.id"
                  >
                    <td
                      v-if="r5.status"
                      :class="r5.class"
                      :style="r5.color"
                      :id="r5.seat"
                      
                    >                    
                      {{ r5.seat }}
                    </td>
                    <td
                      v-else
                      :class="r5.class"
                      style="background-color: red"
                      :id="r5.seat"
                      
                    >                    
                      {{ r5.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row colbar6 heightrow60">
            <div class="col-sm-12" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    :style="'text-align: center;'+r6p1.background"
                    class="flex"
                    v-for="r6p1 in row6part1"
                    :key="r6p1.id"
                  >
                    <td
                      v-if="r6p1.status"
                      :class="r6p1.class"
                      :style="r6p1.color"
                      :id="r6p1.seat"
                      
                    >                     
                      {{ r6p1.seat }}
                    </td>
                    <td
                      v-else
                      :class="r6p1.class"
                      style="background-color: red"
                      :id="r6p1.seat"
                       
                    >                     
                      {{ r6p1.seat }}
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="bar" style="background-color: #5e9cd9">เสา</td>
                  </tr>
                  <tr
                    :style="'text-align: center;'+r6p2.background"
                    class="flex"
                    v-for="r6p2 in row6part2"
                    :key="r6p2.id"
                  >
                    <td
                      v-if="r6p2.status"
                      :class="r6p2.class"
                      :style="r6p2.color"
                      :id="r6p2.seat"
                      
                    >                    
                      {{ r6p2.seat }}
                    </td>
                    <td
                      v-else
                      :class="r6p2.class"
                      style="background-color: red"
                      :id="r6p2.seat"
                      
                    >                    
                      {{ r6p2.seat }}
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="bar" style="background-color: #5e9cd9">เสา</td>
                  </tr>
                  <tr
                  :style="'text-align: center;'+r6p3.background"
                    class="flex"
                    v-for="r6p3 in row6part3"
                    :key="r6p3.id"
                  >
                    <td
                      v-if="r6p3.status"
                      :class="r6p3.class"
                      :style="r6p3.color"
                      :id="r6p3.seat"
                      
                    >                    
                      {{ r6p3.seat }}
                    </td>
                    <td
                      v-else
                      :class="r6p3.class"
                      style="background-color: red"
                      :id="r6p3.seat"
                       
                    >                    
                      {{ r6p3.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row col10 heightrow60">
            <div class="col-sm-10" style="text-align: left">
              <table class="table table-borderless">
                <tbody>
                  <tr style="text-align: center" class="flex">
                    <td class="badge" style="background-color: transparent">
                      6
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="badge" style="background-color: transparent">
                      12
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="bar" style="background-color: transparent">
                      เสา
                    </td>
                  </tr>
                  <tr
                    :style="'text-align: center;'+r7.background"
                    class="flex"
                    v-for="r7 in row7"
                    :key="r7.id"
                  >
                    <td
                      v-if="r7.status"
                      :class="r7.class"
                      :style="r7.color"
                      :id="r7.seat"
                      
                    >                    
                      {{ r7.seat }}
                    </td>
                    <td
                      v-else
                      :class="r7.class"
                      style="background-color: red"
                      :id="r7.seat"
                      
                    >                    
                      {{ r7.seat }}
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="bar" style="background-color: transparent">
                      เสา
                    </td>
                  </tr>
                  <tr style="text-align: center" class="flex">
                    <td class="badge" style="background-color: transparent">
                      66
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-sm-1 float-right">
              <div class="colstair1">
                <div class="a"></div>
                <div class="b">
                  <span class="stair" style="margin-left: 3px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                </div>
                <div class="c"></div>
              </div>
              <tr
                :style="'text-align: center;'+r6p4.background"
                class="flex col73"
                v-for="r6p4 in row6part4"
                :key="r6p4.id"
              >
                <td
                  v-if="r6p4.status"
                  :class="r6p4.class"
                  :style="r6p4.color"
                  :id="r6p4.seat"
                  
                >                 
                  {{ r6p4.seat }}
                </td>
                <td
                  v-else
                  :class="r6p4.class"
                  style="background-color: red"
                  :id="r6p4.seat"
                  
                >                 
                  {{ r6p4.seat }}
                </td>
              </tr>
              <div class="stair73">
                <div class="a"></div>
                <div class="b">
                  <span class="stair" style="margin-left: 3px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                </div>
                <div class="c"></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-11" style="padding: 0px">
              <div
                class="fontsizefootpath"
                style="
                  background-color: black;
                  color: #f7fa11;
                  font-weight: bold;
                "
              >
                ทางเดิน
              </div>
            </div>
            <div class="col-sm-1" style="border-left: 8px solid black"></div>
          </div>
          <div class="row">
            <div class="col-sm-11">
              <div class="row">
                <div
                  class="col-sm-12 heightrow"
                  style="width: 100%; text-align: left"
                >
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        :style="'text-align: center;'+r8.background"
                        class="flex"
                        v-for="r8 in row8"
                        :key="r8.id"
                      >
                        <td
                          v-if="r8.status"
                          :class="r8.class"
                          :style="r8.color"
                          :id="r8.seat"
                          
                        >                        
                          {{ r8.seat }}
                        </td>
                        <td
                          v-else
                          :class="r8.class"
                          style="background-color: red"
                          :id="r8.seat"
                           
                        >                        
                          {{ r8.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="col-sm-3"
                  style="text-align: left; padding-right: 0px; width: 25%"
                >
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        :style="'text-align: center;'+r9p1.background"
                        class="flex"
                        v-for="r9p1 in row9part1"
                        :key="r9p1.id"
                      >
                        <td
                          v-if="r9p1.status"
                          :class="r9p1.class"
                          :style="r9p1.color"
                          :id="r9p1.seat"
                          
                        >                        
                          {{ r9p1.seat }}
                        </td>
                        <td
                          v-else
                          :class="r9p1.class"
                          style="background-color: red"
                          :id="r9p1.seat"
                          
                        >                        
                          {{ r9p1.seat }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr
                        :style="'text-align: center;'+r10p1.background"
                        class="flex"
                        v-for="r10p1 in row10part1"
                        :key="r10p1.id"
                      >
                        <td
                          v-if="r10p1.status"
                          :class="r10p1.class"
                          :style="r10p1.color"
                          :id="r10p1.seat"
                          
                        >                        
                          {{ r10p1.seat }}
                        </td>
                        <td
                          v-else
                          :class="r10p1.class"
                          style="background-color: red"
                          :id="r10p1.seat"
                          
                        >                        
                          {{ r10p1.seat }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr
                       :style="'text-align: center;'+r11p1.background"
                        class="flex col77"
                        v-for="r11p1 in row11part1"
                        :key="r11p1.id"
                      >
                        <td
                          v-if="r11p1.status"
                          :class="r11p1.class"
                          :style="r11p1.color"
                          :id="r11p1.seat"
                          
                        >                        
                          {{ r11p1.seat }}
                        </td>
                        <td
                          v-else
                          :class="r11p1.class"
                          style="background-color: red"
                          :id="r11p1.seat"
                           
                        >                        
                          {{ r11p1.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="verticalbar">เสา</div>
                    </div>
                    <div
                      class="col-sm-6 margintop30"
                      style="text-align: right; padding-right: 10px"
                    >
                      <div class="stair154">
                        <div class="a"></div>
                        <div class="b">
                          <span class="stair" style="margin-left: 2px"></span>
                          <span class="stair" style="margin-left: 1.5px"></span>
                          <span class="stair" style="margin-left: 1.5px"></span>
                        </div>
                        <div class="c"></div>
                      </div>
                      <table class="table table-borderless colrow14">
                        <tbody v-for="r14 in row14" :key="r14.id">
                          <tr :style="'text-align: center;'+r14.background" class="flex">
                            <td
                              v-if="r14.status"
                              :class="r14.class"
                              :style="r14.color"
                              :id="r14.seat"
                              
                            >                            
                              {{ r14.seat }}
                            </td>
                            <td
                              v-else
                              :class="r14.class"
                              style="background-color: red"
                              :id="r14.seat"
                              
                            >                            
                              {{ r14.seat }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  class="col-sm-7 col7border"
                  style="text-align: left; padding-left: 0px"
                >
                  <div
                    style="border-style: solid; width: 100%"
                    class="borderheight"
                  >
                    <table class="table table-borderless">
                      <tbody>
                        <tr
                          :style="'text-align: center;'+r9p2.background"
                          class="flex"
                          v-for="r9p2 in row9part2"
                          :key="r9p2.id"
                        >
                          <td
                            v-if="r9p2.status"
                            :class="r9p2.class"
                            :style="r9p2.color"
                            :id="r9p2.seat"
                            
                          >                          
                            {{ r9p2.seat }}
                          </td>
                          <td
                            v-else
                            :class="r9p2.class"
                            style="background-color: red"
                            :id="r9p2.seat"
                            
                          >                          
                            {{ r9p2.seat }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr
                          :style="'text-align: center;'+r10p2.background"
                          class="flex"
                          v-for="r10p2 in row10part2"
                          :key="r10p2.id"
                        >
                          <td
                            v-if="r10p2.status"
                            :class="r10p2.class"
                            :style="r10p2.color"
                            :id="r10p2.seat"
                            
                          >                          
                            {{ r10p2.seat }}
                          </td>
                          <td
                            v-else
                            :class="r10p2.class"
                            style="background-color: red"
                            :id="r10p2.seat"
                             
                          >                          
                            {{ r10p2.seat }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr
                          :style="'text-align: center;'+r11p2.background"
                          class="flex"
                          v-for="r11p2 in row11part2"
                          :key="r11p2.id"
                        >
                          <td
                            v-if="r11p2.status"
                            :class="r11p2.class"
                            :style="r11p2.color"
                            :id="r11p2.seat"
                            
                          >                          
                            {{ r11p2.seat }}
                          </td>
                          <td
                            v-else
                            :class="r11p2.class"
                            style="background-color: red"
                            :id="r11p2.seat"
                            
                          >                          
                            {{ r11p2.seat }}
                          </td>
                        </tr>
                      </tbody>
                      <div class="row">
                        <div class="col-sm-3 col3dj" style="padding-right: 0px">
                          <tbody>
                            <tr
                              :style="'text-align: center;'+r12.background"
                              class="flex"
                              v-for="r12 in row12"
                              :key="r12.id"
                            >
                              <td
                                v-if="r12.status"
                                :class="r12.class"
                                :style="r12.color"
                                :id="r12.seat"
                                
                              >                              
                                {{ r12.seat }}
                              </td>
                              <td
                                v-else
                                :class="r12.class"
                                style="background-color: red"
                                :id="r12.seat"
                                 
                              >                              
                                {{ r12.seat }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr
                               :style="'text-align: center;'+r13p1.background"
                              class="flex"
                              v-for="r13p1 in row13part1"
                              :key="r13p1.id"
                            >
                              <td
                                v-if="r13p1.status"
                                :class="r13p1.class"
                                :style="r13p1.color"
                                :id="r13p1.seat"
                                
                              >                              
                                {{ r13p1.seat }}
                              </td>
                              <td
                                v-else
                                :class="r13p1.class"
                                style="background-color: red"
                                :id="r13p1.seat"
                                
                              >                              
                                {{ r13p1.seat }}
                              </td>
                            </tr>
                            <tr
                              :style="'text-align: center;'+r13p11.background"
                              class="flex"
                              v-for="r13p11 in row13part11"
                              :key="r13p11.id"
                            >
                              <td
                                v-if="r13p11.status"
                                :class="r13p11.class"
                                :style="r13p11.color"
                                :id="r13p11.seat"
                                
                              >                              
                                {{ r13p11.seat }}
                              </td>
                              <td
                                v-else
                                :class="r13p11.class"
                                style="background-color: red"
                                :id="r13p11.seat"
                                 
                              >                              
                                {{ r13p11.seat }}
                              </td>
                            </tr>
                            <tr
                              :style="'text-align: center;'+r13p12.background"
                              class="flex"
                              v-for="r13p12 in row13part12"
                              :key="r13p12.id"
                            >
                              <td
                                v-if="r13p12.status"
                                :class="r13p12.class"
                                :style="r13p12.color"
                                :id="r13p12.seat"
                                
                              >                               
                                {{ r13p12.seat }}
                              </td>
                              <td
                                v-else
                                :class="r13p12.class"
                                style="background-color: red"
                                :id="r13p12.seat"
                                
                              >                               
                                {{ r13p12.seat }}
                              </td>
                            </tr>
                            <tr
                              :style="'text-align: center;'+r13p13.background"
                              class="flex"
                              v-for="r13p13 in row13part13"
                              :key="r13p13.id"
                            >
                              <td
                                v-if="r13p13.status"
                                :class="r13p13.class"
                                :style="r13p13.color"
                                :id="r13p13.seat"
                                
                              >                              
                                {{ r13p13.seat }}
                              </td>
                              <td
                                v-else
                                :class="r13p13.class"
                                style="background-color: red"
                                :id="r13p13.seat"
                                 
                              >                              
                                {{ r13p13.seat }}
                              </td>
                            </tr>
                          </tbody>
                        </div>
                        <div class="col-sm-4 col4dj" style="padding-left: 0px">
                          <img src="../assets/dj.png" class="imgdj" />
                        </div>
                        <div
                          class="col-sm-3 col90"
                          style="padding: 0px"
                        >
                          <tbody>
                            <tr style="text-align: center" class="flex afterdj">
                              <td
                                class="badge"
                                style="background-color: transparent"
                              >
                                90
                              </td>
                            </tr>
                            <tr style="text-align: center" class="flex">
                              <td
                                class="badge"
                                style="background-color: transparent"
                              >
                                96
                              </td>
                            </tr>
                            <tr
                             :style="'text-align: center;'+rv.background"
                              class="flex colvip"
                              v-for="rv in rowvip"
                              :key="rv.id"
                            >
                              <td
                                v-if="rv.status"
                                :class="rv.class"
                                :style="rv.color"
                                :id="rv.seat"
                                
                              >                              
                                {{ rv.seat }}
                              </td>
                              <td
                                v-else
                                :class="rv.class"
                                style="background-color: red"
                                :id="rv.seat"
                                
                              >                              
                                {{ rv.seat }}
                              </td>
                            </tr>
                            <tr
                              :style="'text-align: center;'+r13p2.background"
                              class="flex"
                              v-for="r13p2 in row13part2"
                              :key="r13p2.id"
                            >
                              <td
                                v-if="r13p2.status"
                                :class="r13p2.class"
                                :style="r13p2.color"
                                :id="r13p2.seat"
                                
                              >                              
                                {{ r13p2.seat }}
                              </td>
                              <td
                                v-else
                                :class="r13p2.class"
                                style="background-color: red"
                                :id="r13p2.seat"
                                
                              >                              
                                {{ r13p2.seat }}
                              </td>
                            </tr>
                            <tr style="text-align: center" class="flex col116">
                              <td
                                class="badge"
                                style="background-color: transparent"
                              >
                                VIP
                              </td>
                            </tr>
                            <tr
                              :style="'text-align: center;'+r13p3.background"
                              class="flex"
                              v-for="r13p3 in row13part3"
                              :key="r13p3.id"
                            >
                              <td
                                v-if="r13p3.status"
                                :class="r13p3.class"
                                :style="r13p3.color"
                                :id="r13p3.seat"
                                
                              >                              
                                {{ r13p3.seat }}
                              </td>
                              <td
                                v-else
                                :class="r13p3.class"
                                style="background-color: red"
                                :id="r13p3.seat"
                                
                              >                              
                                {{ r13p3.seat }}
                              </td>
                            </tr>
                          </tbody>
                        </div>
                      </div>
                    </table>
                  </div>
                  <div class="mt-3 barwidth">เสา</div>
                </div>
                <div class="col-sm-1 col117" style="padding: 0px">
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                       :style="'text-align: center;'+r9p3.background"
                        class="flex"
                        v-for="r9p3 in row9part3"
                        :key="r9p3.id"
                      >
                        <td
                          v-if="r9p3.status"
                          :class="r9p3.class + ' margintop20'"
                          :id="r9p3.seat"
                          :style="r9p3.color"
                          
                        >                        
                          {{ r9p3.seat }}
                        </td>
                        <td
                          v-else
                          :class="r9p3.class"
                          :id="r9p3.seat"
                          style="background-color: red;margin-top:20px'"
                          
                        >                        
                          {{ r9p3.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="stair119">
                    <div class="a"></div>
                    <div class="b">
                      <span class="stair" style="margin-left: 3px"></span>
                      <span class="stair" style="margin-left: 1.5px"></span>
                      <span class="stair" style="margin-left: 1.5px"></span>
                    </div>
                    <div class="c"></div>
                  </div>
                  <table class="table table-borderless margintop30">
                    <tbody>
                      <tr
                        :style="'text-align: center;'+r9p5.background"
                        class="flex"
                        v-for="r9p5 in row9part5"
                        :key="r9p5.id"
                      >
                        <td
                          v-if="r9p5.status"
                          :class="r9p5.class + ' margintop20'"
                          :id="r9p5.seat"
                          :style="r9p5.color"
                          
                        >                        
                          {{ r9p5.seat }}
                        </td>
                        <td
                          v-else
                          :class="r9p5.class + ' margintop20'"
                          :id="r9p5.seat"
                          style="background-color: red"
                           
                        >                        
                          {{ r9p5.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="col-sm-1 footleft"
              style="border-left: 28px solid black; padding: 0px"
            >
              <div class="row backgrountfoot">
                <div class="col-sm-2" style="padding: 0px">
                  <div class="verticaltext">ทางเดิน</div>
                  <div class="doortop" style="width: 70px">
                    <div class="doorone">
                      <div class="handone"></div>
                    </div>
                    <div class="doortwo"><div class="handtwo"></div></div>
                  </div>
                </div>
                <div class="col-sm-6" style="padding: 0px; margin: 0px">
                  <table class="table table-borderless margintop120">
                    <tbody>
                      <tr
                        :style="'text-align: center;'+r9p4.background"
                        class="flex"
                        v-for="r9p4 in row9part4"
                        :key="r9p4.id"
                      >
                        <td
                          v-if="r9p4.status"
                          :class="r9p4.class"
                          :style="r9p4.color"
                          :id="r9p4.seat"
                          
                        >                        
                          {{ r9p4.seat }}
                        </td>
                        <td
                          v-else
                          :class="r9p4.class"
                          style="background-color: red"
                          :id="r9p4.seat"
                           
                        >                        
                          {{ r9p4.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-2 col2width"
          style="
            text-align: center;
            background-color: white;
            border: 8px solid black;
          "
        >
          <div class="row margintop20">
            <div class="col-sm-6">
              <table class="table table-borderless">
                <tbody>
                  <tr
                   :style="'text-align: center;'+c2c1.background"
                    class="flex"
                    v-for="c2c1 in col2col1"
                    :key="c2c1.id"
                  >
                    <td
                      v-if="c2c1.status"
                      :class="c2c1.class"
                      :style="c2c1.color"
                      :id="c2c1.seat"
                      
                    >                    
                      {{ c2c1.seat }}
                    </td>
                    <td
                      v-else
                      :class="c2c1.class"
                      style="background-color: red"
                      :id="c2c1.seat"
                       
                    >                    
                      {{ c2c1.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-6">
              <table class="table table-borderless">
                <tbody>
                  <tr
                    :style="'text-align: center;'+c2c2.background"
                    class="flex"
                    v-for="c2c2 in col2col2"
                    :key="c2c2.id"
                  >
                    <td
                      v-if="c2c2.status"
                      :class="c2c2.class"
                      :style="c2c2.color"
                      :id="c2c2.seat"
                      
                    >                    
                      {{ c2c2.seat }}
                    </td>
                    <td
                      v-else
                      :class="c2c2.class"
                      style="background-color: red"
                      :id="c2c2.seat"
                      
                    >                    
                      {{ c2c2.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-6" style="padding: 0px">
              <div class="heightrow polestyle">เสา</div>
              <table class="table table-borderless pole">
                <tbody>
                  <tr
                     :style="'text-align: center;'+c2c1p1.background"
                    class="flex"
                    v-for="c2c1p1 in col2col1p1"
                    :key="c2c1p1.id"
                  >
                    <td
                      v-if="c2c1p1.status"
                      :class="c2c1p1.class"
                      :style="c2c1p1.color"
                      :id="c2c1p1.seat"
                      
                    >                    
                      {{ c2c1p1.seat }}
                    </td>
                    <td
                      v-else
                      :class="c2c1p1.class"
                      style="background-color: red"
                      :id="c2c1p1.seat"
                       
                    >                    
                      {{ c2c1p1.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-6">
              <div class="stair138">
                <div class="a"></div>
                <div class="b">
                  <span class="stair" style="margin-left: 3px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                  <span class="stair" style="margin-left: 1.5px"></span>
                </div>
                <div class="c"></div>
              </div>
              <table class="table table-borderless margintop138">
                <tbody>
                  <tr
                   :style="'text-align: center;'+c2c1p2.background"
                    class="flex"
                    v-for="c2c1p2 in col2col1p2"
                    :key="c2c1p2.id"
                  >
                    <td
                      v-if="c2c1p2.status"
                      :class="c2c1p2.class"
                      :style="c2c1p2.color"
                      :id="c2c1p2.seat"
                      
                    >                    
                      {{ c2c1p2.seat }}
                    </td>
                    <td
                      v-else
                      :class="c2c1p2.class"
                      style="background-color: red"
                      :id="c2c1p2.seat"
                      
                    >                    
                      {{ c2c1p2.seat }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="col-sm-2 contactheight"
          style="
            text-align: center;
            border-top: 8px solid black;
            border-bottom: 8px solid black;
            border-right: 8px solid black;
          "
        >
          <div class="row">
            <div class="col-sm-12 col12">
              <div class="row margintop20">
                <div style="width:33%;">
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        :style="'text-align: center;'+c3r1.background"
                        class="flex"
                        v-for="c3r1 in col3row1"
                        :key="c3r1.id"
                      >
                        <td
                          v-if="c3r1.status"
                          :class="c3r1.class"
                          :style="c3r1.color"
                          :id="c3r1.seat"
                          
                        >                        
                          {{ c3r1.seat }}
                        </td>
                        <td
                          v-else
                          :class="c3r1.class"
                          style="background-color: red"
                          :id="c3r1.seat"
                          
                        >                        
                          {{ c3r1.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="width:33%;">
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        :style="'text-align: center;'+c3r2.background"
                        class="flex"
                        v-for="c3r2 in col3row2"
                        :key="c3r2.id"
                      >
                        <td
                          v-if="c3r2.status"
                          :class="c3r2.class"
                          :style="c3r2.color"
                          :id="c3r2.seat"
                          
                        >                        
                          {{ c3r2.seat }}
                        </td>
                        <td
                          v-else
                          :class="c3r2.class"
                          style="background-color: red"
                          :id="c3r2.seat"
                           
                        >                        
                          {{ c3r2.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="width:33%;">
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        :style="'text-align: center;'+c3r3.background"
                        class="flex"
                        v-for="c3r3 in col3row3"
                        :key="c3r3.id"
                      >
                        <td
                          v-if="c3r3.status"
                          :class="c3r3.class"
                          :style="c3r3.color"
                          :id="c3r3.seat"
                          
                        >                          
                          {{ c3r3.seat }}
                        </td>
                        <td
                          v-else
                          :class="c3r3.class"
                          style="background-color: red"
                          :id="c3r3.seat"
                          
                        >                          
                          {{ c3r3.seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="col-sm-12 office"
              style="background-color: black !important"
            >
              <div class="verticaloffice">office</div>
              <div class="contact">
                <a
                  href="https://www.facebook.com/NewHollywoodPubPhayao/"
                  target="_blank"
                  class="fas fa fa-facebook"
                ></a
                ><span class="facetext">hollywood pub phayao</span>
                <br />
                <a href="#" class="fas fa fa-instagram"></a
                ><span class="igtext">hollywood_pub_phayao</span>
              </div>
              <img
                class="mt-3 widthline"
                style="margin: auto"
                src="../assets/line.png"
              />
            </div>
          </div>
        </div>
        <div style="display: inline-block">
          <img src="../assets/fireexitdown.png" class="fireexitdownone" />
          <img src="../assets/fireexitdown.png" class="fireexitdowntwo" />
        </div>
      </div>
    </div>
      <div class="row mt-5">
        <div class="col-sm-3" v-for="(z, i) in zones" :key="i">
          <div class="card mt-3">
            <div class="card-body">
              <div style="text-align: center">
                <p class="dot" :style="z.color"></p>
                <div>{{ z.name }}</div>
                <div>ราคา {{ z.price || "0" }} บาท</div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-3">
          <div class="card mt-3">
            <div class="card-body">
              <div style="text-align: center">
                <p class="dot" style="background:red"></p>
                <div>จองแล้ว</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZoneService from "../services/ZoneService.js";
import SeatService from "../services/SeatService";
import ConcertService from "../services/ConcertService";
import axios from "axios";
import BannertService from "../services/BannertService";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import LinkService from "../services/LinkService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      list: [],
      color: "#0000FF",
       row1: [],
      row1part2: [],
      row2: [],
      row3: [],
      row4: [],
      row5: [],
      row6part1: [],
      row6part2: [],
      row6part3: [],
      row6part4: [],
      row7: [],
      row8: [],
      row9part1: [],
      row9part2: [],
      row9part3: [],
      row9part4: [],
      row9part5: [],
      row10part1: [],
      row10part2: [],
      row11part1: [],
      row11part2: [],
      row12: [],
      row13part1: [],
      row13part11: [],
      row13part12: [],
      row13part13: [],
      row13part2: [],
      row13part3: [],
      row14: [],
      col2col1: [],
      col2col2: [],
      col2col1p1: [],
      col2col1p2: [],
      col3row1: [],
      col3row2: [],
      col3row3: [],
      col3row4: [],
      rowvip: [],
      concert_id: 0,
      zones: [],
      data: [],
      seats: [],
      selectedFile: "",
      banner: "",
      filename: "",
      docs: {},
      concert_name: "",
      zone: "",
      image: "",
      link: 'https://i.imgur.com/lF1GKDt.jpg',
      style:'',
      title:{},
    };
  },
  mounted() {
    // ConcertService.getconcerts().then((res)=>{
    // console.log(res.data);
    this.concert_id = this.$route.params.id;
    this.getConcert();
    this.getbanners();
    this.getzones();
    this.getSeats();
    
    // })
  },
  methods: {    
    async printThis() {
      console.log("printing..");
      const el = this.$refs.printMap;

      const options = {
        type: "dataURL",
        width :"500px"
      };
      const printCanvas = await this.$html2canvas(el, options);
      this.image = printCanvas;
      //   var a = document.createElement("a"); //Create <a>
      // a.href = printCanvas; //Image Base64 Goes here
      // a.download = this.concert_name+ ".jpg"; //File name Here
      // a.target = '_blank';
      // a.click(); //Downloaded file

      html2canvas(document.getElementById("my-node")).then(function (canvas) {
        var link = document.createElement("a");
        document.body.appendChild(link); 
        var c = document.getElementById("my-node").getAttribute("name") + ".jpg";
        link.download = c;
        link.href = canvas.toDataURL();
        link.target = "_blank";
        link.click();
      });
    },
    getfilepng() {
      htmlToImage
        .toJpeg(document.getElementById("my-node"), { quality: 0.95 })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "my-image-name.jpeg";
          link.href = dataUrl;
          link.click();
        });
    },
    downloadImg(responseUrl) {
        const url = window.URL.createObjectURL(new Blob([responseUrl]));
        // console.log(url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.png"); //or any other extension
        document.body.appendChild(link);
        link.click();
    },
    getbanners() {
      BannertService.getbanner(this.concert_id).then((res) => {
        // console.log(res.data);
        if (res.data.length != 0) {          
        var b= res.data.banner.split('/')
        // console.log(b[5]);
        // this.banner = '/assets/'+b[5]
        // this.banner = res.data.banner
        axios.get(LinkService.getLink()+"/img?name="+b[5])
    .then((res)=>{
          this.banner = res.data.base64
        // console.log(this.banner);    
        }) 
        }   
      });
    },
    getSeats() {
      SeatService.getseatbyconcertidmap(this.concert_id).then((res) => {
        this.seats = res.data;
        console.log(this.seats);
        this.generaterow();
      });
    },
    getConcert() {
      ConcertService.getconcert(this.concert_id).then((res) => {
        this.concert_name = res.data.concert_name;
        this.title = res.data;
        this.style = 'color:'+this.title.color+';font-family:'+this.title.font+';font-size:1.9rem;margin-top:15px!important'
        // console.log(this.title.time);
      });
    },
    getzones() {
      ZoneService.getzonebyconcertid(this.concert_id).then((res) => {
        this.zones = res.data;
      });
    },
    convertdatesave(date){
var dd = date.split('/')[1]
var mm = date.split('/')[0]
var yy = date.split('/')[2]
return yy+'/'+dd+'/'+mm
    },
    generaterow() {
      this.row1 = [];
      this.row1part2 = [];
      this.row2 = [];
      this.row3 = [];
      this.row4 = [];
      this.row5 = [];
      this.row6part1 = [];
      this.row6part2 = [];
      this.row6part3 = [];
      this.row6part4 = [];
      this.row7 = [];
      this.row8 = [];
      this.row9part1 = [];
      this.row13part11 = [];
      this.row13part12 = [];
      this.row13part13 = [];
      this.row9part2 = [];
      this.row9part3 = [];
      this.row9part4 = [];
      this.row9part5 = [];
      this.row10part1 = [];
      this.row10part2 = [];
      this.row11part1 = [];
      this.row11part2 = [];
      this.row12 = [];
      this.row13part1 = [];
      this.row13part2 = [];
      this.row13part3 = [];
      this.row14 = [];
      this.col2col1 = [];
      this.col2col2 = [];
      this.col2col1p1 = [];
      this.col2col1p2 = [];
      this.col3row1 = [];
      this.col3row2 = [];
      this.col3row3 = [];
      this.col3row4 = [];
      this.rowvip = [];
      for (let i = 0; i < this.seats.length; i++) {
        var classss = "";
       if (
          this.seats[i].seat == 30 ||
          this.seats[i].seat == 36 ||
          this.seats[i].seat == 43 ||
          this.seats[i].seat == 50 ||
          this.seats[i].seat == 57 ||
          this.seats[i].seat == 31 ||
          this.seats[i].seat == 37 ||
          this.seats[i].seat == 44 ||
          this.seats[i].seat == 58 ||
          this.seats[i].seat == 63 ||
          this.seats[i].seat == 32 ||
          this.seats[i].seat == 38 ||
          this.seats[i].seat == 52 ||
          this.seats[i].seat == 59 ||
          this.seats[i].seat == 64 ||
          this.seats[i].seat == 33 ||
          this.seats[i].seat == 46 ||
          this.seats[i].seat == 53 ||
          this.seats[i].seat == 60 ||
          this.seats[i].seat == 65 || 
          this.seats[i].seat == 69 ||
          this.seats[i].seat == 70 ||
          this.seats[i].seat == 71 ||
          this.seats[i].seat == 34 ||
          this.seats[i].seat == 40 ||
          this.seats[i].seat == 47 ||
          this.seats[i].seat == 54 ||
          this.seats[i].seat == 35 ||
          this.seats[i].seat == 41 ||
          this.seats[i].seat ==  48 ||
          this.seats[i].seat == 55 ||
          this.seats[i].seat == 8 ||
          this.seats[i].seat == 14 ||
          this.seats[i].seat ==  19 ||
          this.seats[i].seat == 24 ||
          this.seats[i].seat == 7 ||
          this.seats[i].seat == 13 ||
          this.seats[i].seat == 18 ||
          this.seats[i].seat == 23 ||
          this.seats[i].seat == 9 ||
          this.seats[i].seat == 15 ||
          this.seats[i].seat == 20 ||
          this.seats[i].seat == 25 ||
          this.seats[i].seat == 31 ||
          this.seats[i].seat == 37 ||
          this.seats[i].seat == 58 ||
          this.seats[i].seat == 63 ||
          this.seats[i].seat == 10 ||
          this.seats[i].seat == 16 ||
          this.seats[i].seat == 21 ||
          this.seats[i].seat == 26 ||
          this.seats[i].seat == 32 ||
          this.seats[i].seat == 38 ||
          this.seats[i].seat == 52 ||
          this.seats[i].seat == 59 ||
          this.seats[i].seat == 64 ||
          this.seats[i].seat == 70 ||
          this.seats[i].seat == 11 ||
          this.seats[i].seat == 17 ||
          this.seats[i].seat == 22 ||
          this.seats[i].seat == 27 ||
          this.seats[i].seat == 33 ||
          this.seats[i].seat == 42 ||
          this.seats[i].seat == 46 ||
          this.seats[i].seat == 53 ||
          this.seats[i].seat == 60 ||
          this.seats[i].seat == 66 ||
          this.seats[i].seat == 65 ||
          this.seats[i].seat == 71 ||
          this.seats[i].seat == 12 ||
          this.seats[i].seat == 72 ||
          this.seats[i].seat == 28 ||
          this.seats[i].seat == 116 ||
          this.seats[i].seat == 117 || 
          this.seats[i].seat == 29 ||
          this.seats[i].seat == 43 ||
          this.seats[i].seat == 49 ||
          this.seats[i].seat == 61 ||
          this.seats[i].seat == 67
        ) {
          classss += this.seats[i].class + " leftbadge";
        } else {
          classss += this.seats[i].class;
        }
        if (
          this.seats[i].seat == 85 ||
          this.seats[i].seat == 92 ||
          this.seats[i].seat == 98 ||
          this.seats[i].seat == 102 ||
          this.seats[i].seat == 106 ||
          this.seats[i].seat == 111 ||
          this.seats[i].seat == 93 ||
          this.seats[i].seat == 99 ||
          this.seats[i].seat == 103 ||
          this.seats[i].seat == 107 ||
          this.seats[i].seat == 112 ||
          this.seats[i].seat == 94 ||
          this.seats[i].seat == 100 ||
          this.seats[i].seat == 101 ||
          this.seats[i].seat == 104 ||
          this.seats[i].seat == 103 ||
          this.seats[i].seat == 105 ||
          this.seats[i].seat == 106  ||
          this.seats[i].seat == 108 ||
          this.seats[i].seat == 109 ||
          this.seats[i].seat == 114 ||
          this.seats[i].seat == 111 ||
          this.seats[i].seat == 112 ||
          this.seats[i].seat == 113 ||
          this.seats[i].seat == 95 ||
          this.seats[i].seat == 96 ||
          this.seats[i].seat == 97 ||
          this.seats[i].seat == 115 
        ) {
          classss += ' col93';
        }
        if (          
        this.seats[i].seat == 45 ||
        this.seats[i].seat == 51 ||
        this.seats[i].seat == 56 ||
        this.seats[i].seat == 62 ||
        this.seats[i].seat == 68
        ) {
          classss += " colleft";
        }
        if (this.seats[i].seat == 91 || this.seats[i].seat == 97) {
          classss += " addmargin17";
        }
        if (
        this.seats[i].seat == 125 ||
        this.seats[i].seat == 126 ||
        this.seats[i].seat == 127 ||
        this.seats[i].seat == 128 ||
        this.seats[i].seat == 130 ||
        this.seats[i].seat == 131 ||
        this.seats[i].seat == 132 ||
        this.seats[i].seat == 133 ||
         this.seats[i].seat == 134 ||
        this.seats[i].seat == 138 ||
        this.seats[i].seat == 139 ||
        this.seats[i].seat == 140 ||
        this.seats[i].seat == 141 ||
        this.seats[i].seat == 142) {
          classss += " margintop125";
        }
        if (
        this.seats[i].seat == 136 ||  
        this.seats[i].seat == 137 || 
        this.seats[i].seat == 144 ||
        this.seats[i].seat == 145 ||
        this.seats[i].seat == 147 ||
        this.seats[i].seat == 148 ||
        this.seats[i].seat == 149 ||
        this.seats[i].seat == 151 ||
        this.seats[i].seat == 152 ||
        this.seats[i].seat == 153) {
          classss += " margintop136";
        }
  
  var data = {
          id: this.seats[i].id,
          seat: this.seats[i].seat,
          class: classss,
          color: this.seats[i].color,
          price: this.seats[i].price,
          status: this.seats[i].status,
          seatstatus:this.seats[i].seatstatus,
          background:";background-color:"+this.seats[i].background
        };
        // console.log(data);
        if (
          this.seats[i].seat == 1 ||
          this.seats[i].seat == 7 ||
          this.seats[i].seat == 13 ||
          this.seats[i].seat == 18 ||
          this.seats[i].seat == 23
        ) {
          this.row1.push(data);
        } else if (
          this.seats[i].seat == 39 ||
          this.seats[i].seat == 45 ||
          this.seats[i].seat == 51 ||
          this.seats[i].seat == 56 ||
          this.seats[i].seat == 62 ||
          this.seats[i].seat == 68
        ) {
          this.row1part2.push(data);
        } else if (
          this.seats[i].seat == 2 ||
          this.seats[i].seat == 8 ||
          this.seats[i].seat == 14 ||
          this.seats[i].seat == 19 ||
          this.seats[i].seat == 24 ||
          this.seats[i].seat == 29 ||
          this.seats[i].seat == 34 ||
          this.seats[i].seat == 40 ||
          this.seats[i].seat == 45 ||
          this.seats[i].seat == 46 ||
          this.seats[i].seat == 52 ||
          this.seats[i].seat == 57 ||
          this.seats[i].seat == 63 ||
          this.seats[i].seat == 69
        ) {
          this.row2.push(data);
        } else if (
          this.seats[i].seat == 3 ||
          this.seats[i].seat == 9 ||
          this.seats[i].seat == 15 ||
          this.seats[i].seat == 20 ||
          this.seats[i].seat == 25 ||
          this.seats[i].seat == 30 ||
          this.seats[i].seat == 35 ||
          this.seats[i].seat == 41 ||
          this.seats[i].seat == 47 ||
          this.seats[i].seat == 53 ||
          this.seats[i].seat == 58 ||
          this.seats[i].seat == 64 ||
          this.seats[i].seat == 70
        ) {
          this.row3.push(data);
        } else if (
          this.seats[i].seat == 4 ||
          this.seats[i].seat == 10 ||
          this.seats[i].seat == 16 ||
          this.seats[i].seat == 21 ||
          this.seats[i].seat == 26 ||
          this.seats[i].seat == 31 ||
          this.seats[i].seat == 36 ||
          this.seats[i].seat == 42 ||
          this.seats[i].seat == 48 ||
          this.seats[i].seat == 54 ||
          this.seats[i].seat == 59 ||
          this.seats[i].seat == 65 ||
          this.seats[i].seat == 71
        ) {
          this.row4.push(data);
        } else if (
          this.seats[i].seat == 5 ||
          this.seats[i].seat == 11 ||
          this.seats[i].seat == 17 ||
          this.seats[i].seat == 22 ||
          this.seats[i].seat == 27 ||
          this.seats[i].seat == 32 ||
          this.seats[i].seat == 37 ||
          this.seats[i].seat == 43 ||
          this.seats[i].seat == 49 ||
          this.seats[i].seat == 55 ||
          this.seats[i].seat == 60 ||
          this.seats[i].seat == 66 ||
          this.seats[i].seat == 72
        ) {
          this.row5.push(data);
        } else if (this.seats[i].seat == 6 || this.seats[i].seat == 12) {
          this.row6part1.push(data);
        } else if (
          this.seats[i].seat == 28 ||
          this.seats[i].seat == 33 ||
          this.seats[i].seat == 38 ||
          this.seats[i].seat == 44 ||
          this.seats[i].seat == 50
        ) {
          this.row6part2.push(data);
        } else if (this.seats[i].seat == 61 || this.seats[i].seat == 67) {
          this.row6part3.push(data);
        } else if (this.seats[i].seat == 73) {
          this.row6part4.push(data);
        } else if (
          this.seats[i].seat == 74 ||
          this.seats[i].seat == 78 ||
          this.seats[i].seat == 82 ||
          this.seats[i].seat == 85 ||
          this.seats[i].seat == 92 ||
          this.seats[i].seat == 98 ||
          this.seats[i].seat == 102 ||
          this.seats[i].seat == 106 ||
          this.seats[i].seat == 111 
        ) {
          this.row8.push(data);
        } else if (
          this.seats[i].seat == 75 ||
          this.seats[i].seat == 79 ||
          this.seats[i].seat == 83
        ) {
          this.row9part1.push(data);
        } else if (
          this.seats[i].seat == 86 ||
          this.seats[i].seat == 93 ||
          this.seats[i].seat == 99 ||
          this.seats[i].seat == 103 ||
          this.seats[i].seat == 107 ||
          this.seats[i].seat == 112
        ) {
          this.row9part2.push(data);
        } else if (this.seats[i].seat == 116 || this.seats[i].seat == 117) {
          this.row9part3.push(data);
        } else if (this.seats[i].seat == 118) {
          this.row9part5.push(data);
        } else if (
          this.seats[i].seat == 119 ||
          this.seats[i].seat == 120 ||
          this.seats[i].seat == 121 || 
          this.seats[i].seat == 122 || 
          this.seats[i].seat == 123
        ) {
          this.row9part4.push(data);
        } else if (
          this.seats[i].seat == 76 ||
          this.seats[i].seat == 80 ||
          this.seats[i].seat == 84
        ) {
          this.row10part1.push(data);
        } else if (
          this.seats[i].seat == 87 ||
          this.seats[i].seat == 94 ||
          this.seats[i].seat == 100 ||
          this.seats[i].seat == 104 ||
          this.seats[i].seat == 108 ||
          this.seats[i].seat == 113
        ) {
          this.row10part2.push(data);
        } else if (this.seats[i].seat == 77 || this.seats[i].seat == 81) {
          this.row11part1.push(data);
        } else if (
          this.seats[i].seat == 88 ||
          this.seats[i].seat == 95 ||
          this.seats[i].seat == 101 ||
          this.seats[i].seat == 105 ||
          this.seats[i].seat == 109 
        ) {
          console.log(data);
          this.row11part2.push(data);
        } else if (this.seats[i].seat == 89) {
          this.row12.push(data);
        } else if (this.seats[i].seat == 90) {
          this.row13part1.push(data);
        } else if (this.seats[i].seat == 96) {
          this.row13part11.push(data);
        } else if (this.seats[i].seat == 91) {
          this.row13part12.push(data);
        } else if (this.seats[i].seat == 97) {
          this.row13part13.push(data);
        } else if (this.seats[i].seat == 114) {
          this.row13part2.push(data);
        } else if (this.seats[i].seat == 110) {
          this.rowvip.push(data);
        } else if (this.seats[i].seat == 115) {
          this.row13part3.push(data);
        } else if (this.seats[i].seat == 154 || this.seats[i].seat == 155) {
          this.row14.push(data);
        } else if (
          this.seats[i].seat == 124 ||
          this.seats[i].seat == 125 ||
          this.seats[i].seat == 126 ||
          this.seats[i].seat == 127 ||
          this.seats[i].seat == 128
        ) {
          this.col2col1.push(data);
        } else if (
          this.seats[i].seat == 135 ||
          this.seats[i].seat == 136 ||
          this.seats[i].seat == 137
        ) {
          this.col2col2.push(data);
        } else if (
          this.seats[i].seat == 129 ||
          this.seats[i].seat == 130 ||
          this.seats[i].seat == 131 ||
          this.seats[i].seat == 132 ||
          this.seats[i].seat == 133 ||
          this.seats[i].seat == 134
        ) {
          this.col2col1p1.push(data);
        } else if (
          this.seats[i].seat == 138 ||
          this.seats[i].seat == 139 ||
          this.seats[i].seat == 140 ||
          this.seats[i].seat == 141 ||
          this.seats[i].seat == 142
        ) {
          this.col2col1p2.push(data);
        } else if (
          this.seats[i].seat == 143 ||
          this.seats[i].seat == 144 ||
          this.seats[i].seat == 145
        ) {
          this.col3row1.push(data);
        } else if (
          this.seats[i].seat == 146 ||
          this.seats[i].seat == 147 ||
          this.seats[i].seat == 148 ||
          this.seats[i].seat == 149
        ) {
          this.col3row2.push(data);
        } else if (
          this.seats[i].seat == 150 ||
          this.seats[i].seat == 151 ||
          this.seats[i].seat == 152 ||
          this.seats[i].seat == 153
        ) {
          this.col3row3.push(data);
        }     
      }
      // this.printThis()
      // console.log(this.row11);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-device-width: 1025px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .col93{
    margin-left: 15px!important;
  }
  .stair119{
    width: 20px; margin-left: 0px;
    margin-bottom: 0px;
  }
  .footleft {
    margin-left: -8%;
  }
  .col12 {
    width: 90%;
    margin: auto;
  }
  .toiletwomen {
    height: 40px;
    margin-top: 70px;
    text-align: center;
    background-color: black;
    width: 30px;
  }
  .toilettext {
    color: white;
    font-size: 5px;
    text-decoration: overline;
    margin-left: -23px;
  }
  #famen {
    color: white;
    margin-top: 5px;
    font-size: 10px;
    margin-left: -25px;
  }
  .toiletmen {
    text-align: center;
    background-color: black;
    width: 30px;
    height: 28px;
  }
  .sizefireexitleft {
    width: 10px;
  }
  .fireexitleftone {
    display: inline-block;
    margin-top: 30px;
    margin-left: 20px;
  }
  .fireexitlefttwo {
    display: inline-block;
    margin-top: 80px;
    margin-left: 20px;
  }
  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 77px;
    margin-left: 20px;
  }
  .contactheight {
    height: 428px;
  }
  .widthline {
    width: 80px;
  }
  .contact {
    margin-top: 60px;
    margin-left: -9px;
  }
  .stair138 {
    width: 15px;
    margin-left: 15px;
  }
  .col2124 {
    width: 100px;
    height: 428px;
  }
  .mt-3 {
    margin-top: 0.3rem !important;
  }
  .polestyle {
    background-color: black;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    width: 30px;
    margin-top: 15px;
  }
  .margintop120 {
    margin-top: 20px;
  }
  .pole {
    margin-top: 26px;
    width: 30px;
  }
  .backgrountfoot {
    background-color: white;
    height: 212px;
  }
  .footpath {
    margin-left: -16px;
  }
  .fontsizefootpath {
    font-size: 10px;
  }
  .margintop20 {
    margin-top: 20px !important;
  }
  .margintop30 {
    margin-top: 3px;
  }
  .addmargin17 {
    margin-left: 0px;
  }
  .col117 {
    width: 30px;
    margin-top: 5px;
  }
  .heightrow60 {
    height: 30px;
  }
  .heightrow {
    height: 25px;
  }
  .office {
    height: 244px;
    background-color: black;
    color: white;
    margin-top: 10px;
    padding: 0px;
  }
  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
  .fireexitdownone {
    margin-left: 105px;
    width: 20px;
  }
  .fireexitdowntwo {
    margin-left: 180px;
    width: 20px;
    margin-top: -5px;
  }
  .facetext {
    font-size: 8px;
  }
  .igtext {
    font-size: 8px;
  }
  .doortop {
    margin-left: -45px;
    margin-top: 118px;
  }
  .stair154 {
    width: 15px;
    margin-left: 5px;
  }
  .colrow14 {
    margin-left: -10px;
    margin-top: 0px;
  }
  .col116 {
    margin-left: 15px;
  }
  .colvip {
    margin-left: 15px;
  }
  .col90 {
    margin-left: 27px;
    width: 30%;
  }
  .afterdj {
    margin-left: 10px;
  }
  .col4dj {
    width: 41px;
  }
  .imgdj {
    height: 65px;
    margin-top: 20px;
  }
  .col3dj {
    width: 53px;
  }
  .col77 {
    margin-left: 5px;
  }
  .col7border {
    width: 170px;
    height: 160px;
  }
  .col73 {
    margin-left: 15px;
  }
  .stair73 {
    margin-top: 2px;
    margin-left: 22px;
    width: 15px;
  }
  .colstair1 {
    margin-left: -2px;
    width: 15px;
    margin-top: 10px;
  }
  .widthcol2 {
    width: 10%;
  }
  .widthcol5 {
    width: 30%;
  }
  .widthcol4 {
    width: 30%;
  }
  .col10 {
    margin-left: 18px;
  }
  .col8width {
    height: 428px;
    width: 325px;
  }
  .colbar6 {
    margin-left: 15px;
  }
  .leftbadge {
    margin-left: 7px !important;
  }
  .stage {
    margin: 8px;
    padding: 2px;
    width: 80px;
    height: 20px;
    background: black;
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f;
    font-size: 10px;
    font-weight: bold;
  }
  .bar {
    padding: 5px;
    width: 25px;
    height: 25px;
    background: black; /*important*/
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 10px;
    font-weight: 100;
  }
  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 80px;
    height: 14px;
    font-size: 12px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: 5px;
    background-color: white;
    color: black;
    text-align: center;
  }
  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 50px;
    height: 40px;
    font-size: 12px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -10px;
    margin-top: -30px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 50px;
    height: 30px;
    font-size: 14px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -24px;
    margin-top: 33px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 10px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: 40px;
    background-color: black;
    color: yellow;
    text-align: center;
  }
  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 20px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: -15px;
    color: white;
    text-align: center;
  }
  .fas {
    padding: 5px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    text-decoration: none;
    margin: 1px;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }
  .fa-instagram {
    background: #f82153;
    color: white;
  }
  .badge {
    cursor: pointer;
    margin: 0.1px;
    padding: 5px !important;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 8px;
    font-weight: 100;
  }
  .selectbadge {
    cursor: pointer;
    margin: 0.1px;
    padding: 4px;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 8px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 2px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  div.b {
    width: 100%;
    height: 15px;
    background-color: white;
  }
  div.c {
    width: 100%;
    height: 2px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  .stair {
    display: inline-block;
    width: 2px;
    background-color: black;
    height: 15px;
    background-color: black;
  }
  .doorone {
    display: inline-block;
    width: 15px;
    height: 40px;
    background: black;
    border: 1px solid red;
    border-radius: 10%;
  }
  .doortwo {
    display: inline-block;
    width: 15px;
    height: 40px;
    background: black;
    border: 1px solid red;
    border-radius: 10%;
  }
  .handone {
    width: 2px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    background: red;
    border-radius: 20%;
  }
  .handtwo {
    width: 2px;
    height: 20px;
    margin-left: 2px;
    margin-top: 15px;
    background: red;
    border-radius: 20%;
  }
}
@media only screen and (min-width: 100px) {
  .col93{
    margin-left: 10px!important;
  }
  .stair119{
    width: 18px; 
    margin-left: 0px;
    margin-bottom:20px
  }
  .banner {
    width: 50%;
  }
  .bannerright{
width: 30%;
  }
  .margintopbanner{
    margin-top: 10px!important;
  }
  .blankbanner {
    width: 10%;
  }
  .col1banner {
    width: 10%;
  }
  .col2banner {
    width: 8%;
  }
  .col5banner {
    width: 38%;
  }
  .col4banner {
    width: 40.5%;
  }
  .footleft {
    margin-left: -5%;
  }
  .col12 {
    width: 90%;
    margin: auto;
    padding: 0px;
  }
  .toiletwomen {
    height: 27px;
    margin-top: 40px;
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -19px;
  }
  .toilettext {
    color: white;
    font-size: 5px;
    text-decoration: overline;
    margin-left: -28px;
  }
  #famen {
    color: white;
    margin-top: 8px;
    font-size: 10px;
    margin-left: -17px;
  }
  .toiletmen {
    text-align: center;
    background-color: black;
    width: 30px;
    height: 28px;
    margin-left: -18px;
  }
  .sizefireexitleft {
    width: 11px;
    margin-left: -14px;
  }
  .fireexitleftone {
    display: inline-block;
    margin-top: 30px;
    margin-left: 55px;
  }
  .fireexitlefttwo {
    display: inline-block;
    margin-top: 80px;
    margin-left: 55px;
  }
  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 77px;
    margin-left: 52px;
  }
  .contactheight {
    height: 428px;
    width: 16%;
  }
  .widthline {
    width: 80px;
  }
  .contact {
    margin-top: 60px;
    margin-left: -9px;
  }
  .stair138 {
       width: 15px;
    margin-left: 20px;
    margin-top: -22px;
  }
  .col2124 {
    width: 100px;
    height: 428px;
  }
  .mt-3 {
    margin-top: 0.3rem !important;
  }
  .polestyle {
    background-color: black;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    width: 30px;
    margin-top: 15px;
  }
  .margintop120 {
    margin-top: 20px;
  }
  .pole {
    margin-top: 35px;
    width: 30px;
  }
  .backgrountfoot {
    background-color: white;
    height: 212px;
  }
  .footpath {
    margin-left: -16px;
  }
  .fontsizefootpath {
    font-size: 10px;
  }
  .margintop20 {
    margin-top: 0px !important;
  }
   .margintop125 {
    margin-top: 7px !important;
  }
  .margintop136 {
    margin-top: 12px !important;
  }
  .margintop30 {
    margin-top: -1px;
  }
  .addmargin17 {
    margin-left: 0px;
  }
  .col117 {
    width: 10px;
    margin-top: 15px;
    margin-left: -13px;
  }
  .heightrow60 {
    height: 30px;
  }
  .heightrow {
    height: 25px;
  }
  .office {
        height: 256px;
    background-color: black;
    color: white;
    margin-top: 31px;
    padding: 0px;
  }
  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }
  .fireexitdownone {
    margin-top: -8px;
    margin-left: 120px;
    width: 20px;
  }
  .fireexitdowntwo {
    margin-left: 180px;
    width: 20px;
    margin-top: -8px;
  }
  .facetext {
    font-size: 8px;
  }
  .igtext {
    font-size: 8px;
  }
  .doortop {
    margin-left: -45px;
    margin-top: 118px;
  }
  .stair154 {
    width: 15px;
    margin-left: 5px;
  }
  .colrow14 {
    margin-left: -4px;
    margin-top: 0px;
  }
  .col116 {
    margin-left: 5px;
  }
  .colvip {
    margin-left:5px;
  }
  .col90 {
    margin-left: 32px;
    width: 33%;
  }
  .afterdj {
    margin-left: 10px;
  }
  .col4dj {
    width: 31px;
    margin-left: -12px;
  }
  .imgdj {
    height: 52px;
    margin-top: 13px;
  }
  .col3dj {
    width: 60px;
  }
  .col77 {
    margin-left: 5px;
  }
  .col7border {
    width: 175px;
  }
  .col73 {
    margin-left: 15px;
  }
  .stair73 {
    margin-top: 0px;
    margin-left: 20px;
    width: 15px;
  }
  .colstair1 {
    margin-left: 20px;
    width: 15px;
    margin-top: -25px;
  }
  .widthcol2 {
    width: 10%;
  }
  .widthcol5 {
    width: 30%;
  }
  .widthcol4 {
    width: 30%;
  }
  .col10 {
    margin-left: 13px;
  }
  .colleft {
    margin-left: 2px !important;
}
  .col8width {
    height: 428px;
    width: 47%;
  }
  .col2width {
    width: 17%;
  }
  .colbar6 {
    margin-left: 3px;
  }
  .leftbadge {
    margin-left: 5px !important;
  }
  .stage {
    margin: 8px;
    padding: 2px;
    width: 47px;
    height: 20px;
    background: black;
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f;
    font-size: 10px;
    font-weight: bold;
  }
  .bar {
    padding: 3px;
    margin: 2px;
    width: 20px;
    height: 20px;
    background: black; /*important*/
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 8px;
    font-weight: 100;
  }
  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 47px;
    height: 14px;
    font-size: 10px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: 5px;
    background-color: white;
    color: black;
    text-align: center;
  }
  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 50px;
    height: 40px;
    font-size: 12px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -10px;
    margin-top: -30px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 50px;
    height: 30px;
    font-size: 12px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -24px;
    margin-top: 45px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 10px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: 40px;
    background-color: black;
    color: yellow;
    text-align: center;
  }
  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 20px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: -15px;
    color: white;
    text-align: center;
  }
  .fas {
    padding: 4px;
    font-size: 10px;
    width: 17px;
    height: 17px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }
  .fa-instagram {
    background: #f82153;
    color: white;
  }
  .badge {
    cursor: pointer;
    margin: 0.1px;
    padding: 2px !important;
    padding-top: 5px !important;
    width: 16px;
    height: 16px;
    margin-top: 5px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 7px;
    font-weight: 100;
  }
  .selectbadge {
    cursor: pointer;
    margin: 0.1px;
    padding: 3px;
    width: 16px;
    height: 16px;
    margin-top: 5px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 7px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 2px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  div.b {
    width: 100%;
    height: 12px;
    background-color: white;
  }
  div.c {
    width: 100%;
    height: 2px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  .stair {
    display: inline-block;
    width: 2px;
    background-color: black;
    height: 15px;
    background-color: black;
  }
  .doorone {
    display: inline-block;
    width: 15px;
    height: 40px;
    background: black;
    border: 1px solid red;
    border-radius: 10%;
  }
  .doortwo {
    display: inline-block;
    width: 15px;
    height: 40px;
    background: black;
    border: 1px solid red;
    border-radius: 10%;
  }
  .handone {
    width: 2px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    background: red;
    border-radius: 20%;
  }
  .handtwo {
    width: 2px;
    height: 20px;
    margin-left: 2px;
    margin-top: 15px;
    background: red;
    border-radius: 20%;
  }
}
@media only screen and (min-width: 1050px) {
  .col93{
    margin-left: 17px!important;
  }
  .stair119{
    width: 20px; margin-left: 0px;
    margin-top: -7px;
    margin-bottom: 0px;
  }
  .banner {
    width: 41%;
  }
  .bannerright{
width: 30%;
  }
  .margintopbanner{
    margin-top: 15px!important;
  }
  .blankbanner {
    width: 20%;
  }
  .col1banner {
    width: 15%;
  }
  .col2banner {
    width: 8%;
  }
  .col5banner {
    width: 26.5%;
  }
  .col4banner {
    width: 35%;
  }
  .footleft {
    margin-left: -21px;
    height: 247px;
  }
  .col2width {
    width: 12% !important;
  }
  .colleft {
    margin-left: 6px !important;
  }
  .col12 {
    width: 90%;
    margin: auto;
    padding: 0px;
  }
  .backgrountfoot {
    height: 10px;
    background-color: transparent;
  }
  .toiletwomen {
    height: 40px;
    margin-top: 57px;
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: 1px;
  }
  .toilettext {
    color: white;
    font-size: 9px;
    text-decoration: overline;
    padding: 5px;
    margin-left: -10px;
  }
  #famen {
    color: white;
    margin-top: 5px;
    font-size: 18px;
    margin-left: -10px;
  }
  .toiletmen {
    text-align: center;
    background-color: black;
    width: 26px;
    height: 51px;
    margin-left: 1px;
  }
  .sizefireexitleft {
    width: 15px;
  }
  .fireexitleftone {
    display: inline-block;
    margin-top: 45px;
    margin-left: 122px;
  }
  .fireexitlefttwo {
    display: inline-block;
    margin-top: 90px;
    margin-left: 122px;
  }
  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 80px;
    margin-left: 100px;
  }
  .widthline {
    width: 90px;
  }
  .contact {
    margin-top: 97px;
    margin-left: -15px;
  }
  .stair138 {
   width: 21px;
    margin-left: 20px;
    margin-top: -28px;
  }
  .contactheight {
    width: 14%;
    height: 520px;
  }
  .col2124 {
    width: 14%;
    height: 748px;
  }
  .polestyle {
    background-color: black;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 6px;
    width: 37px;
    margin-top: 21px;
  }
  .margintop120 {
    margin-top: 23px;
    margin-left: 7px;
  }
  .margintop125 {
    margin-top: 5px!important;
  }
  .margintop136 {
    margin-top: 15px!important;
  }
  .pole {
    margin-top: 47px;
    margin-left: 8px;
  }
  .footpath {
    margin-left: -56px;
  }
  .fontsizefootpath {
    font-size: 15px;
  }
  .margintop20 {
    margin-top: 10px !important;
  }
  .margintop30 {
    margin-top: 1px;
  }
  .addmargin17 {
    margin-left: 17px;
  }
  .col117 {
    width: 0px;
    margin-top: 5px;
    margin-left: -12px;
  }
  .heightrow60 {
    height: 40px;
  }
  .heightrow {
    height: 30px;
  }
  .office {
    height: 315px;
    background-color: black;
    color: white;
    margin-top: 27px;
    padding: 0px;
  }
  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
  .fireexitdownone {
    margin-left: 190px;
    width: 35px;
    margin-top: 0px;
  }
  .fireexitdowntwo {
    margin-left: 242px;
    width: 35px;
    margin-top: 0px;
  }
  .facetext {
    font-size: 10px;
  }
  .igtext {
    font-size: 10px;
  }
  .doortop {
    margin-left: -36px;
    margin-top: 130px;
  }
  .stair154 {
    margin-left: 12px;
    width: 20px;
  }
  .colrow14 {
   margin-left: -4px;
    margin-top: 4px;
  }
  .col116 {
    margin-left: 5px;
  }
  .colvip {
    margin-left: 5px;
  }
  .col90 {
    margin-left: -22px;
    width: 30%;
  }
  .afterdj {
    margin-left: 10px;
  }
  .col4dj {
    width: 122px;
  }
  .imgdj {
    height: 75px;
  }
  .addmargin17 {
    margin-left: 0px;
  }
  .col3dj {
    width: 78px !important;
  }
  .col77 {
    margin-left: 5px;
  }
  .col7border {
    width: 250px;
    height: 240px;
    padding-left: 5px;
  }
  .col73 {
    margin-left: 20px;
  }
  .stair73 {
    margin-left: 35px;
    width: 21px;
  }
  .colstair1 {
    margin-left: 33px;
    width: 20px;
    margin-top: -23px;
  }
  .widthcol2 {
    width: 16%;
    height: 520px;
  }
  .widthcol5 {
    width: 40%;
  }
  .widthcol4 {
    width: 36%;
  }
  .col10 {
    margin-left: 9px;
  }
  .col8width {
    width: 45%;
    padding: 8px;
    height: 520px;
  }
  .colbar6 {
    margin-left: 6px;
  }
  .leftbadge {
    margin-left: 9px !important;
  }
  .stage {
    margin: 8px;
    padding: 2px;
    width: 70px;
    height: 30px;
    background: black; /*important*/
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f; /*no need*/
    font-size: 14px;
    font-weight: bold;
    margin-top: -5px;
  }
  .bar {
    padding: 10px;
    width: 40px;
    height: 40px;
    background: black; /*important*/
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 14px;
    font-weight: 100;
  }
  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 65px;
    height: 20px;
    font-size: 14px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -27px;
    margin-top: -15px;
    background-color: white;
    color: black;
    text-align: center;
  }
  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 50px;
    font-size: 20px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -30px;
    margin-top: -31px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 67px;
    height: 36px;
    font-size: 15px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -25px;
    margin-top: 39px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 17px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -32px;
    margin-top: 39px;
    background-color: black;
    color: yellow;
    text-align: center;
  }
  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 30px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: 0px;
    color: white;
    text-align: center;
  }
  .fas {
    padding: 5px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    text-decoration: none;
    margin: 1px;
    margin-left: 10px;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }
  .fa-instagram {
    background: #f82153;
    color: white;
  }
  .badge {
    cursor: pointer;
    padding: 2px !important;
    padding-top: 7px !important;
    margin: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: 100;
  }
  .selectbadge {
    cursor: pointer;
    padding: 2px !important;
    padding-top: 4px !important;
    width: 22px;
    height: 22px;
    margin: 1px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  div.b {
    width: 100%;
    height: 20px;
    background-color: white;
  }
  div.c {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  .stair {
    display: inline-block;
    width: 4px;
    background-color: black;
    height: 20px;
    background-color: black;
  }
  .doorone {
    display: inline-block;
    width: 30px;
    height: 50px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }
  .doortwo {
    display: inline-block;
    width: 30px;
    height: 50px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }
  .handone {
    width: 4px;
    height: 15px;
    margin-left: 18px;
    margin-top: 25px;
    background: red;
    border-radius: 20%;
  }
  .handtwo {
    width: 4px;
    height: 15px;
    margin-left: 2px;
    margin-top: 25px;
    background: red;
    border-radius: 20%;
  }
}
@media only screen and (min-width: 1366px) {
  .col93{
    margin-left: 26px!important;
  }
  .margintop138{
    margin-top: -13px;
  }
  .stair119{
    width: 20px; margin-left: 0px;
    margin-bottom: 15px;
  }
  .banner {
    width: 50.5%;
  }
  .bannerright{
width: 30%;
  }
  .margintopbanner{
    margin-top: 15px;
  }
  .blankbanner {
    width: 10%;
  }
  .borderheight {
    height: 250px;
  }
  .col1banner {
    width: 12%;
  }
  .col2banner {
    width: 10%;
  }
  .col5banner {
    width: 33%;
  }
  .col4banner {
    width: 37.5%;
  }
  .footleft {
    margin-left: -19.5px;
    height: 330px;
  }
  .contactheight {
    width: 15.5%;
    height: 755px;
  }
  .col2width {
    width: 12% !important;
    height: 755px;
  }
  .colleft {
    margin-left: 7px !important;
  }
  .col12 {
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .backgrountfoot {
    height: 10px;
  }
  .toiletwomen {
    height: 40px;
    margin-top: 90px;
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -5px;
  }
  .toilettext {
    color: white;
    font-size: 9px;
    text-decoration: overline;
    margin: auto;
  }
  #famen {
    color: white;
    margin-top: 5px;
    font-size: 18px;
    margin-left: 0px;
  }

  .toiletmen {
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -5px;
  }
  .sizefireexitleft {
    width: 30px;
  }
  .fireexitleftone {
    display: inline-block;
    margin-top: 55px;
    margin-left: 95px;
  }
  .fireexitlefttwo {
    display: inline-block;
    margin-top: 140px;
    margin-left: 95px;
  }
  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 170px;
    margin-left: 80px;
  }
  .widthline {
    width: 120px;
  }
  .contact {
    margin-top: 130px;
    margin-left: -15px;
    margin-bottom: 15px;
  }
  .stair138 {
    width: 28px;
    margin-left: 30px;
  }
  .col2124 {
    width: 150px;
    height: 806px;
  }
  .polestyle {
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 6px;
    width: 50px;
    margin-top: 50px;
  }
  .margintop120 {
    margin-top: 30px;
    margin-left: 5px;
  }
  .pole {
    margin-top: 50px;
  }
  .footpath {
    margin-left: -150px;
  }
  .fontsizefootpath {
    font-size: 20px;
  }
  .margintop20 {
    margin-top: 10px !important;
  }
  .margintop125 {
    margin-top: 17px !important;
  }
  .margintop136 {
    margin-top: 25px !important;
  }
  .margintop30 {
    margin-top: 5px;
  }
  .addmargin17 {
    margin-left: 17px;
  }
  .col117 {
    width: 50px;
    margin-top: 2px;
    margin-left: -15px;
  }
  .heightrow60 {
    height: 60px;
  }
  .heightrow {
    height: 50px;
  }
  .office {
    height: 490px;
    background-color: black;
    color: white;
    margin-top: 25px;
    padding: 0px;
  }
  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  .fireexitdownone {
    margin-left: 210px;
    width: 40px;
    margin-top: 0px;
  }
  .fireexitdowntwo {
    margin-left: 330px;
    width: 40px;
    margin-top: 0px;
  }
  .facetext {
    font-size: 12px;
  }
  .igtext {
    font-size: 12px;
  }
  .doortop {
    margin-left: -36px;
    margin-top: 80px;
  }
  .stair154 {
    margin-left: 34px;
    width: 20px;
  }
  .colrow14 {
    margin-left: -2px;
    margin-top: 5px;
  }
  .col116 {
    margin-left: 10px;
  }
  .colvip {
    margin-left: 10px;
  }
  .col90 {
    margin-left: 35px;
    width: 30%;
  }
  .afterdj {
    margin-left: 20px;
  }
  .col4dj {
    width: 30%;
  }
  .imgdj {
    height: 53%;
  }
  .col3dj {
    width: 30% !important;
  }
  .col77 {
    margin-left: 10px;
  }
  .col7border {
    width: 65%;
    height: 200px !important;
  }
  .col73 {
    margin-left: 30px;
  }
  .stair73 {
    margin-left: 48px;
    width: 28px;
  }
  .colstair1 {
    margin-left: 45px;
    width: 28px;
    margin-top: -30px;
  }
  .widthcol2 {
    width: 9.5%;
  }
  .widthcol5 {
    width: 36.5%;
  }
  .widthcol4 {
    width: 43%;
  }
  .col8width {
    width: 53%;
    height: 755px;
  }
  .col10 {
    margin-left: 45px;
  }
  .colbar6 {
    margin-left: 20px;
  }
  .leftbadge {
    margin-left: 11px !important;
  }
  .stage {
    margin: 8px;
    padding: 2px;
    width: 100px;
    height: 40px;
    background: black; /*important*/
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f; /*no need*/
    font-size: 20px;
    font-weight: bold;
  }
  .bar {
    padding: 10px;
    width: 45px;
    height: 45px;
    margin-top: 0px;
    background: black;
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 100;
  }
  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 36px;
    font-size: 20px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: -15px;
    background-color: white;
    color: black;
    text-align: center;
  }
  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 90px;
    height: 48px;
    font-size: 20px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -30px;
    margin-top: -34px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 50px;
    font-size: 18px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -34px;
    margin-top: 38px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 17px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -32px;
    margin-top: 150px;
    background-color: black;
    color: yellow;
    text-align: center;
  }
  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 40px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: 50px;
    color: white;
    text-align: center;
  }
  .fas {
    padding: 5px;
    font-size: 25px;
    width: 35px;
    height: 35px;
    text-align: center;
    text-decoration: none;
    margin: 5px;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }
  .fa-instagram {
    background: #f82153;
    color: white;
  }
  .badge {
    cursor: pointer;
    margin: 2px;
    padding: 3px !important;
    padding-top: 8px !important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 14px;
    font-weight: 100;
  }
  .selectbadge {
    cursor: pointer;
    margin: 2px;
    padding: 6px;
    width: 30px;
    height: 30px;
    background: gray; /*important*/
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 14px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  div.b {
    width: 100%;
    height: 20px;
    background-color: white;
  }
  div.c {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  .stair {
    display: inline-block;
    width: 4px;
    background-color: black;
    height: 20px;
    background-color: black;
  }
  .doorone {
    display: inline-block;
    width: 30px;
    height: 80px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }
  .doortwo {
    display: inline-block;
    width: 30px;
    height: 80px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }
  .handone {
    width: 4px;
    height: 26px;
    margin-left: 18px;
    margin-top: 45px;
    background: red;
    border-radius: 20%;
  }
  .handtwo {
    width: 4px;
    height: 26px;
    margin-left: 2px;
    margin-top: 44px;
    background: red;
    border-radius: 20%;
  }
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1600px) {
  .col93{
    margin-left: 26px!important;
  }
  .margintop138{
    margin-top: -13px;
  }
  .stair119{
    width: 20px; margin-left: 0px;
    margin-bottom: 15px;
  }
  .banner {
    width: 50.5%;
  }
  .bannerright{
width: 30%;
  }
  .margintopbanner{
    margin-top: 20px;
  }
  .blankbanner {
    width: 10%;
  }
  .borderheight {
    height: 266px;
  }
  .col1banner {
    width: 12%;
  }
  .col2banner {
    width: 10%;
  }
  .col5banner {
    width: 33%;
  }
  .col4banner {
    width: 37.5%;
  }
  .footleft {
    margin-left: -19.5px;
    height: 352px;
  }
  .contactheight {
    width: 15.5%;
    height: 775px;
  }
  .col2width {
    width: 12% !important;
    height: 775px;
  }
  .colleft {
    margin-left: 7px !important;
  }
  .col12 {
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .backgrountfoot {
    height: 10px;
  }
  .toiletwomen {
    height: 40px;
    margin-top: 90px;
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -5px;
  }
  .toilettext {
    color: white;
    font-size: 9px;
    text-decoration: overline;
    margin: auto;
  }
  #famen {
    color: white;
    margin-top: 5px;
    font-size: 18px;
    margin-left: 0px;
  }

  .toiletmen {
    text-align: center;
    background-color: black;
    width: 30px;
    margin-left: -5px;
  }
  .sizefireexitleft {
    width: 30px;
  }
  .fireexitleftone {
    display: inline-block;
    margin-top: 55px;
    margin-left: 119px;
  }
  .fireexitlefttwo {
    display: inline-block;
    margin-top: 140px;
    margin-left: 119px;
  }
  .toiletstyle {
    background-color: black;
    width: 40px;
    padding: 5px;
    margin-top: 170px;
    margin-left: 97px;
  }
  .widthline {
    width: 120px;
  }
  .contact {
    margin-top: 130px;
    margin-left: -15px;
    margin-bottom: 15px;
  }
  .stair138 {
    width: 28px;
    margin-left: 30px;
  }
  .col2124 {
    width: 150px;
    height: 806px;
  }
  .polestyle {
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 6px;
    width: 50px;
    margin-top: 12px;
  }
  .margintop120 {
    margin-top: 30px;
    margin-left: 5px;
  }
  .pole {
    margin-top: 50px;
  }
  .footpath {
    margin-left: -150px;
  }
  .fontsizefootpath {
    font-size: 20px;
  }
  .margintop20 {
    margin-top: 10px !important;
  }
  .margintop125 {
    margin-top: 17px !important;
  }
  .margintop136 {
    margin-top: 25px !important;
  }
  .margintop30 {
    margin-top: 5px;
  }
  .addmargin17 {
    margin-left: 17px;
  }
  .col117 {
    width: 50px;
    margin-top: 2px;
    margin-left: -15px;
  }
  .heightrow60 {
    height: 60px;
  }
  .heightrow {
    height: 50px;
  }
  .office {
    height: 478px;
    background-color: black;
    color: white;
    margin-top: 25px;
    padding: 0px;
  }
  .barwidth {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  .fireexitdownone {
    margin-left: 260px;
    width: 40px;
    margin-top: 0px;
  }
  .fireexitdowntwo {
    margin-left: 404px;
    width: 40px;
    margin-top: 0px;
  }
  .facetext {
    font-size: 12px;
  }
  .igtext {
    font-size: 12px;
  }
  .doortop {
    margin-left: -36px;
    margin-top: 97px;
  }
  .stair154 {
    margin-left: 34px;
    width: 20px;
  }
  .colrow14 {
    margin-left: -2px;
    margin-top: 5px;
  }
  .col116 {
    margin-left: 10px;
  }
  .colvip {
    margin-left: 10px;
  }
  .col90 {
    margin-left: 35px;
    width: 30%;
  }
  .afterdj {
    margin-left: 20px;
  }
  .col4dj {
    width: 30%;
  }
  .imgdj {
    height: 53%;
  }
  .col3dj {
    width: 30% !important;
  }
  .col77 {
    margin-left: 10px;
  }
  .col7border {
    width: 65%;
    height: 200px !important;
  }
  .col73 {
    margin-left: 45px;
  }
  .stair73 {
    margin-left: 61px;
    width: 28px;
  }
  .colstair1 {
    margin-left:62px;
    width: 28px;
    margin-top: -30px;
  }
  .widthcol2 {
    width: 9.5%;
  }
  .widthcol5 {
    width: 36.5%;
  }
  .widthcol4 {
    width: 43%;
  }
  .col8width {
    width: 53%;
    height: 775px;
  }
  .col10 {
    margin-left: 45px;
  }
  .colbar6 {
    margin-left: 20px;
  }
  .leftbadge {
    margin-left: 11px !important;
  }
  .stage {
    margin: 8px;
    padding: 2px;
    width: 115px;
    height: 40px;
    background: black; /*important*/
    background-color: black !important;
    justify-content: center;
    align-items: center;
    color: #f2972f; /*no need*/
    font-size: 20px;
    font-weight: bold;
  }
  .bar {
    padding: 10px;
    width: 45px;
    height: 45px;
    margin-top: 0px;
    background: black;
    background-color: #5e9cd9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 100;
  }
  .verticaltoilet {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 36px;
    font-size: 20px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -38px;
    margin-top: -15px;
    background-color: white;
    color: black;
    text-align: center;
  }
  .verticalbar134 {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 90px;
    height: 48px;
    font-size: 20px;
    /* transform-origin: center left; */
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -30px;
    margin-top: -34px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticalbar {
    font-weight: bold;
    letter-spacing: 0.7px;
    width: 100px;
    height: 50px;
    font-size: 18px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -34px;
    margin-top: 38px;
    background-color: black;
    color: white;
    text-align: center;
  }
  .verticaltext {
    letter-spacing: 0.7px;
    width: 60px;
    font-size: 17px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-left: -32px;
    margin-top: 150px;
    background-color: black;
    color: yellow;
    text-align: center;
  }
  .verticaloffice {
    letter-spacing: 0.7px;
    font-size: 40px;
    /* transform-origin: center left; */
    top: 100%;
    transform: rotate(-90deg) translateX(-50%);
    padding: 0px;
    margin-top: 50px;
    color: white;
    text-align: center;
  }
  .fas {
    padding: 5px;
    font-size: 25px;
    width: 35px;
    height: 35px;
    text-align: center;
    text-decoration: none;
    margin: 5px;
    border-radius: 50%;
  }

  .fa-facebook {
    background: #3e58a3;
    color: white;
  }
  .fa-instagram {
    background: #f82153;
    color: white;
  }
  .badge {
    cursor: pointer;
    margin: 2px;
    padding: 3px !important;
    padding-top: 8px !important;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 14px;
    font-weight: 100;
  }
  .selectbadge {
    cursor: pointer;
    margin: 2px;
    padding: 6px;
    width: 38px;
    height: 38px;
    background: gray; /*important*/
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white; /*no need*/
    font-size: 14px;
    font-weight: 100;
  }

  div.a {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  div.b {
    width: 100%;
    height: 20px;
    background-color: white;
  }
  div.c {
    width: 100%;
    height: 3px;
    background-color: black;
    -ms-transform: skewX(30deg); /* IE 9 */
    transform: skewX(30deg);
  }
  .stair {
    display: inline-block;
    width: 4px;
    background-color: black;
    height: 20px;
    background-color: black;
  }
  .doorone {
    display: inline-block;
    width: 30px;
    height: 80px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }
  .doortwo {
    display: inline-block;
    width: 30px;
    height: 80px;
    background: black;
    border: 2px solid red;
    border-radius: 10%;
  }
  .handone {
    width: 4px;
    height: 26px;
    margin-left: 18px;
    margin-top: 45px;
    background: red;
    border-radius: 20%;
  }
  .handtwo {
    width: 4px;
    height: 26px;
    margin-left: 2px;
    margin-top: 44px;
    background: red;
    border-radius: 20%;
  }
}
body {
  margin: 60px;
  background: white;
}
.flex-container {
  display: flex;
}
.flex {
  display: inline-flex;
}
.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.neonText {
  color: #f269d3;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #bc13fe,
    0 0 82px #bc13fe,
    0 0 92px #bc13fe,
    0 0 102px #bc13fe,
    0 0 151px #bc13fe;
    height: 70%;
}
.seatconfirm{
  width: 1.4%;position:absolute;margin-top:-2px;margin-left:-5px;
}
h1 {
  text-align: center;
  font-size: 1.9rem;
  animation: pulsate 1.5s infinite alternate;  
  border: 0.1rem solid #fc0eb6;
  border-radius: 1rem;
  padding: 0.3em;
   box-shadow: 0 0 .2rem #fff,
            0 0 .2rem #fff,
            0 0 2rem #bc13fe,
            0 0 0.8rem #bc13fe,
            0 0 2.8rem #f478cf,
            inset 0 0 1.3rem #f478cf; 
}
@keyframes pulsate  {
  100% {text-shadow:
      0 0 4px #ed0f3e,
      0 0 11px #ed0f3e,
      0 0 19px #ed0f3e,
      0 0 40px #f367f1,
      0 0 80px #f367f1,
      0 0 90px #f367f1,
      0 0 100px #f367f1,
      0 0 150px #f367f1;}
  0% { text-shadow:
    0 0 2px #fff,
    0 0 4px #fff,
    0 0 6px #fff,
    0 0 10px #bc13fe,
    0 0 45px #bc13fe,
    0 0 55px #bc13fe,
    0 0 70px #bc13fe,
    0 0 80px #bc13fe;}
}
</style>
