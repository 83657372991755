import http from "../http-common";

class MainTimeBookingService {
  createmanage_time_booking(data) {
    return http.post('/manage_time_booking', data);
  }
  getmanage_time_bookings() {
    return http.get(`/manage_time_booking`);
  }
  getmanage_time_booking(id) {
    return http.get(`/manage_time_booking/${id}`);
  }
  updatemanage_time_booking(id, data) {
    return http.put(`/manage_time_booking/${id}`, data);
  }
  deletemanage_time_booking(id) {
    return http.delete(`/manage_time_booking/${id}`);
  }
}

export default new MainTimeBookingService();