import http from "../http-common";

class TicketService {
  createticket(data) {
    return http.post('/tickets', data);
  }
  gettickets() {
    return http.get(`/tickets`);
  }  
  getticketsreport(id,seat) {
    return http.get(`/tickets/getreport?id=`+id+'&&seat='+seat);
  } 
  getticket(id) {
    return http.get(`/tickets/`+id);
  }
  updateticket(id, data) {
    return http.put(`/tickets/${id}`, data);
  }
  deleteticket(id) {
    return http.delete(`/tickets/`+id);
  }
  updateticketbyconcertid(id) {
    return http.put(`/tickets/updateticket/`+id);
  }
}

export default new TicketService();