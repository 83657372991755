<template>
  <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout">
    <div class="col mb-3" style="text-align:right">
        <a :href="'confirm/'+concert_id+'/0'">
          <button
            class="btn btn-success"
          >
            <i class="fa fa-plus"></i> เพิ่มข้อมูล
          </button></a
        >
      </div>
     <table class="table table-bordered"> 
  <thead>
     <tr class="table-active">
      <th scope="col">ชื่อลูกค้า</th>
      <th scope="col">เบอร์โทรศัพท์</th>
       <th scope="col">โซน</th>
       <th scope="col">ที่นั่ง</th>
      <th scope="col">หมายเหตุ</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(l,i) in list" :key="i">
      <td><router-link :to="'confirm/'+concert_id+'/'+l.id" style="color:black;text-decoration:none;">{{l.name}}</router-link></td>      
      <td><router-link :to="'confirm/'+concert_id+'/'+l.id" style="color:black;text-decoration:none;">{{l.phone}}</router-link></td>
      <td><router-link :to="'confirm/'+concert_id+'/'+l.id" style="color:black;text-decoration:none;">{{l.zone}}</router-link></td>
      <td><router-link :to="'confirm/'+concert_id+'/'+l.id" style="color:black;text-decoration:none;">{{l.seat}}</router-link></td>
      <td><router-link :to="'confirm/'+concert_id+'/'+l.id" style="color:black;text-decoration:none;">{{l.remark || '-'}}</router-link></td>
    </tr>
  </tbody>
</table>
  </div>
</template>

<script>

  import TicketService from "../services/TicketService";
  import ConcertService from "../services/ConcertService";
  import ZoneService from "../services/ZoneService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return { list:[],
    concert_id:0,
    zone:''};
  },
  mounted() {
    this.getconcert()
  },
  methods: {
    gettickets(){
      TicketService.getticketsreport(this.concert_id).then((res)=>{
        this.list = res.data
        for (let r = 0; r < this.list.length; r++) {
          // console.log(this.list[r].id);
          ZoneService.getzonebyticketid(this.list[r].id).then((res)=>{
// console.log(res.data);
this.zone = ''
for (let z = 0; z < res.data.length; z++) {
  this.zone += res.data[z].zone + ', '
}
this.zone = this.zone.slice(0, -2)
          // console.log(this.zone);  
          this.list[r].zone = this.zone
          })        
        }
        
      // console.log(this.list);
      })
    },
    getconcert(){
      ConcertService.getconcerts().then((res)=>{
      // console.log(res.data);
      this.concert_id = res.data.id
      // console.log(this.concert_id);
    this.gettickets()
    })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 60px;
  background: white;
}
.flex-container {
  display: flex;
}
.flex {
  display: inline-flex;
}
.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.badge {
  cursor: pointer;
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: black; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.selectbadge {
  cursor: pointer;
  margin: 8px;
  padding: 6px;
  width: 40px;
  height: 40px;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.activebadge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 8px;
  transition: color 0.15s;
  width: 40px;
  height: 40px !important;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.theatre {
  display: flex;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cinema-seats {
  display: flex;
}

.cinema-seats .seat {
  cursor: pointer;
}

.select {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #da1b21;
  border-radius: 7px;
}

/* .active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #545e57;
  border-radius: 7px;
} */
/* // Left Seats */
.left {
  margin-right: 100px;
}

.left .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.left .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}
/* 
// Right Seats */

.right .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}

.right .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.right .screen {
  width: 1000px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  text-align: center;
  border: 10px solid;
  top: 300px;
  right: -60px;
}
/* //button */
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  font-size: 15px;
  position: relative;
  top: 400px;
  right: 800px;
}
</style>
