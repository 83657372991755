import { render, staticRenderFns } from "./promotions.vue?vue&type=template&id=977d36c6&scoped=true&"
import script from "./promotions.vue?vue&type=script&lang=js&"
export * from "./promotions.vue?vue&type=script&lang=js&"
import style0 from "./promotions.vue?vue&type=style&index=0&id=977d36c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "977d36c6",
  null
  
)

export default component.exports