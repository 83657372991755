<template>
  <div class="mt-3 container-xxl bd-gutter mt-3 my-md-4 bd-layout">
    <!-- <div class="col-12" align="right" style="display: none">
      <vue-excel-xlsx
        id="excel"
        :data="data"
        :columns="columns"
        :file-name="filename"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
      >
        Export Excel
      </vue-excel-xlsx>
    </div>
    <div class="col mb-3" style="text-align: right">
      <a @click="exportToPDF()">
        <button class="btn btn-primary">
          <i class="fa fa-file"></i> Export
        </button></a
      >
    </div> -->
    <div id="app" ref="document">
      <table class="table table-bordered" v-for="(d, ii) in list" :key="ii">
         <thead>
         <tr style="background-color:#6FE35A">
    <th colspan="9" style="text-align:center">วันที่จองโต๊ะ {{convertdate(d.date)}}</th>
  </tr>
   <tr style="background-color:#C0BEBE;text-align:center">
    <th>วันที่จองโต๊ะ</th>
     <th>ชื่อลูกค้า</th>
      <th>เบอร์โทร</th>
       <th>โต๊ะที่จอง</th>
        <th>ไลน์ไอดี</th>
         <th>วันที่ทำรายการ</th>
          <th>เวลา</th>
           <th>สถานะ</th>
            <th></th>
  </tr>
         </thead>
          <tbody>
          <tr v-for="(l, i) in d.detail" :key="i" >
             <td :style="'width: 120px;'+l.style">
              <a style="color: black; text-decoration: none">{{ convertdate(l.date) }}</a>
            </td>
            <td :style="'width: 120px;'+l.style">
              <a style="color: black; text-decoration: none">{{ l.name }}</a>
            </td>
            <td :style="'width: 100;'+l.style">
              <a style="color: black; text-decoration: none">{{ l.phone }}</a>
            </td>
            <td :style="'width: 300;'+l.style">
              <a style="color: black; text-decoration: none">{{ l.seat }}</a>
            </td>
             <td :style="'width: 400;'+l.style">
              <a style="color: black; text-decoration: none">
                {{l.line || '-'}}
              </a>
            </td>  
            <td :style="'width: 400;'+l.style">
              <a style="color: black; text-decoration: none">
               {{convertdate(l.created_date)}} 
              </a>
            </td> 
             <td :style="'width: 400;'+l.style">
              <a style="color: black; text-decoration: none">
               {{converttime(l.created_date)}} น.
              </a>
            </td> 
             <td :style="'width: 300px;'+l.style" v-if="l.status">
              <a style="color: black; text-decoration: none">
                ยืนยันการจองแล้ว
              </a>
            </td>  
             <td :style="'width: 300px;'+l.style" v-else>
              <a style="color: black; text-decoration: none">
                ยังไม่ได้ยืนยันการจอง
              </a>
            </td>  
            <td v-if="statuspdf" style="width: 130px">
              <router-link :to="'/bookadmin/' + 17 + '/' + l.id">
                <button type="button" class="btn btn-warning">
                  <i class="fa fa-edit"></i></button
              ></router-link>&nbsp;
              <a>
                <button
                @click="getid(l.id)"
                  data-bs-toggle="modal" data-bs-target="#exampleModaldelete"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <table class="table table-bordered">
        <thead >
          <tr>
            <th scope="col">วันที่จองโต๊ะ</th>
            <th scope="col">ชื่อลูกค้า</th>
            <th scope="col">เบอร์โทรศัพท์</th>
            <th scope="col">โต๊ะที่จอง</th>
            <th scope="col">หมายเหตุ</th>
             <th scope="col">สถานะ</th>
              <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in list" :key="i">
             <td :style="'width: 120px;'+l.style">
              <a style="color: black; text-decoration: none">{{ convertdate(l.date) }}</a>
            </td>
            <td :style="'width: 120px;'+l.style">
              <a style="color: black; text-decoration: none">{{ l.name }}</a>
            </td>
            <td :style="'width: 100;'+l.style">
              <a style="color: black; text-decoration: none">{{ l.phone }}</a>
            </td>
            <td :style="'width: 300;'+l.style">
              <a style="color: black; text-decoration: none">{{ l.seat }}</a>
            </td>
             <td :style="'width: 400;'+l.style">
              <a style="color: black; text-decoration: none">{{
                l.remark || "-"
              }}</a>
            </td>   
             <td :style="'width: 300px;'+l.style" v-if="l.status">
              <a style="color: black; text-decoration: none">
                ยืนยันการจองแล้ว
              </a>
            </td>  
             <td :style="'width: 300px;'+l.style" v-else>
              <a style="color: black; text-decoration: none">
                ยังไม่ได้ยืนยันการจองแล้ว
              </a>
            </td>  
            <td v-if="statuspdf" style="width: 130px">
              <router-link :to="'/bookadmin/' + 17 + '/' + l.id">
                <button type="button" class="btn btn-warning">
                  <i class="fa fa-edit"></i></button
              ></router-link>&nbsp;
              <a>
                <button
                @click="getid(l.id)"
                  data-bs-toggle="modal" data-bs-target="#exampleModaldelete"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a>
            </td>
          </tr>
        </tbody>
      </table> -->
    </div>
    <!-- Modal -->
<div class="modal fade" id="exampleModaldelete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ</h5>
            <button 
            id="closed" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConcertService from "../services/ConcertService";
import html2pdf from "html2pdf.js";
import TicketSeatService from "../services/TicketSeatService";
import BookingService from "../services/BookingService";
// import BookingSeatService from "../services/BookingSeatService";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      list: [],
      columns: [],
      data: [],
      filename: "",
      zone: "",
      seat: "",
      statuspdf: true,
      ticket_id:0,
      concert:''
    };
  },
  mounted() {
    this.concert_id = this.$route.params.id
    
        this.gettickets();
        // this.getconcerts();
  },
  methods: {
    converttime(date){
var dates = new Date(date)
var hh = dates.getHours()
var mm = dates.getMinutes()
if (String(hh).length == 1) {
  hh = '0'+hh
}
if (String(mm).length == 1) {
  mm = '0'+mm
}
return hh+'.'+mm
    },
    convertdate(date){
var dates = new Date(date)
var dd = dates.getDate()
var mm = dates.getMonth()+1
var yy = dates.getFullYear()
if (String(dd).length == 1) {
  dd = '0'+dd
}
if (String(mm).length == 1) {
  mm = '0'+mm
}
return dd+'/'+mm+'/'+yy
    },
    getconcerts(){
ConcertService.getconcert(this.concert_id).then((res)=>{
  this.concert = res.data
})
    }, 
    deletedata() {
       BookingService.deletebooking(this.ticket_id).then(() => {
              TicketSeatService.getticketseat(this.ticket_id).then(() => {
              document.getElementById("closed").click();
             this.gettickets();
            });
          
              });
    },
    getid(id) {
      this.ticket_id = id
    },
    exportToPDF() {
      this.statuspdf = false;
      html2pdf(this.$refs.document, {
        margin: [1, 1.5, 0, 1.2],
        filename: this.concert.concert_name + ".pdf",
        jsPDF: { unit: "cm", format: "a4", orientation: "p" },
      });
      setTimeout(function () {
        location.reload();
      }, 500);
      window.scrollTo(0, 0);
    },
    generatedata() {
      this.data = this.list;
      this.columns = [];
      this.columns.push(
        {
          label: "ชื่อลูกค้า",
          field: "name",
        },
        {
          label: "เบอร์โทรศัพท์",
          field: "phone",
        },
        {
          label: "โซน",
          field: "zone",
        },
        {
          label: "ที่นั่ง",
          field: "seat",
        },
        {
          label: "หมายเหตุ",
          field: "remark",
        },
        {
          label: "คิวอาร์โค้ด",
          field: "qrcode",
        }        ,
        {
          label: "ยืนยันโดย",
          field: "user",
        }
      );
      this.filename = "123";
    },
    generateexcel() {
      this.generatedata();
      document.getElementById("excel").click();
    },
    gettickets() {
      BookingService.getbookings().then((res) => {
        this.list = res.data;
        // console.log(this.list);
        for (let r = 0; r < this.list.length; r++) {
          // console.log(this.list[r].id);
          
        // this.list[r].seat = ''
          // BookingSeatService.getbooking(this.list[r].id).then((res) => {
          //   this.seat = "";
          //   // console.log(res.data);
          //   for (let z = 0; z < res.data.length; z++) {
          //     this.seat += res.data[z].seats + ", ";
          //   }
          //   this.seat = this.seat.slice(0, -2);
          //   // console.log(this.seat);
          //   this.list[r].seat = this.seat;
          // });
          
if ( this.list[r].status == false) {
  this.list[r].style = 'background-color:#D59496'
} else{
  this.list[r].style = 'background-color:#86DA9E'
}
        }
        
      });
    },
    getconcert() {
      ConcertService.getconcerts().then((res) => {
        // console.log(res.data);
        this.concert_id = res.data.id;
        this.gettickets();
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 60px;
  background: white;
}
.flex-container {
  display: flex;
}
.flex {
  display: inline-flex;
}
.notbadge {
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: #c7c7c9; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #c7c7c9; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.badge {
  cursor: pointer;
  margin: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: black; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.selectbadge {
  cursor: pointer;
  margin: 8px;
  padding: 6px;
  width: 40px;
  height: 40px;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.activebadge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 8px;
  transition: color 0.15s;
  width: 40px;
  height: 40px !important;
  background: gray; /*important*/
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white; /*no need*/
  font-size: 20px;
  font-weight: 100;
}
.theatre {
  display: flex;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cinema-seats {
  display: flex;
}

.cinema-seats .seat {
  cursor: pointer;
}

.select {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21,
    #da1b21
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #da1b21;
  border-radius: 7px;
}

/* .active {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #545e57;
  border-radius: 7px;
} */
/* // Left Seats */
.left {
  margin-right: 100px;
}

.left .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.left .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}
/* 
// Right Seats */

.right .cinema-row {
  transform: skew(-21deg);
  margin: 0 6px;
}

.right .seat {
  width: 45px;
  height: 60px;
  border-radius: 7px;
  background: linear-gradient(
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd,
    #4530cd
  );
  margin-bottom: 10px;
  transform: skew(20deg);
  margin-top: -32px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.right .screen {
  width: 1000px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  text-align: center;
  border: 10px solid;
  top: 300px;
  right: -60px;
}
/* //button */
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  font-size: 15px;
  position: relative;
  top: 400px;
  right: 800px;
}
img {
  display: inline !important;
  width: 100px !important;
}
</style>
