import http from "../http-common";

class ManageSeatsService {
  createper_seat(data) {
    return http.post('/manage_seats', data);
  }
  getmanage_seats() {
    return http.get(`/manage_seats`);
  }
  getper_seat(id) {
    return http.get(`/manage_seats/${id}`);
  }
  updateper_seat(id, data) {
    return http.put(`/manage_seats/${id}`, data);
  }
  deleteper_seat(id) {
    return http.delete(`/manage_seats/${id}`);
  }
}

export default new ManageSeatsService();