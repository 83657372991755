<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!-- <div class="col-md-12" style="text-align: center;">
  <qr-code style="display:inline !important" v-if="ticket.qrcode" :text="ticket.qrcode"></qr-code>

  </div> -->
          <div class="card-body" style="width: 100%">
            <h6 class="card-title" style="text-align: center">
              วันที่จองโต๊ะ: {{ convertdate(ticket.date) }}
            </h6>
            <br />
            <p class="card-title" style="text-align: center">
              ชื่อผู้จอง: {{ ticket.name }}
            </p>
            <br />
            <p class="card-title" style="text-align: center">
              เบอร์โทรศัพท์: {{ ticket.phone }}
            </p>
            <br />
<p class="card-title" style="text-align: center">
              ไลน์ไอดี: {{ ticket.line || '-' }}
            </p>
            <br />
            <p class="card-title" style="text-align: center">หมายเลขโต๊ะ: {{ seat }}</p><br />
            <p class="card-title" style="text-align: center" v-if="ticket.type == 'birthday'">
              จัดเลี้ยงวันเกิด 
            </p>
            <p class="card-title" style="text-align: center" v-else>
              จัดเลี้ยงสังสรรค์
            </p>
            <br />
            <p class="card-title" style="text-align: center">
              จำนวนผู้เข้าใช้บริการ: {{ ticket.numberpeople }} คน
            </p>
            <br />
            <p class="card-title mt-3" style="text-align: center">
              หมายเหตุ: {{ ticket.remark || '-' }}
            </p>
            <br />
             <h5 class="card-title mt-3" style="text-align: center">
             <!-- คุณต้องเข้ามาที่ร้านก่อนเวลา -->
              {{ time.messages }} 
              <!-- ไม่เช่นนั้นทางร้านจะถือว่าท่านสละสิทธิ์ในการจองโต๊ะ -->
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookingService from "../services/BookingService";
import BookingSeatService from '../services/BookingSeatService.js';
import MainTimeBookingService from '../services/MainTimeBookingService.js';

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      ticket_id: 0,
      ticket: {},
      seat: "",
      zone: "",
      concert: {},
      time:{}
    };
  }, 
  mounted() {
    this.ticket_id = this.$route.params.id;
    BookingService.getbooking(this.ticket_id).then((res) => {
      this.ticket = res.data;
      MainTimeBookingService.getmanage_time_bookings().then((res) => {
        this.time = res.data;
        var t = res.data[0].to_time.split(":");
        this.time.to_time = t[0] + ":" + t[1];
        this.time.messages = res.data[0].messages
      });
    });
    BookingSeatService.getbooking(this.ticket_id).then((res) => {
      this.seat = "";
            for (let s = 0; s < res.data.length; s++) {
              this.seat += res.data[s].seats + ", ";
            }
            this.seat = this.seat.slice(0, -2);
      // console.log(this.seat);
    });
    // console.log(this.$route.params.ticket)
  },
  methods: {
    convertdate(date){
var dates = new Date(date)
var dd = dates.getDate()
var mm = dates.getMonth()+1
var yy = dates.getFullYear()
if (String(dd).length == 1) {
  dd = '0'+dd
}
return dd+'/'+mm+'/'+yy
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
img {
  display: inline-block !important;
}
</style>
