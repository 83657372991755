<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">

        <div class="mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          data-bs-toggle="modal"
          @click="gettype(1)"
          data-bs-target="#AddPromotion"
        >
          <i class="fa fa-plus"></i> HOLLYWOOD
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">HOLLYWOOD</th>
          <!-- <th scope="col">ชื่อไฟล์</th> -->
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }} 
          </td>
          <td>
            <img :src="l.path" style="width:300px ">
          </td>
          <!-- <td>{{ l.name }} </td> -->
          <td>
            <!-- <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddPromotion"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp; -->
              <a>
                <button
                @click="deleteid(l.id)"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a>
          </td>
        </tr>
      </tbody>
    </table>
      </div>
      <div class="col-md-6">

<div class="mb-3 mt-3" style="text-align: right">
<a>
<button
  type="button"
  id="get_file"
  @click="gettype(2)"
  class="btn btn-success"
  data-bs-toggle="modal"
  data-bs-target="#AddPromotion"
>
  <i class="fa fa-plus"></i> OVERLAND
</button></a
>
</div>
<table class="table table-bordered">
<thead>
<tr class="table-active">
  <th scope="col">ลำดับที่</th>
  <th scope="col">OVERLAND</th>
  <!-- <th scope="col">ชื่อไฟล์</th> -->
  <th scope="col"></th>
</tr>
</thead>
<tbody>
<tr v-for="(l, i) in listtwo" :key="i">
  <td>
    {{ i + 1 }} 
  </td>
  <td>
    <img :src="l.path" style="width:300px ">
  </td>
  <!-- <td>{{ l.name }} </td> -->
  <td>
    <!-- <a @click="getid(l.id)">
      <button
        type="button"
        class="btn btn-warning"
        data-bs-toggle="modal"
        data-bs-target="#AddPromotion"
      >
        <i class="fa fa-edit"></i></button
    ></a>&nbsp; -->
      <a>
        <button
        @click="deleteid(l.id)"
          type="button"
          class="btn btn-danger"
        >
          <i class="fa fa-trash"></i></button
      ></a>
  </td>
</tr>
</tbody>
</table>
</div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddPromotion"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              id="closeduser"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group">
                  <label for="username">ไฟล์</label>
                  <img  class="mb-3" :src="path" style="width:100%"/>
                  <input
        id="my_file"
        class="form-control"
        type="file"
        accept="image/*"
        @change="onFileChange"
      />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PromotionService from "../services/PromotionService";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import axios from "axios";
import LinkService from "../services/LinkService";

export default {
  name: "Nav",
  components: {
    // DatePicker,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      con_id: 0,
      hash: 0,
      title: "",
      roles:[],
      concert:[],
      data:{},
      path:'',
      listtwo:[],
      type:1
    };
  },
  mounted() {
    this.getPromotion()
  },
  methods: {
    gettype(type){
      this.path = ''
this.type = type
    },
    getPromotion(){
      PromotionService.getpromotions(1).then((res)=>{
        this.list = res.data
        console.log(res.data);
      })
      PromotionService.getpromotions(2).then((res)=>{
        this.listtwo = res.data
        console.log(res.data);
      })
    },
    
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      this.selectedFile = evt.target.files[0];
      
      this.filename = this.selectedFile.name;
      if (!files.length) return;
      this.onUploadFile();
      // }
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      // var http = "http://localhost:8080/uploadbanner?name="+this.filename;
      var http = LinkService.getLink()+"/uploadpromotion?name="+this.filename;
      axios
        .post(http, formData)
        .then(() => {   
          this.data.path =  LinkService.getLink()+"/uploads/promotions/" + this.filename
          this.data.name =  this.filename
          this.path = LinkService.getLink()+"/uploads/promotions/" + this.filename
          // console.log(this.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteid(id) {
      PromotionService.deletepromotions(id).then(()=>{
this.getPromotion();
    })
    },
    getid(id) {
      this.con_id = id;
      if (this.con_id != 0) {
        this.title = "แก้ไขโปรโมชัน";
        PromotionService.getpromotion(this.con_id).then((res) => {
          this.data = res.data;
          this.data.date = this.convertdate(this.data.date)
          
          // console.log(this.data);
        });
      } else {
        this.title = "เพิ่มโปรโมชัน";
        this.data = {};
      }
    },
    save() {
      // console.log(this.data);
      if (this.path == null) {
        alert("กรุณาเลือกโปรโมชัน");
      }  else {
        var con = {
          name: this.data.name,
          path: this.data.path,
          status: true,
          type:this.type 
        };
        // console.log(con);
        if (this.con_id == 0) {
          PromotionService.createpromotions(con).then(() => {
            document.getElementById("closeduser").click();
            document.getElementById("my_file").value = null
            this.path = ''
            this.getPromotion();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          PromotionService.updatepromotions(this.con_id, con).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            this.getPromotion();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
