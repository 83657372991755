<template>
  <div class="container mt-5">
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ชื่อคอนเสิร์ต</th>
          <th scope="col" style="width:200px">จัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.concert_name }}
          </td>
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#AddUser"
              >
                คืนการยกเลิก
              </button></a
            >&nbsp;
            <button
              @click="deleteid(l.id)"
              type="button"
              class="btn btn-danger"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ConcertService from "../services/ConcertService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
    };
  },
  mounted() {
    this.getConcerts();
  },
  methods: {
    deleteid(id) {
      ConcertService.deleteconcertall(id).then(() => {
        // this.$router.push("/");
        setTimeout(function () {
          location.reload();
        }, 500);
      });
    },
    getid(id) {
      this.user_id = id;
      var data = {};
      ConcertService.updateStatusconcert(id, data).then(() => {
        this.$router.push("/");
        setTimeout(function () {
          location.reload();
        }, 500);
      });
    },
    getConcerts() {
      ConcertService.getconcertsAll().then((res) => {
        this.list = res.data;
        // console.log(this.list);
      });
    },
    signIn() {
      if (this.user.username == "" || this.user.username == null) {
        alert("กรุณากรอกชื่อผู้ใช้งาน");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else {
        var user = {
          username: this.user.username,
          password: this.user.password,
        };
        this.$store.dispatch("auth/login", user).then(
          () => {
            this.loading = true;
            const loggedIn = localStorage.getItem("user");
            var user = JSON.parse(loggedIn);
            if (user.role_id == 1) {
              this.$router.push("/bannerlist");
            } else {
              this.$router.push("/confirmlist");
            }
            setTimeout(function () {
              location.reload();
            }, 500);
          },
          (error) => {
            alert("ชื่อหรือรหัสผ่านไม่ถูกต้อง");
            console.log(error);
            // this.loading = false;
            // this.message =
            //   (error.response &&
            //     error.response.data &&
            //     error.response.data.message) ||
            //   error.message ||
            //   error.toString();
          }
        );
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
